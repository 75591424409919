<template>
    <div class="extra-form">
        <h2 class="text-yellow">Choisir des Extras (Optionnel)</h2>
        <div class="extra">
            <label class="no-extras" for="no-extras">
                <input type="checkbox" id="no-extras" v-model="noExtrasSelected" @change="toggleExtras"
                    autocomplete="off" />
                Je ne prends pas d'extras
            </label>

            <div class="extra-list" v-if="!noExtrasSelected">
                <label v-for="(extra, index) in extrasOptions" :key="extra.name" :for="'extra-' + index">
                    <input type="checkbox" :id="'extra-' + index" :value="extra.name" v-model="selectedExtras"
                        :disabled="isExtraDisabled(extra)" autocomplete="off" />
                    {{ extra.name }} <strong>({{ extra.price.toFixed(2) }}€)</strong>
                    <span v-if="isExtraDisabled(extra)" class="extra-included-message">
                        (Déjà inclus dans la formule)
                    </span>
                </label>
            </div>
        </div>
        <div class="form-navigation">
            <button @click="prevStep">Précédent</button>
            <button v-if="showNextButton" class="next-button" @click="nextStep">Suivant</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bookingData: Object
    },
    data() {
        return {
            selectedExtras: this.bookingData.extras || [],
            noExtrasSelected: false,
            extrasOptions: [
                { name: 'Nettoyage des Tapis', price: 19.90 },
                { name: 'Nettoyage extérieur à vapeur', price: 29.90 },
                { name: 'Rénovation des Phares Avant', price: 39.90 },
                { name: 'Rénovation des Phares Arrière', price: 39.90 },
                { name: 'Nettoyage Complet des Sièges', price: 49.90 }
            ]
        };
    },
    computed: {
        showNextButton() {
            return this.noExtrasSelected || this.selectedExtras.length > 0;
        }
    },
    methods: {
        formatPrice(price) {
            return parseFloat(price).toFixed(2);
        },
        toggleExtras() {
            if (this.noExtrasSelected) {
                this.selectedExtras = [];
            }
        },
        prevStep() {
            this.$emit('prev');
        },
        nextStep() {
            const extras = this.noExtrasSelected ? [] : this.selectedExtras;
            const extrasPrice = this.calculateExtrasPrice(extras);
            this.$emit('update:bookingData', { extras, extrasPrice });
            this.$emit('next');
        },
        calculateExtrasPrice(extras) {
            return extras.reduce((total, extra) => {
                const option = this.extrasOptions.find(e => e.name === extra);
                return total + (option ? parseFloat(option.price) : 0);
            }, 0).toFixed(2);
        },
        isExtraDisabled(extra) {
            const selectedService = this.bookingData.service;
            const selectedFormula = this.bookingData.package;

            const disabledConditions = [
                { service: 'Nettoyage', formula: 'Package Auto', extra: 'Nettoyage des Tapis' },
                { service: 'Nettoyage', formula: 'Package BM', extra: 'Nettoyage des Tapis' },
                { service: 'Nettoyage', formula: 'Package BM', extra: 'Nettoyage Complet des Sièges' },
                { service: 'Polissage', formula: 'Formule 3', extra: 'Rénovation des Phares' },
                { service: 'Polissage', formula: 'Formule 3', extra: 'Nettoyage extérieur à vapeur' },
                { service: 'Polissage', formula: 'Formule 3', extra: 'Nettoyage des Tapis' },
                { service: 'Polissage', formula: 'Formule 3', extra: 'Rénovation des Phares Avant' },
                { service: 'Polissage', formula: 'Formule 3', extra: 'Rénovation des Phares Arrière' },
                { service: 'Polissage', formula: 'Formule 3', extra: 'Nettoyage Complet des Sièges' },
                { service: 'Polissage', formula: 'Formule 2', extra: 'Nettoyage des Tapis' },
                { service: 'Polissage', formula: 'Formule 2', extra: 'Nettoyage extérieur à vapeur' },
                { service: 'Polissage', formula: 'Formule 2', extra: 'Rénovation des Phares Avant' },
                { service: 'Remplacement Pare-Brise', formula: 'Formule 1 + Package Auto', extra: 'Nettoyage des Tapis' }
            ];

            return disabledConditions.some(condition =>
                condition.service === selectedService &&
                condition.formula === selectedFormula &&
                condition.extra === extra.name
            );
        },
        removeDisabledExtras() {
            this.selectedExtras = this.selectedExtras.filter(extra => {
                return !this.isExtraDisabled({ name: extra });
            });
        }
    },
    watch: {
        selectedExtras(newVal) {
            if (newVal.length > 0) {
                this.noExtrasSelected = false;
            }
        },
        noExtrasSelected(newVal) {
            if (newVal) {
                this.selectedExtras = [];
            }
        },
        'bookingData.service': 'removeDisabledExtras',
        'bookingData.package': 'removeDisabledExtras'
    }
};
</script>


<style scoped>
.extra-form {
    width: 100%;
}

.extra {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.extra-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

h2 {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}

.extra-included-message {
    font-size: 0.9rem;
    color: var(--grey-color);
}
</style>
