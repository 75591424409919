<template>
    <div class="services-infos">

        <section id="services-hero">
            <h1>Infos sur nos services de lavage et polissage.</h1>
            <button @click="scrollToMap">Plus d'Infos</button>
        </section>

        <section id="services-section">
            <h2>Nos services</h2>
        </section>
        <div class="effect-container"></div>

        <div id="washing-section" class="washing-section-container">
            <h3>Service de Nettoyage</h3>
            <p>La qualité de notre service de nettoyage est notre engagement !
                en proposant des produits bio et respectueux de l'environnement.
            </p>
            <div class="washing-packages">
                <h4>Packages de base</h4>
                <ul>
                    <li>
                        <strong id="package-splash">Package Splash (39.90€) :</strong> Nettoyage de base idéal pour un
                        entretien régulier.
                        <ul>
                            <li>Aspiration complète de l'habitacle</li>
                            <li>Nettoyage des vitres intérieures</li>
                            <li>Nettoyage et finition des plastiques</li>
                        </ul>
                        <p>Temps d'intervention : 45 minutes</p>
                    </li>
                    <li>
                        <strong id="package-auto">Package Auto (69.90€) :</strong> Nettoyage en profondeur pour une
                        voiture propre et désinfectée.
                        <ul>
                            <li><a href="#" @click.prevent="scrollToElement('#package-splash')">Tous les éléments du
                                    Package Splash</a></li>
                            <li>Aspiration du coffre</li>
                            <li>Désinfection complète du véhicule</li>
                            <li>Shampouinage des tapis</li>
                        </ul>
                        <p>Temps d'intervention : 1 heure</p>
                    </li>
                    <li>
                        <strong id="package-bm">Package BM (89.90€) :</strong> Nettoyage premium avec rénovation des
                        plastiques.
                        <ul>
                            <li><a href="#" @click.prevent="scrollToElement('#package-auto')">Tous les éléments du
                                    Package Auto</a></li>
                            <li>Rénovation des plastiques (restauration de la couleur et de la souplesse)</li>
                            <li>Shampouinage des sièges</li>
                        </ul>
                        <p>Temps d'intervention : 1 heure 30 minutes</p>
                    </li>
                </ul>
            </div>
            <router-link class="button-container" to="/appointment">
                <button class="button-appointment">Prendre RDV</button>
            </router-link>
        </div>

        <div id="polishing-section" class="polishing-section-container">
            <h3>Service de polissage céramique</h3>
            <p>N’hésitez pas à protéger votre véhicule avec une pose de céramique en créant un effet hydrophobe,
                repoussant l'eau et facilitant la visibilité sous la
                pluie tout en réduisant l'adhérence de la saleté et
                et des contaminants et en améliorant l'éclat de la
                peinture.
            </p>
            <div class="polishing-packages">
                <h4>Formules</h4>
                <ul>
                    <li>
                        <strong id="formule-1">Formule 1 (139.90€) :</strong> Brillance et protection de base.
                        <ul>
                            <li>Polissage : Élimine les micro-rayures et les oxydations.</li>
                            <li>Céramique : Crée une barrière protectrice durable, repousse l'eau et les saletés.</li>
                        </ul>
                        <p>Temps d'intervention : 1 heure</p>
                    </li>
                    <li>
                        <strong id="formule-2">Formule 2 (189.90€) :</strong> Nettoyage complet + brillance.
                        <ul>
                            <li><a href="#" @click.prevent="scrollToElement('#formule-1')">Tous les éléments de la
                                    Formule 1</a></li>
                            <li>Nettoyage à vapeur extérieur</li>
                            <li><a href="#" @click.prevent="scrollToElement('#package-auto')">Package Auto (pour un
                                    intérieur impeccable)</a></li>
                        </ul>
                        <p>Temps d'intervention : 1 heure 30 minutes</p>
                    </li>
                    <li>
                        <strong id="formule-3">Formule 3 (249.90€) :</strong> Nettoyage premium + brillance.
                        <ul>
                            <li><a href="#" @click.prevent="scrollToElement('#formule-2')">Tous les éléments de la
                                    Formule 2</a></li>
                            <li>Rénovation des phares</li>
                            <li><a href="#" @click.prevent="scrollToElement('#package-bm')">Package BM (pour une
                                    rénovation complète)</a></li>
                        </ul>
                        <p>Temps d'intervention : 2 heures</p>
                    </li>
                </ul>
            </div>
            <router-link class="button-container" to="/appointment">
                <button class="button-appointment">Prendre RDV</button>
            </router-link>
        </div>

        <div class="effect-container-windshield-section"></div>

        <div id="windshield-section" class="windshield-section-container">
            <h3>Service de Remplacement de Pare-Brise</h3>
            <p>Chez SPLASH AUTO BM, ce service est <strong>réservé aux assurés brise glace</strong>. Si vous changez
                votre pare-brise, nous vous offrons :</p>
            <ul>
                <li>La franchise offerte</li>
                <li>Une carte cadeau au choix (Amazon, Airbnb, Total, etc.) <strong>ou</strong> la possibilité d'obtenir
                    une formule 1 à 139.90€ + un package auto !</li>
            </ul>
            <p>De plus, bénéficiez de notre système de parrainage :</p>
            <ul>
                <li>Recevez 50€ pour chaque personne que vous amenez et qui remplace son pare-brise chez nous.</li>
            </ul>
            <p>Temps d'intervention : 2 heures</p>
            <router-link class="button-container" to="/appointment">
                <button class="button-appointment">Prendre RDV</button>
            </router-link>
        </div>
        <div class="effect-container-extra-section"></div>

        <div id="extras-section" class="extras-section-container">
            <h3>Extras</h3>
            <p>Ces services peuvent être ajoutés à n'importe quel package ou formule :</p>
            <ul>
                <li>
                    <strong>Nettoyage des Tapis (19.90€) :</strong> Nettoyage en profondeur de vos tapis de voiture.
                </li>
                <li>
                    <strong>Nettoyage extérieur à vapeur (29.90€) :</strong> Enlève la saleté tenace et les
                    insectes de
                    votre carrosserie.
                </li>
                <li>
                    <strong>Rénovation des Phares Avant (39.90€) :</strong> Redonne de la transparence à vos phares
                    avant ternis ou jaunis.
                </li>
                <li>
                    <strong>Rénovation des Phares Arrière (39.90€) :</strong> Redonne de la transparence à vos phares
                    arrière ternis ou jaunis.
                </li>
                <li>
                    <strong>Nettoyage Complet des Sièges (49.90€) :</strong> Élimine les taches et les saletés
                    incrustées
                    dans vos sièges.
                </li>
            </ul>
            <router-link class="button-container" to="/appointment">
                <button class="button-appointment">Prendre RDV</button>
            </router-link>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ServicesInfos',
    methods: {
        scrollToMap() {
            const element = document.querySelector('#services-section');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        scrollToElement(elementId) {
            const element = document.querySelector(elementId);
            const offset = 50;

            if (element) {
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }
        }
    },
}
</script>

<style>
/* Hero Section */
#services-hero {
    position: relative;
    width: 100%;
    padding: 18rem 8rem;
    color: var(--white-color);
    font-size: larger;
    overflow: hidden;
    z-index: 1;
}

#services-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-cottonbro-4488641.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

#services-hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, var(--black-color-50) 25%, var(--yellow-color) 100%);
    z-index: 2;
}

#services-hero h1 {
    max-width: 25rem;
    text-transform: uppercase;
    position: relative;
    z-index: 4;
    font-size: 2.5rem;
}

#services-hero button {
    position: relative;
    z-index: 4;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

/* Services Section */
#services-section {
    text-align: center;
    position: relative;
    top: -0.125rem;
}

#services-section h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--yellow-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 3;
}

/* Services Section Container */
.washing-section-container,
.windshield-section-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: -moz-fit-content;
    height: fit-content;
    color: var(--black-color);
    background-color: var(--white-color);
    text-transform: uppercase;
    margin-top: -1.25rem;
    padding: 3rem 2rem;
    z-index: 1;
}

.washing-section-container::before {
    content: "";
    position: absolute;
    bottom: -1.125rem;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--white-color);
    z-index: 1;
}

.washing-section-container::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--white-color);
    transform: rotate(45deg);
    z-index: 2;
}

.polishing-section-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: -moz-fit-content;
    height: fit-content;
    color: var(--white-color);
    text-transform: uppercase;
    overflow: hidden;
    padding: 2rem 2rem 5rem 3rem;
    z-index: 0;
}

.polishing-section-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-dimkidama-14908957.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: -1;
}

.polishing-section-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black-color);
    opacity: 0.75;
    z-index: -1;
}

.washing-section-container,
.polishing-section-container,
.extras-section-container,
.windshield-section-container {
    font-size: .91rem;
}

.washing-section-container h3,
.polishing-section-container h3,
.extras-section-container h3,
.windshield-section-container h3 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--yellow-color);
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

.washing-section-container ul li,
.polishing-section-container ul li,
.extras-section-container ul li,
.windshield-section-container ul li {
    margin: 1rem 0;
    font-weight: bold;
    list-style: square;
}

.washing-section-container ul li strong,
.polishing-section-container ul li strong,
.extras-section-container ul li strong,
.windshield-section-container ul li strong {
    color: var(--yellow-color);
    font-weight: bold;
}

.washing-section-container .button-container,
.windshield-section-container .button-container {
    display: flex;
    align-self: flex-end;
}

.polishing-section-container .button-container {
    display: flex;
    align-self: flex-start;
}

.washing-section-container .button-appointment,
.polishing-section-container .button-appointment,
.extras-section-container .button-appointment,
.windshield-section-container .button-appointment {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

.effect-container-windshield-section {
    position: relative;
    top: -1.2rem;
    z-index: 1;
}

.effect-container-windshield-section::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 100%;
    height: 1.25rem;
    background: var(--white-color);
    z-index: 1;
}

.effect-container-windshield-section::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: calc(20% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--white-color);
    transform: rotate(45deg);
    z-index: 0;
}

.effect-container-extra-section {
    position: relative;
    top: 1.2rem;
    z-index: 1;
}

.effect-container-extra-section::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--white-color);
    z-index: 1;
}

.effect-container-extra-section::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--white-color);
    transform: rotate(45deg);
    z-index: 0;
}

/* Extras Section */
.extras-section-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: fit-content;
    color: var(--white-color);
    background-color: var(--black-color);
    text-transform: uppercase;
    padding: 2rem 2rem 5rem 3rem;
    z-index: 0;
}

.extras-section-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-tima-miroshnichenko-6872603.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: -1;
}

.extras-section-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black-color);
    opacity: 0.75;
    z-index: -1;
}

.extras-section-container h3 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--yellow-color);
}

.extras-section-container ul li {
    margin: 1rem 0;
    font-weight: bold;
}

.extras-section-container ul li strong {
    color: var(--yellow-color);
    font-weight: bold;
}

.extras-section-container .button-container {
    display: flex;
    align-self: flex-start;
}

.extras-section-container .button-appointment {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}


.button-appointment:hover,
.button-container:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
    text-decoration: none;
}


/* Responsive Styles */
@media (max-width: 1024px) {
    #services-hero {
        padding: 15rem 4rem;
    }

    #services-hero h1 {
        font-size: 2rem;
    }

    #services-hero button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    #services-section h2 {
        font-size: 2rem;
    }

    .washing-section-container h3,
    .polishing-section-container h3,
    .extras-section-container h3,
    .windshield-section-container h3 {
        font-size: 2rem;
    }

    .washing-section-container ul li,
    .polishing-section-container ul li,
    .extras-section-container ul li,
    .windshield-section-container ul li {
        font-size: 0.875rem;
    }

    .washing-section-container p,
    .polishing-section-container p,
    .extras-section-container p,
    .windshield-section-container p {
        font-size: 0.75rem;
    }

    .washing-section-container .button-appointment,
    .polishing-section-container .button-appointment,
    .extras-section-container .button-appointment,
    .windshield-section-container .button-appointment {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }
}

@media (max-width: 768px) {
    #services-hero {
        padding: 10rem 2rem;
    }

    #services-hero h1 {
        font-size: 2rem;
        max-width: 15rem;
    }

    #services-hero button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    #services-section h2 {
        font-size: 1.4rem;
    }

    .washing-section-container h3,
    .polishing-section-container h3,
    .extras-section-container h3,
    .windshield-section-container h3 {
        font-size: 1.4rem;
    }

    .washing-section-container ul li,
    .polishing-section-container ul li,
    .extras-section-container ul li,
    .windshield-section-container ul li {
        font-size: 0.75rem;
    }

    .washing-section-container .button-appointment,
    .polishing-section-container .button-appointment,
    .extras-section-container .button-appointment,
    .windshield-section-container .button-appointment {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
        width: 100%;
    }
}

@media (max-width: 480px) {
    #services-hero {
        padding: 8rem 1rem;
    }

    #services-hero h1 {
        font-size: 1.5rem;
    }

    #services-hero button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    #services-section h2 {
        font-size: 1.2rem;
    }

    .washing-section-container h3,
    .polishing-section-container h3,
    .extras-section-container h3,
    .windshield-section-container h3 {
        font-size: 1.2rem;
    }

    .washing-section-container .button-appointment,
    .polishing-section-container .button-appointment,
    .extras-section-container .button-appointment,
    .windshield-section-container .button-appointment {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }
}
</style>
