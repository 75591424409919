<template>
    <div class="pay-form">
        <h2 class="text-yellow">Résumé de Commande et Paiement</h2>
        <div>
            <h3>Résumé de la Commande</h3>
            <p><strong>Service:</strong> {{ bookingData.service }}</p>
            <p v-if="bookingData.service === 'Remplacement Pare-Brise'">
                <strong>Offre Spéciale:</strong>
                <span v-if="bookingData.specialOffer === 'Carte Cadeau'">
                    Gratuit si assuré brise glace. Bénéficiez de la franchise offerte, d'une carte cadeau au choix
                    (Amazon, Airbnb, ou Total), et d'articles multimédia. Parrainage : 50€ pour chaque nouveau client
                    recommandé !
                </span>
                <span v-else-if="bookingData.specialOffer === 'Formule 1 + Package Auto'">
                    Gratuit si assuré brise glace. Bénéficiez de la franchise offerte et de la formule "Formule 1 +
                    Package Auto" gratuite (d'une valeur de 139.90€). Parrainage : 50€ pour chaque nouveau client
                    recommandé !
                </span>
            </p>
            <p v-else-if="bookingData.service !== 'Extra'">
                <strong>Forfait/Formule:</strong> {{ bookingData.package }} - {{ packagePrice }}€
            </p>
            <p v-if="bookingData.extras.length > 0">
                <strong>Extras:</strong> {{ bookingData.extras.join(', ') }} - {{ extrasPrice.total }}€
            </p>
            <p v-else>
                <strong>Extras:</strong> Aucun extra sélectionné
            </p>
            <p> <strong>Type de Véhicule: </strong>
                <span v-if="vehicleTypePrice === '0.00'">Gratuit</span>
                <span v-else-if="vehicleTypePrice === 'Sur devis'">Sur devis</span>
                <span v-else>{{ vehicleTypeDisplay }} - {{ vehicleTypePrice }}€</span>
            </p>
            <p><strong>Date:</strong> {{ bookingData.date }}</p>
            <p><strong>Heure:</strong> {{ combineTimeSlots(bookingData) }}</p>
            <p><strong>Total à Payer:</strong> {{ totalDisplay }}</p>
            <p v-if="prepaymentRequired">
                <strong>Pré-paiement de 15€ requis pour valider votre rendez-vous:</strong>
                Ce montant garantit votre réservation et sera déduit du total à régler.
            </p>
            <p v-if="remainingAmountOnTime !== 'Gratuit'">
                <strong>Montant restant à payer:</strong> {{ remainingAmountOnTime }}€
            </p>
            <p v-else>
                <strong>Le jour du rendez-vous:</strong> Aucun montant à payer, le service est gratuit.
            </p>
            <p v-if="lateFeeWarning" class="warning">
                <strong>En cas de retard de plus de 15 minutes:</strong> Le pré-paiement ne sera pas déduit du total.
            </p>
            <p v-if="totalLate === 'Montant à déterminer sur devis'">
                <strong>Montant à payer si en retard de plus de 15 min:</strong> À déterminer sur devis.
            </p>
            <p v-else>
                <strong>Si vous êtes en retard de plus de 15 min:</strong> {{ isNaN(totalLate) ? totalLate : totalLate +
                    '€' }}
            </p>

            <h3>Veuillez entrer les informations de carte pour le pré-paiement de 15€</h3>
            <p>
            <div id="card-element"></div>
            </p>
            <p>
            <div id="card-errors" role="alert"></div>
            <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
            </p>
        </div>
        <div class="form-navigation">
            <button @click="prevStep">Précédent</button>
            <button class="next-button" @click="confirmOrder" v-if="cardComplete">
                Confirmer la Commande
            </button>
        </div>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe('pk_live_51Q3hMxAQPO14OaBlXAIZBKCt0tadurlioNiT7PRlRdw2tFUCETRnsfCbeXGEtA7mIgaRz5cgknSCGoTJ2ThKRRm000OuiiA6uI');

export default {
    props: {
        bookingData: Object
    },
    data() {
        return {
            userInfo: {},
            clientSecret: '',
            cardComplete: false,
            card: null,
            paymentSucceeded: false,
            successMessage: ''
        };
    },
    computed: {
        packagePrice() {
            return this.bookingData.service === 'Remplacement Pare-Brise' ? 0.00 : parseFloat(this.bookingData.packagePrice || 0).toFixed(2);
        },
        extrasPrice() {
            let extrasTotal = 0;
            const extraPrices = {
                'Nettoyage des Tapis': 19.90,
                'Nettoyage extérieur à vapeur': 29.90,
                'Rénovation des Phares Avant': 39.90,
                'Rénovation des Phares Arrière': 39.90,
                'Nettoyage Complet des Sièges': 49.90,
            };

            (this.bookingData.extras || []).forEach(extra => {
                if (extraPrices[extra]) {
                    extrasTotal += extraPrices[extra];
                }
            });

            return {
                total: extrasTotal.toFixed(2),
                hasExtras: extrasTotal > 0
            };
        },
        vehicleTypePrice() {
            const type = this.userInfo.vehicleType || this.bookingData.personalInfo.vehicleType;
            const vehicleTypePrices = {
                'Citadine': 0.00,
                'Berline/Compact': 9.90,
                '4x4/Familiale/Break': 15.90,
                'Van/Pick Up': 'Sur devis',
            };
            const price = vehicleTypePrices[type];
            return price === 'Sur devis' ? price : price.toFixed(2);
        },
        vehicleTypeDisplay() {
            const type = this.userInfo.vehicleType || this.bookingData.personalInfo.vehicleType;
            const vehicleTypePrices = {
                'Citadine': 'Gratuit',
                'Berline/Compact': 'Berline/Compact',
                '4x4/Familiale/Break': '4x4/Familiale/Break',
                'Van/Pick Up': 'Sur devis',
            };
            return vehicleTypePrices[type] || 'Indéterminé';
        },
        totalDisplay() {
            if (this.totalOnTime === 'Le service est gratuit' || this.totalOnTime === 'Sur devis') {
                return this.totalOnTime;
            }
            return `${this.totalOnTime}€`;
        },
        prepaymentRequired() {
            return this.totalOnTime !== 'Le service est gratuit';
        },
        totalOnTime() {
            const vehiclePrice = parseFloat(this.vehicleTypePrice) || 0;
            const packagePrice = parseFloat(this.bookingData.packagePrice) || 0;
            const extrasTotal = parseFloat(this.extrasPrice.total) || 0;

            const total = (vehiclePrice + packagePrice + extrasTotal).toFixed(2);
            return total === '0.00' ? 'Le service est gratuit' : total;
        },
        remainingAmountOnTime() {
            if (this.totalOnTime === 'Sur devis') {
                return 'Montant à déterminer sur devis';
            }
            if (this.totalOnTime === 'Le service est gratuit') {
                return 'Gratuit';
            }
            const remaining = parseFloat(this.totalOnTime) - 15;
            if (remaining < 0) {
                return 'Votre prépaiement de 15€ sera remboursé';
            }
            return remaining.toFixed(2);
        },
        lateFeeWarning() {
            return this.totalOnTime !== 'Le service est gratuit';
        },
        totalLate() {
            if (this.vehicleTypePrice === 'Sur devis') {
                return 'Montant à déterminer sur devis';
            }
            const vehiclePrice = parseFloat(this.vehicleTypePrice) || 0;
            const packagePrice = parseFloat(this.packagePrice) || 0;
            const extrasTotal = parseFloat(this.extrasPrice.total) || 0;
            const total = (packagePrice + extrasTotal + vehiclePrice).toFixed(2);
            return total === '0.00' ? 'Le prépaiement de 15€ ne sera pas remboursé.' : total;
        }
    },
    async created() {
        if (this.$store.state.user) {
            this.userInfo = this.$store.state.user;
        }
        const response = await fetch('https://api.splashauto-bm.fr/create-payment-intent', {
            method: 'POST',
            body: JSON.stringify({ amount: 1500 }),
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        });
        const { clientSecret } = await response.json();
        this.clientSecret = clientSecret;
    },
    async mounted() {
        const stripe = await stripePromise;
        const elements = stripe.elements();
        this.card = elements.create('card');
        this.card.mount('#card-element');

        this.card.on('change', event => {
            const displayError = document.getElementById('card-errors');
            displayError.textContent = event.error ? event.error.message : '';
            this.cardComplete = !event.error;
        });
    },
    methods: {
        combineTimeSlots(reservation) {
            const timeSlot = reservation.timeSlot;
            const timeSlots = reservation.timeSlots || [];
            return timeSlots.length === 0 ? timeSlot : `${timeSlot.split(' - ')[0]} - ${timeSlots[timeSlots.length - 1].split(' - ')[1]}`;
        },
        async confirmOrder() {
            if (!this.clientSecret) {
                console.error('Client secret not available');
                return;
            }
            const stripe = await stripePromise;
            const { error, paymentIntent } = await stripe.confirmCardPayment(this.clientSecret, {
                payment_method: { card: this.card },
            });
            if (error) {
                console.error('Payment failed:', error);
                document.getElementById('card-errors').textContent = error.message;
            } else if (paymentIntent.status === 'succeeded') {
                this.successMessage = 'Le paiement a réussi ! Vous serez redirigé dans 5 secondes.';
                await this.handlePaymentSuccess(paymentIntent.id);
            }
        },
        async handlePaymentSuccess(paymentIntentId) {
            const user = this.$store.state.user;
            if (!user || !user.userId) {
                console.error('User ID is missing');
                return;
            }
            try {
                const response = await fetch('https://api.splashauto-bm.fr/payment-success', {
                    method: 'POST',
                    body: JSON.stringify({
                        user_id: user.userId,
                        service: this.bookingData.service,
                        package: this.bookingData.package,
                        extras: this.bookingData.extras,
                        date: this.bookingData.date,
                        time_slot: this.bookingData.timeSlot.replace(/\s*-\s*/, ' - '),
                        amount: [parseFloat(this.totalOnTime)],
                        vehicle_type: this.userInfo.vehicleType || this.bookingData.personalInfo.vehicleType,
                        payment_intent_id: paymentIntentId
                    }),
                    credentials: 'include',
                    headers: { 'Content-Type': 'application/json' }
                });
                const result = await response.json();
                if (response.ok) {
                    this.successMessage = result.message || 'Le paiement a réussi ! Vous serez redirigé dans 5 secondes.';
                    setTimeout(() => this.$router.go(), 7500);
                } else {
                    console.error('Error in response:', result.error);
                    this.successMessage = result.error || 'Une erreur est survenue. Veuillez réessayer.';
                }
            } catch (error) {
                console.error('An error occurred during payment success handling:', error);
                this.successMessage = 'Une erreur est survenue. Veuillez réessayer.';
            }
        },
        prevStep() {
            this.$emit('prev');
        },
    }
};
</script>

<style scoped>
.pay-form {
    width: 100%;
}

h2 {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

h3 {
    width: fit-content;
    margin-bottom: 1rem;
}

p {
    margin: 1rem 0 1rem 0;
    padding: 0;
}

button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}

.warning,
#card-errors {
    color: var(--red-color);
    font-weight: bold;
}

.success-message {
    color: var(--green-color);
    font-weight: bold;
}
</style>
