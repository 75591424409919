<template>
    <div class="date-select-time">
        <div class="date-container">
            <h2 class="text-yellow">Sélectionnez une date et un créneau horaire</h2>
            <label for="date-input"><strong>Date</strong></label>
            <input type="date" id="date-input" v-model="selectedDate" @change="fetchAvailableSlots"
                autocomplete="off" />

            <label for="time-select" v-if="availableSlots.length"><strong>Créneau horaire</strong></label>
            <select id="time-select" v-model="selectedSlot" v-if="availableSlots.length" autocomplete="off">
                <option value="" disabled>Sélectionnez un créneau horaire</option>
                <option v-for="slot in availableSlots" :key="slot" :value="slot">{{ slot }}</option>
            </select>

            <!-- Message si aucun créneau n'est disponible pour la date sélectionnée -->
            <div v-else-if="selectedDate && !availableSlots.length">
                <p>Aucun créneau disponible pour la date sélectionnée.</p>
            </div>

            <!-- Message d'erreur si pas assez de créneaux consécutifs après avoir sélectionné l'heure -->
            <div v-if="selectedDate && selectedSlot && !hasEnoughConsecutiveSlots">
                <p style="color: red;">Pas assez de créneaux horaires consécutifs pour ce service.</p>
            </div>
        </div>

        <div class="form-navigation">
            <button @click="prevStep">Précédent</button>
            <!-- Le bouton suivant n'apparaît que si le créneau horaire est valide -->
            <button class="next-button" v-if="selectedDate && selectedSlot && hasEnoughConsecutiveSlots"
                @click="nextStep">
                Suivant
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bookingData: Object
    },
    data() {
        return {
            selectedDate: this.bookingData.date || '',
            availableSlots: [],
            selectedSlot: '',
            hasEnoughConsecutiveSlots: true // Pour suivre si assez de créneaux consécutifs sont disponibles
        };
    },
    methods: {
        async fetchAvailableSlots() {
            if (!this.selectedDate) {
                this.availableSlots = [];
                return;
            }

            let serviceName = '';

            // Si le service est "Extra", nous devons concaténer les extras sélectionnés
            if (this.bookingData.service === 'Extra') {
                serviceName = this.bookingData.extras.map(extra => encodeURIComponent(extra)).join(',');
            } else {
                serviceName = encodeURIComponent(this.bookingData.package); // Encoder le nom du package sélectionné
            }

            try {
                const response = await fetch(`https://api.splashauto-bm.fr/available_slots/${this.selectedDate}?service_name=${serviceName}`);
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération des créneaux horaires.');
                }

                const data = await response.json();
                this.availableSlots = data.available_slots || [];
                this.selectedSlot = '';

                // Vérifier si suffisamment de créneaux consécutifs sont disponibles
                this.checkConsecutiveSlots();
            } catch (error) {
                console.error(error);
                this.availableSlots = [];
            }
        },
        checkConsecutiveSlots() {
            const requiredSlots = Math.ceil(this.getServiceDuration() / 60); // Nombre de créneaux nécessaires

            // Vérifier si le créneau sélectionné a assez de créneaux consécutifs
            const selectedIndex = this.availableSlots.indexOf(this.selectedSlot);
            if (selectedIndex === -1 || this.availableSlots.length < selectedIndex + requiredSlots) {
                this.hasEnoughConsecutiveSlots = false;
                return;
            }

            const nextSlots = this.availableSlots.slice(selectedIndex, selectedIndex + requiredSlots);
            this.hasEnoughConsecutiveSlots = this.areSlotsConsecutive(nextSlots);
        },
        getServiceDuration() {
            return this.bookingData.serviceDuration || 60; // Par défaut, 60 minutes si non fourni
        },
        areSlotsConsecutive(slots) {
            for (let i = 0; i < slots.length - 1; i++) {
                const endCurrent = slots[i].split(' - ')[1];
                const startNext = slots[i + 1].split(' - ')[0];
                if (endCurrent !== startNext) {
                    return false;
                }
            }
            return true;
        },
        nextStep() {
            const selectedIndex = this.availableSlots.indexOf(this.selectedSlot);
            const requiredSlots = Math.ceil(this.getServiceDuration() / 60);

            // Récupérer les créneaux multiples à partir de selectedSlot
            const selectedSlots = this.availableSlots.slice(selectedIndex, selectedIndex + requiredSlots);

            // Mettre à jour bookingData avec les créneaux multiples
            this.$emit('update:bookingData', {
                date: this.selectedDate,
                timeSlot: selectedSlots[0], // Premier créneau
                timeSlots: selectedSlots.slice(1) // Créneaux supplémentaires
            });

            this.$emit('next');
        },
        prevStep() {
            this.$emit('prev');
        }
    },
    watch: {
        selectedSlot() {
            this.checkConsecutiveSlots(); // Re-vérifie chaque fois que le créneau sélectionné change
        }
    }
};
</script>

<style scoped>
.date-select-time {
    width: 100%;
}

.date-container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 1rem;
}

input,
textarea,
select {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
}

button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
}
</style>
