import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import store from './store';

import HomePage from './views/HomePage.vue';
import ContactPage from './views/ContactPage.vue';
import AppointmentPage from './views/AppointmentPage.vue';
import LegalNotice from './views/LegalNotice.vue';
import PrivacyPolicy from './views/PrivacyPolicy.vue';
import MapHoursPage from './views/MapHours.vue';
import LoginRegister from './views/LoginRegister.vue';
import ServicesInfos from './views/ServicesInfos.vue';
import ProfilePage from './views/Profile.vue';
import AdminPage from './views/AdminPage.vue';

const routes = [
    { path: '/', component: HomePage, meta: { title: 'Page d\'accueil' } },
    { path: '/contact', component: ContactPage, meta: { title: 'Contact' } },
    { path: '/appointment', component: AppointmentPage, meta: { title: 'Prendre un rendez-vous' } },
    { path: '/legal-notice', component: LegalNotice, meta: { title: 'Mentions légales' } },
    { path: '/privacy-policy', component: PrivacyPolicy, meta: { title: 'Politique de confidentialité' } },
    { path: '/map-hours', component: MapHoursPage, meta: { title: 'Carte et horaires' } },
    { path: '/login-register', component: LoginRegister, meta: { title: 'Connexion/Inscription' } },
    { path: '/services-infos', component: ServicesInfos, meta: { title: 'Informations sur les services' } },
    { path: '/profile', component: ProfilePage, meta: { title: 'Mon profil', requiresAuth: true } },
    { path: '/admin', component: AdminPage, meta: { title: 'Page d\'administration', requiresAuth: true, isAdmin: true } },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
});

const app = createApp(App);

app.use(router);

router.afterEach((to) => {
    if (to.meta.title) {
        document.title = to.meta.title;
    }
});

app.use(store);

router.beforeEach(async (to, from, next) => {
    const isAuthenticated = store.state.isAuthenticated;
    const isAdmin = store.state.isAdmin;

    if (to.path === '/login-register' && isAuthenticated) {
        next('/profile');
    } else if (to.meta.requiresAuth && !isAuthenticated) {
        next('/login-register');
    } else if (to.meta.isAdmin && !isAdmin) {
        next('/');
    } else {
        next();
    }
});


app.mount('#app');
