<template>
    <div class="admin">
        <div class="tabs">
            <div class="tab" :class="{ active: activeTab === 'user-management' }"
                @click="setActiveTab('user-management')">
                Utilisateurs
            </div>
            <div class="tab" :class="{ active: activeTab === 'availability' }" @click="setActiveTab('availability')">
                Agenda
            </div>
            <div class="tab" :class="{ active: activeTab === 'appointments' }" @click="setActiveTab('appointments')">
                RDV
            </div>
            <div class="tab-bar" :style="{ width: tabBarWidth + 'px', transform: `translateX(${tabBarPosition}px)` }">
            </div>
        </div>

        <div class="tab-content">
            <div v-if="activeTab === 'user-management'">
                <user-management></user-management>
            </div>
            <div v-if="activeTab === 'availability'">
                <availability-calendar></availability-calendar>
            </div>
            <div v-if="activeTab === 'appointments'">
                <appointments-viewer></appointments-viewer>
            </div>
        </div>
    </div>
</template>

<script>
import UserManagement from './UserManagement.vue';
import AvailabilityCalendar from './AvailabilityCalendar.vue';
import AppointmentsViewer from './AppointmentsViewer.vue';

export default {
    name: 'AdminPage',
    components: {
        UserManagement,
        AvailabilityCalendar,
        AppointmentsViewer,
    },
    data() {
        return {
            activeTab: 'user-management',
            tabBarWidth: 0,
            tabBarPosition: 0,
        };
    },
    mounted() {
        this.calculateTabBar();
        window.addEventListener('resize', this.calculateTabBar);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.calculateTabBar);
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab;
            this.$nextTick(() => {
                this.calculateTabBar();
            });
        },
        calculateTabBar() {
            const tabs = Array.from(this.$el.querySelectorAll('.tab'));
            const activeTabIndex = tabs.findIndex(tab => tab.classList.contains('active'));
            if (tabs.length > 0 && activeTabIndex !== -1) {
                const tab = tabs[activeTabIndex];
                const tabRect = tab.getBoundingClientRect();
                this.tabBarWidth = tabRect.width;
                this.tabBarPosition = tabRect.left - this.$el.querySelector('.tabs').getBoundingClientRect().left;
            }
        },
    },
};
</script>

<style>
.admin {
    padding: 2em;
}

.tabs {
    position: relative;
}

.tab {
    cursor: pointer;
    font-size: 2.5rem;
    font-weight: bold;
    text-transform: uppercase;
    padding: 0 1rem;
    margin: 32.2px 0 16px 0;
    color: var(--white-color);
    transition: color 0.3s, width 0.3s;
}

.tab.active {
    color: var(--yellow-color);
}

.tab:hover {
    color: var(--yellow-color);
}

.tab-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    background-color: var(--yellow-color);
    transition: transform 0.3s, width 0.3s;
}

.tab-content {
    padding: 0;
}

@media (max-width: 1024px) {
    .tab {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    .tab {
        font-size: 1.4rem;
        padding: 0;
    }

    .tab-content {
        padding: 0;
    }
}

@media (max-width: 480px) {
    .tab {
        font-size: 1.05rem;
    }
}
</style>
