<template>
    <div id="contact">

        <section id="contact-hero">
            <h1>Des questions sur le lavage ou le polissage ? Contactez-nous !</h1>
            <button @click="scrollToForm">Nous Contacter</button>
        </section>

        <section id="contact-services">
            <h2>Contactez-nous</h2>
        </section>
        <div class="effect-container"></div>

        <div class="contact-section-container">
            <div class="info-contact">
                <p>Nous sommes heureux de recevoir vos questions, commentaires et suggestions. Veuillez remplir le
                    formulaire ci-dessous afin que nous puissions vous aider. Nous vous répondrons dès que possible.</p>

                <h2 class="text-yellow">Instructions :</h2>
                <ul>
                    <li><strong>Nom :</strong> Indiquez votre nom complet pour que nous puissions vous adresser
                        correctement.</li>
                    <li><strong>E-mail :</strong> Entrez une adresse e-mail valide où nous pourrons vous répondre.
                        Assurez-vous qu'elle est correcte pour éviter tout retard dans la réponse.</li>
                    <li><strong>Sujet :</strong> Spécifiez le sujet de votre message pour que nous puissions diriger
                        votre demande vers le bon département ou la bonne personne.</li>
                    <li><strong>Message :</strong> Décrivez clairement votre demande ou votre message. Plus votre
                        message est précis, plus il sera facile pour nous de vous apporter une réponse adéquate.</li>
                </ul>
            </div>
            <div class="form">
                <form @submit.prevent="submitForm">
                    <div class="form-group">
                        <label for="name">Nom :</label>
                        <input type="text" id="name" v-model="form.name" required autocomplete="name" />
                        <span v-if="!form.name && submitted" class="error">Le nom est requis.</span>
                    </div>

                    <div class="form-group">
                        <label for="email">E-mail :</label>
                        <input type="email" id="email" v-model="form.email" required autocomplete="email" />
                        <span v-if="!form.email && submitted" class="error">L'email est requis.</span>
                        <span v-if="!isValidEmail(form.email) && submitted" class="error">L'email n'est pas
                            valide.</span>
                    </div>

                    <div class="form-group">
                        <label for="subject">Sujet :</label>
                        <input type="text" id="subject" v-model="form.subject" required autocomplete="off" />
                        <span v-if="!form.subject && submitted" class="error">Le sujet est requis.</span>
                    </div>

                    <div class="form-group">
                        <label for="message">Message :</label>
                        <textarea id="message" v-model="form.message" required autocomplete="off"></textarea>
                        <span v-if="!form.message && submitted" class="error">Le message est requis.</span>
                    </div>

                    <div class="form-actions">
                        <button type="submit">Envoyer le message</button>
                        <button type="button" @click="resetForm">Réinitialiser</button>
                    </div>
                </form>
                <div :class="['submission-status', submissionStatusClass]">
                    <p>{{ submissionStatus }}</p>
                </div>
            </div>
        </div>

        <div class="more-info-contact">
            <p><strong>Que se passe-t-il après l'envoi ?</strong></p>
            <p>
                Une fois que vous avez soumis votre message, nous recevrons une notification et nous traiterons
                votre
                demande dans les plus brefs délais. En général, vous devriez recevoir une réponse dans un délai de
                48 heures. Si vous ne recevez pas de réponse dans ce délai, veuillez vérifier votre dossier de spam
                ou nous contacter par un autre moyen.
            </p>

            <p>
                Merci de nous avoir contactés. Nous apprécions votre patience et nous nous efforçons de vous fournir
                une réponse utile et rapide.
            </p>
        </div>

    </div>
</template>

<script>
export default {
    name: 'ContactPage',
    data() {
        return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            submitted: false,
            submissionStatus: ''
        };
    },
    computed: {
        submissionStatusClass() {
            if (this.submissionStatus.includes('Erreur')) {
                return 'error';
            } else if (this.submissionStatus.includes('succès')) {
                return 'success';
            }
            return '';
        }
    },
    methods: {
        scrollToForm() {
            const element = document.querySelector('#contact-services');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        },
        async submitForm() {
            this.submitted = true;
            if (this.validateForm()) {
                try {
                    const response = await fetch('https://api.splashauto-bm.fr/contact', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(this.form)
                    });

                    const result = await response.json();

                    if (response.ok) {
                        this.submissionStatus = 'Message envoyé avec succès !';
                    } else {
                        this.handleErrorResponse(response.status, result.message);
                    }
                    this.clearStatus();
                } catch (error) {
                    this.submissionStatus = `Erreur : ${error.message}`;
                    this.clearStatus();
                }
            }
        },
        validateForm() {
            return this.form.name && this.form.email && this.isValidEmail(this.form.email) && this.form.subject && this.form.message;
        },
        isValidEmail(email) {
            const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return re.test(email);
        },
        resetForm() {
            this.form.name = '';
            this.form.email = '';
            this.form.subject = '';
            this.form.message = '';
            this.submitted = false;
            this.submissionStatus = '';
        },
        clearStatus() {
            setTimeout(() => {
                this.submissionStatus = '';
            }, 7500);
        },
        handleErrorResponse(status, message) {
            switch (status) {
                case 400:
                    this.submissionStatus = `Erreur : ${message || "Veuillez remplir tous les champs requis avant de soumettre le formulaire."}`;
                    break;
                case 401:
                    this.submissionStatus = "Erreur : Accès refusé. Vous n'êtes pas autorisé à effectuer cette action.";
                    break;
                case 404:
                    this.submissionStatus = "Erreur : Serveur introuvable. Veuillez vérifier l'adresse du serveur.";
                    break;
                case 500:
                    this.submissionStatus = "Erreur : Une erreur interne est survenue. Nous ne pouvons pas traiter votre demande pour le moment.";
                    break;
                case 503:
                    this.submissionStatus = "Erreur : Le service est actuellement indisponible. Veuillez essayer à nouveau plus tard.";
                    break;
                case 504:
                    this.submissionStatus = "Erreur : Le délai de soumission a expiré. Veuillez vérifier votre connexion et réessayer.";
                    break;
                default:
                    this.submissionStatus = `Erreur : ${message || "Impossible d'envoyer votre message en raison d'un problème technique. Veuillez réessayer."}`;
            }
        }
    }
};
</script>

<style>
/* Hero Section */
#contact-hero {
    position: relative;
    width: 100%;
    padding: 18rem 8rem;
    color: var(--white-color);
    font-size: larger;
    overflow: hidden;
    z-index: 1;
}

#contact-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-steve-845241.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

#contact-hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, var(--black-color-50) 25%, var(--yellow-color) 100%);
    z-index: 2;
}

#contact-hero h1 {
    max-width: 25rem;
    text-transform: uppercase;
    position: relative;
    z-index: 4;
    font-size: 2.5rem;
}

#contact-hero button {
    position: relative;
    z-index: 4;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

/* Services Section */
#contact-services {
    text-align: center;
    position: relative;
    top: -0.125rem;
}

#contact-services h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--yellow-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 3;
}

/* Contact Section Container */
.contact-section-container {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    background-color: var(--white-color);
    color: var(--black-color);
    text-transform: uppercase;
    min-height: auto;
    height: -moz-fit-content;
    height: fit-content;
    margin-top: -1.25rem;
    padding-top: 4rem;
    padding-bottom: 3rem;
}

.contact-section-container::before {
    content: "";
    position: absolute;
    bottom: -1.125rem;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--white-color);
    z-index: 1;
}

.contact-section-container::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--white-color);
    transform: rotate(45deg);
    z-index: 2;
}

/* Message de statut de soumission */
.submission-status {
    max-width: 29rem;
    margin: 1rem 0 0 0;
    text-align: start;
    font-weight: bold;
}

.submission-status p {
    margin: 0;
}

.submission-status.success {
    color: var(--green-color);
    font-size: 1.25rem;
}

.submission-status.error {
    color: var(--red-color);
}

.info-contact {
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-contact p {
    margin: 0;
}

.info-contact h2 {
    font-size: 2.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
    width: fit-content;
}

.contact-section-container .form {
    width: fit-content;
    height: 100%;
}

.contact-section-container form {
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;
}

.info-contact ul {
    list-style-type: none;
    padding: 0;
}

.info-contact p {
    font-weight: bold;
}

.info-contact p,
.info-contact ul {
    font-size: .91rem;
}

.info-contact ul li {
    padding-bottom: 1.25rem;
}

.contact-section-container label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.75rem;
}

.contact-section-container input[type="text"],
.contact-section-container input[type="email"],
.contact-section-container textarea {
    width: calc(100% - 11px);
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
    border-radius: 0px;
    transition: border-color 0.3s ease;
}

.contact-section-container input[type="text"]:focus,
.contact-section-container input[type="email"]:focus,
.contact-section-container textarea:focus {
    border-color: var(--yellow-color);
    outline: none;
}

.contact-section-container input[type="text"]:focus,
.contact-section-container input[type="email"]:focus,
.contact-section-container textarea:focus {
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.contact-section-container textarea {
    resize: vertical;
    min-height: 100px;
}

.contact-section-container .error {
    color: var(--red-color);
    font-size: 1.25rem;
}

.contact-section-container .form-actions {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
}

.contact-section-container .form-actions button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

.contact-section-container .form-actions button:hover {
    border-style: solid;
    border-color: var(--black-color);
}

.more-info-contact {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: fit-content;
    color: var(--white-color);
    font-size: larger;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 0;
    padding: 2rem;
    z-index: 0;
}

.more-info-contact::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-deyvi-romero-15310-89955.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

.more-info-contact::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black-color);
    opacity: 0.75;
    z-index: 2;
}

.more-info-contact p {
    font-size: .91rem;
    font-weight: bold;
    z-index: 3;
}

.more-info-contact p strong {
    font-size: 2.5rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    #contact-hero {
        padding: 15rem 4rem;
    }

    #contact-hero h1 {
        font-size: 2rem;
    }

    #contact-hero button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    #contact-services h2 {
        font-size: 2rem;
    }

    .info-contact {
        width: 35%;
        justify-content: space-between;
    }

    .info-contact h2 {
        font-size: 2rem;
    }

    .info-contact p,
    .info-contact ul {
        font-size: 0.75rem;
    }

    .contact-section-container {
        padding-bottom: 3rem;
    }

    .contact-section-container label {
        font-size: small;
    }

    .contact-section-container .form-actions button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .contact-section-container .error {
        font-size: 1rem;
    }

    .submission-status.success {
        font-size: 1rem;
    }

    .more-info-contact p {
        font-size: 0.75rem;
    }

    .more-info-contact p strong {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    #contact-hero {
        padding: 10rem 2rem;
    }

    #contact-hero h1 {
        font-size: 2rem;
        max-width: 15rem;
    }

    #contact-hero button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    #contact-services h2 {
        font-size: 1.4rem;
    }

    .contact-section-container {
        height: fit-content;
        flex-direction: column;
        padding-left: 2rem;
        padding-right: 2rem;
        padding-bottom: 4rem;
    }

    .info-contact {
        width: 100%;
    }

    .info-contact h2 {
        font-size: 1.4rem;
    }

    .contact-section-container .form {
        width: 100%;
    }

    .info-contact ul li {
        padding-bottom: 2rem;
    }

    .contact-section-container .form-actions {
        width: 100%;
    }

    .contact-section-container .form-actions button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
        width: 100%;
    }

    .contact-section-container .error {
        font-size: 0.875rem;
    }

    .submission-status.success {
        font-size: 0.875rem;
    }


    .more-info-contact p strong {
        font-size: 1.4rem;
    }
}

@media (max-width: 480px) {
    #contact-hero {
        padding: 8rem 1rem;
    }

    #contact-hero h1 {
        font-size: 1.5rem;
    }

    #contact-hero button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    #contact-services h2 {
        font-size: 1.2rem;
    }

    .info-contact h2 {
        font-size: 1.2rem;
    }

    .contact-section-container .form-actions button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    .contact-section-container .error {
        font-size: 0.75rem;
    }

    .submission-status.success {
        font-size: 0.75rem;
    }


    .more-info-contact p strong {
        font-size: 1.2rem;
    }
}
</style>
