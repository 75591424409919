import { createStore } from 'vuex';

const API_BASE_URL = 'https://api.splashauto-bm.fr';

async function apiRequest(url, options = {}) {
    try {
        const response = await fetch(`${API_BASE_URL}${url}`, {
            ...options,
            headers: {
                'Content-Type': 'application/json',
                ...options.headers
            },
            credentials: 'include'
        });

        if (response.status === 401) {
            throw new Error('Non autorisé. Veuillez vérifier vos informations d\'identification ou vous reconnecter.');
        }

        if (response.status === 404) {
            return [];
        }

        if (!response.ok) {
            const data = await response.json();
            throw new Error(data.message || 'Erreur lors de la requête. Veuillez réessayer.');
        }

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            return await response.json();
        } else {
            return {};
        }
    } catch (error) {
        console.error(`Erreur API pour l'URL ${url}:`, error);
        throw error;
    }
}

async function apiRequestMultipart(url, formData) {
    try {
        const response = await fetch(`${API_BASE_URL}${url}`, {
            method: 'POST',
            body: formData,
            credentials: 'include'
        });

        const contentType = response.headers.get('Content-Type');
        if (contentType && contentType.includes('application/json')) {
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Erreur lors de la requête multipart. Veuillez réessayer.');
            }
            return data;
        } else {
            return {};
        }
    } catch (error) {
        console.error(`Erreur API multipart pour l'URL ${url}:`, error);
        throw error;
    }
}

export default createStore({
    state: {
        user: JSON.parse(localStorage.getItem('user')) || null,
        isAuthenticated: !!localStorage.getItem('user'),
        isAdmin: JSON.parse(localStorage.getItem('user'))?.roleId === 1,
        users: [],
        reservations: [],
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
            state.isAuthenticated = !!user;
            state.isAdmin = user?.roleId === 1;
            localStorage.setItem('user', JSON.stringify(user));
        },
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_RESERVATIONS(state, reservations) {
            state.reservations = reservations;
        },
        CLEAR_USER(state) {
            state.isAuthenticated = false;
            state.isAdmin = false;
            localStorage.removeItem('user');
            state.reservations = [];
        },
        UPDATE_USER_FILES(state, updatedFiles) {
            state.user = {
                ...state.user,
                ...updatedFiles
            };
            localStorage.setItem('user', JSON.stringify(state.user));
        },
        UPDATE_USER(state, updatedUser) {
            const index = state.users.findIndex(user => user.userId === updatedUser.userId);
            if (index !== -1) {
                state.users.splice(index, 1, updatedUser);
            }
        },
        DELETE_USER(state, userId) {
            state.users = state.users.filter(user => user.userId !== userId);
        },
        UPDATE_RESERVATION_STATUS(state, updatedReservation) {
            const index = state.reservations.findIndex(reservation => reservation.id === updatedReservation.id);
            if (index !== -1) {
                state.reservations.splice(index, 1, updatedReservation);
            }
        },
    },
    actions: {
        async login({ commit }, credentials) {
            try {
                const user = await apiRequest('/login', {
                    method: 'POST',
                    body: JSON.stringify(credentials)
                });

                if (user) {
                    commit('SET_USER', user);
                    return { success: true, user };
                } else {
                    return { success: false, message: 'Identifiants invalides. Veuillez vérifier votre adresse email et mot de passe.' };
                }
            } catch (error) {
                return { success: false, message: error.message || 'Erreur de connexion, veuillez réessayer plus tard.' };
            }
        },

        async register({ dispatch }, userDetails) {
            try {
                const registrationResponse = await apiRequest('/register', {
                    method: 'POST',
                    body: JSON.stringify(userDetails)
                });

                if (!registrationResponse || registrationResponse.error) {
                    throw new Error('Erreur lors de l\'inscription. Veuillez vérifier les informations fournies et réessayer.');
                }

                const loginResponse = await dispatch('login', {
                    email: userDetails.email,
                    password: userDetails.password
                });

                if (loginResponse.success) {
                    await dispatch('fetchUser');
                    return { success: true };
                } else {
                    return { success: false, message: loginResponse.message };
                }
            } catch (error) {
                console.error('Erreur lors de l\'inscription:', error);
                return { success: false, message: error.message || 'Erreur lors de l\'inscription, veuillez réessayer plus tard.' };
            }
        },

        async forgotPassword(_, email) {
            try {
                await apiRequest('/forgot-password', {
                    method: 'POST',
                    body: JSON.stringify({ email })
                });

                return { success: true, message: 'Un nouveau mot de passe a été envoyé à votre adresse e-mail.' };
            } catch (error) {
                console.error('Erreur lors de la demande de réinitialisation du mot de passe:', error);
                return { success: false, message: error.message || 'Erreur lors de la demande de réinitialisation du mot de passe.' };
            }
        },

        async fetchUser({ commit }) {
            try {
                const user = await apiRequest('/user');
                if (user && user.firstName && user.lastName) {
                    commit('SET_USER', user);
                } else {
                    commit('CLEAR_USER');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données utilisateur:', error);
                commit('CLEAR_USER');
            }
        },

        async fetchUsers({ commit }) {
            try {
                const users = await apiRequest('/users');
                commit('SET_USERS', users);
            } catch (error) {
                console.error('Erreur lors de la récupération des utilisateurs:', error);
                throw error;
            }
        },

        async cancelReservation({ commit, state }, reservationId) {
            try {
                const userId = state.user.userId;
                const result = await apiRequest('/cancel-reservation', {
                    method: 'POST',
                    body: JSON.stringify({
                        userId: userId,
                        reservationId: reservationId
                    }),
                });

                if (result.status === 'success') {
                    // Mise à jour de l'état local des réservations
                    const updatedReservations = state.reservations.map(reservation =>
                        reservation.id === reservationId ? { ...reservation, status: 'cancelled' } : reservation
                    );
                    commit('SET_RESERVATIONS', updatedReservations);
                    return { success: true };
                } else {
                    throw new Error('Échec de la demande d\'annulation.');
                }
            } catch (error) {
                console.error('Erreur lors de l\'annulation de la réservation:', error);
                throw new Error('Échec de la demande d\'annulation.');
            }
        },

        async fetchUserReservations({ commit, state }) {
            try {
                const userId = state.user.userId;
                const reservations = await apiRequest(`/reservation/${userId}`);

                if (!reservations || reservations.length === 0) {
                    commit('SET_RESERVATIONS', []);
                } else {
                    const sortedReservations = reservations
                        .map(reservation => {
                            // Check and format the amount
                            let amountDisplay = 'À calculer';
                            try {
                                const parsedAmount = JSON.parse(reservation.amount);
                                if (parsedAmount[0] === "Gratuit" || parsedAmount[0] === "Sur devis") {
                                    amountDisplay = parsedAmount[0];
                                } else {
                                    amountDisplay = `${parsedAmount[0]}€`;
                                }
                            } catch (e) {
                                console.error('Erreur lors de la conversion du montant :', e);
                            }
                            return {
                                ...reservation,
                                amountDisplay
                            };
                        })
                        // Sort by date and time_slot in descending order
                        .sort((a, b) => {
                            const dateTimeA = new Date(`${a.date} ${a.time_slot.split(' - ')[0]}`);
                            const dateTimeB = new Date(`${b.date} ${b.time_slot.split(' - ')[0]}`);
                            return dateTimeB - dateTimeA;
                        });

                    commit('SET_RESERVATIONS', sortedReservations);
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des réservations:', error);
                commit('SET_RESERVATIONS', []);
            }
        },

        async logout({ commit }) {
            try {
                await apiRequest('/logout', { method: 'POST' });
                commit('CLEAR_USER');
            } catch (error) {
                console.error('Erreur lors de la déconnexion:', error);
            }
        },

        async updateUserProfile({ commit }, { textData, files }) {
            try {
                if (textData) {
                    await apiRequest('/update/text', {
                        method: 'PUT',
                        body: JSON.stringify(textData)
                    });
                }

                if (files) {
                    const formData = new FormData();
                    for (const [key, file] of Object.entries(files)) {
                        if (file) {
                            formData.append(key, file);
                        }
                    }
                    await apiRequestMultipart('/update/files', formData);
                }

                const user = await apiRequest('/user');
                commit('SET_USER', user);
                return user;
            } catch (error) {
                console.error('Erreur lors de la mise à jour du profil utilisateur:', error);
                throw error;
            }
        },

        async updateUser({ commit }, userData) {
            try {
                const updatedUser = await apiRequest(`/users/${userData.userId}`, {
                    method: 'PUT',
                    body: JSON.stringify(userData)
                });
                commit('UPDATE_USER', updatedUser);
            } catch (error) {
                console.error('Erreur lors de la mise à jour de l\'utilisateur:', error);
            }
        },

        async deleteUserAction({ commit }, userId) {
            try {
                await apiRequest(`/users/${userId}`, { method: 'DELETE' });
                commit('DELETE_USER', userId);
            } catch (error) {
                console.error('Erreur lors de la suppression de l\'utilisateur:', error);
            }
        },

        async setUserRole({ dispatch }, { userId, roleId }) {
            try {
                await apiRequest(`/users/${userId}/role`, {
                    method: 'PUT',
                    body: JSON.stringify({ roleId })
                });
                await dispatch('fetchUsers');
            } catch (error) {
                console.error('Erreur lors de la mise à jour du rôle de l\'utilisateur:', error);
            }
        },

    },
});
