<template>
    <div class="userManagement">
        <h2>Liste des utilisateurs</h2>
        <p class="description">
            Vous êtes sur la page de gestion des utilisateurs de l'application. Ici, vous pouvez rechercher parmi les
            utilisateurs enregistrés, modifier leurs informations personnelles, ou supprimer leurs comptes si
            nécessaire. De plus, vous avez la possibilité de gérer les rôles des utilisateurs, par exemple en attribuant
            des privilèges d'administrateur ou en les restreignant à des rôles spécifiques. Utilisez les fonctionnalités
            de cette page pour assurer une gestion efficace et sécurisée de votre base d'utilisateurs.
        </p>

        <div class="item">
            <input id="searchTerm" name="searchTerm" v-model="searchTerm" type="text"
                placeholder="Rechercher un utilisateur" class="search-input" autocomplete="off" />
        </div>

        <button @click="sendEmailToAllUsers" class="btn btn--email-all">Envoyer un e-mail à tous les
            utilisateurs</button>

        <ul class="list">
            <li v-for="user in paginatedUsers" :key="user.userId" class="item">
                <div v-if="editingUserId === user.userId" class="edit-form">
                    <label for="firstName">Prénom</label>
                    <input id="firstName" name="firstName" v-model="editableUser.firstName" type="text"
                        placeholder="Prénom" class="input" autocomplete="given-name" />

                    <label for="lastName">Nom</label>
                    <input id="lastName" name="lastName" v-model="editableUser.lastName" type="text" placeholder="Nom"
                        class="input" autocomplete="family-name" />

                    <label for="phone">Téléphone</label>
                    <input id="phone" name="phone" v-model="editableUser.phone" type="tel" placeholder="Téléphone"
                        pattern="^(\+33|0)[1-9](\d{2}){4}$"
                        title="Le numéro de téléphone doit commencer par 0 ou +33 suivi de 9 chiffres." class="input"
                        autocomplete="tel" />

                    <label for="email">Adresse Email</label>
                    <input id="email" name="email" v-model="editableUser.email" type="email" placeholder="Email"
                        class="input" autocomplete="email" />

                    <label for="street">Rue</label>
                    <input id="street" name="street" v-model="editableUser.address.street" type="text"
                        placeholder="Adresse" class="input" autocomplete="street-address" />

                    <label for="city">Ville</label>
                    <input id="city" name="city" v-model="editableUser.address.city" type="text" placeholder="Ville"
                        class="input" autocomplete="address-level2" />

                    <label for="postalCode">Code Postal</label>
                    <input id="postalCode" name="postalCode" v-model="editableUser.address.postalCode" type="text"
                        placeholder="Code Postal" class="input" autocomplete="postal-code" />

                    <label for="country">Pays</label>
                    <input id="country" name="country" v-model="editableUser.address.country" type="text"
                        placeholder="Pays" class="input" autocomplete="country" />

                    <div class="password-section">
                        <label class="new-pass">
                            <input type="checkbox" id="regenerate-password" name="regenerate-password"
                                v-model="regeneratePassword" />
                            Régénérer le mot de passe
                        </label>
                        <input v-if="regeneratePassword" type="password" id="new-password" name="new-password"
                            v-model="newPassword" placeholder="Nouveau mot de passe" class="input"
                            autocomplete="new-password" />
                    </div>

                    <button @click="saveUser(user.userId)" class="btn btn--save">Enregistrer</button>
                    <button @click="cancelEdit" class="btn btn--cancel">Annuler</button>
                </div>
                <div v-else class="details">
                    <div class="info">
                        <span class="id">Utilisateur {{ user.userId }}</span>
                        <span class="role">Rôle: {{ user.roleId === 1 ? 'Admin' : 'Utilisateur' }}</span>
                        <span class="name">{{ user.firstName }} {{ user.lastName }}</span>
                        <span class="phone">
                            <a :href="`tel:${user.phone}`">{{ user.phone }}</a>
                        </span>
                        <span class="email">
                            <a :href="`mailto:${user.email}`">{{ user.email }}</a>
                        </span>
                        <span class="address">
                            <a :href="generateMapUrl(user.address)" target="_blank">
                                {{ user.address.street || 'Adresse inconnue' }},
                                {{ user.address.city || 'Ville inconnue' }},
                                {{ user.address.postalCode || 'Code postal inconnu' }},
                                {{ user.address.country || 'Pays inconnu' }}
                            </a>
                        </span>
                    </div>
                    <div class="links">
                        <span v-if="user.registrationCertificateUrl" class="link">
                            <a :href="generateFileUrl(user.registrationCertificateUrl, user)" target="_blank">Carte
                                grise</a>
                        </span>
                        <span v-if="user.technicalControlUrl" class="link">
                            <a :href="generateFileUrl(user.technicalControlUrl, user)" target="_blank">Contrôle
                                Technique</a>
                        </span>
                        <span v-if="user.vehiclePhotoUrl" class="link">
                            Photos du Véhicule
                            <span v-for="(url, index) in splitUrls(user.vehiclePhotoUrl)" :key="index"
                                class="vehicle-photo-link">
                                <a :href="generateFileUrl(url, user)" target="_blank">Voir {{ index + 1 }}</a>
                            </span>
                        </span>
                    </div>
                    <div class="actions">
                        <button @click="editUser(user)" class="btn btn--edit">Modifier</button>
                        <button @click="deleteUser(user.userId)" class="btn btn--delete">Supprimer</button>
                        <button v-if="user.roleId !== 1" @click="setRole(user.userId, 1)" class="btn btn--admin">
                            Définir comme Admin
                        </button>
                        <button v-if="user.roleId === 1" @click="setRole(user.userId, 2)" class="btn btn--remove-admin">
                            Retirer Admin
                        </button>
                    </div>
                </div>
            </li>
        </ul>

        <div class="pagination">
            <button @click="prevPage" :disabled="currentPage === 1">
                <span class="desktop">Précédent</span>
                <span class="mobile"><ion-icon name="chevron-back"></ion-icon></span>
            </button>
            <span>Page {{ currentPage }} sur {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">
                <span class="desktop">Suivant</span>
                <span class="mobile"><ion-icon name="chevron-forward"></ion-icon></span>
            </button>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'UserManagement',
    data() {
        return {
            editingUserId: null,
            editableUser: {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                address: {
                    street: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            },
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5,
            regeneratePassword: false,
            newPassword: ''
        };
    },
    computed: {
        ...mapState(['users']),
        filteredUsers() {
            const searchTerm = (this.searchTerm || '').toLowerCase();
            return this.users.filter(user => {
                const firstName = user.firstName?.toLowerCase() || '';
                const lastName = user.lastName?.toLowerCase() || '';
                const email = user.email?.toLowerCase() || '';
                const phone = user.phone?.toLowerCase() || '';
                const street = user.address?.street?.toLowerCase() || '';
                const city = user.address?.city?.toLowerCase() || '';
                const postalCode = user.address?.postalCode?.toLowerCase() || '';
                const country = user.address?.country?.toLowerCase() || '';

                const hasRegistrationCertificate = user.registrationCertificateUrl ? 'carte grise' : '';
                const hasTechnicalControl = user.technicalControlUrl ? 'contrôle technique' : '';
                const hasVehiclePhotos = user.vehiclePhotoUrl ? 'photos du véhicule' : '';

                const role = user.roleId === 1 ? 'admin' : 'utilisateur';

                const userId = user.userId?.toString() || '';

                return firstName.includes(searchTerm) ||
                    lastName.includes(searchTerm) ||
                    email.includes(searchTerm) ||
                    phone.includes(searchTerm) ||
                    street.includes(searchTerm) ||
                    city.includes(searchTerm) ||
                    postalCode.includes(searchTerm) ||
                    country.includes(searchTerm) ||
                    hasRegistrationCertificate.includes(searchTerm) ||
                    hasTechnicalControl.includes(searchTerm) ||
                    hasVehiclePhotos.includes(searchTerm) ||
                    role.includes(searchTerm) ||
                    userId.includes(searchTerm);
            });
        },
        paginatedUsers() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.filteredUsers.slice(start, end);
        },
        totalPages() {
            return Math.ceil(this.filteredUsers.length / this.itemsPerPage);
        }
    },
    created() {
        this.fetchUsers();
    },
    methods: {
        ...mapActions(['fetchUsers', 'deleteUserAction', 'setUserRole', 'updateUser']),

        editUser(user) {
            this.editingUserId = user.userId;
            this.editableUser = { ...user };
            this.regeneratePassword = false;
            this.newPassword = '';
        },

        async saveUser(userId) {
            const payload = { id: userId, ...this.editableUser };
            if (this.regeneratePassword) {
                payload.regeneratePassword = true;
                payload.newPassword = this.newPassword;
            }

            try {
                await this.updateUser(payload);
                alert('Utilisateur mis à jour avec succès.');
                this.cancelEdit();
                this.fetchUsers();
            } catch (error) {
                console.error('Erreur lors de la mise à jour de l\'utilisateur:', error);
            }
        },

        cancelEdit() {
            this.editingUserId = null;
            this.editableUser = {
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                address: {
                    street: '',
                    city: '',
                    postalCode: '',
                    country: ''
                }
            };
            this.regeneratePassword = false;
            this.newPassword = '';
        },

        async deleteUser(userId) {
            if (confirm('Êtes-vous sûr de vouloir supprimer cet utilisateur ?')) {
                try {
                    await this.deleteUserAction(userId);
                    alert('Utilisateur supprimé avec succès.');
                    this.fetchUsers();
                } catch (error) {
                    console.error('Erreur lors de la suppression de l\'utilisateur:', error);
                }
            }
        },

        async setRole(userId, roleId) {
            try {
                await this.setUserRole({ userId, roleId });
                alert('Rôle mis à jour avec succès.');
                this.fetchUsers();
            } catch (error) {
                console.error('Erreur lors de la mise à jour du rôle de l\'utilisateur:', error);
            }
        },

        splitUrls(urls) {
            return urls.split(/[,; ]+/).filter(url => url.includes('photo_du_vehicule'));
        },

        generateFileUrl(filePath, user) {
            const baseUrl = 'https://api.splashauto-bm.fr/';

            const userFolder = `${user.userId}_${user.firstName}_${user.lastName}`.replace(/\s+/g, '%20');

            if (filePath.includes('photo_du_vehicule')) {
                return `${baseUrl}uploads/${userFolder}/photo_du_vehicule/${filePath.replace(/^.*photo_du_vehicule\//, '')}`;
            }

            let cleanedFilePath = filePath.replace(/^.*api\.splashauto-bm\.fr\//, '');
            return baseUrl + cleanedFilePath.replace(/^\/+/, '');
        },

        generateMapUrl(address) {
            if (!address) {
                return '';
            }
            const { street, city, postalCode, country } = address;
            const formattedAddress = `${street || ''}, ${city || ''}, ${postalCode || ''}, ${country || ''}`.trim().replace(/\s+/g, ' ');
            return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(formattedAddress)}`;
        },

        async sendEmailToAllUsers() {
            try {
                await this.fetchUsers();

                const emails = this.users.filter(user => user.email).map(user => user.email).join(',');

                if (emails.length > 0) {
                    const mailtoLink = `mailto:${emails}`;
                    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

                    if (isMobile) {
                        window.location.href = mailtoLink;
                    } else {
                        window.location.href = mailtoLink;
                        setTimeout(() => {
                            if (window.location.href === window.location.origin + window.location.pathname) {
                                this.showEmailCopyDialog(emails);
                            }
                        }, 1000);
                    }
                } else {
                    alert('Aucun utilisateur trouvé.');
                }
            } catch (error) {
                console.error('Erreur lors de l\'envoi des e-mails:', error);
                alert('Une erreur est survenue lors de l\'envoi des e-mails');
            }
        },

        showEmailCopyDialog(emails) {
            const overlay = document.createElement('div');
            overlay.className = 'email-copy-dialog-overlay';

            const dialog = document.createElement('div');
            dialog.className = 'email-copy-dialog';

            const message = document.createElement('p');
            message.innerText = 'Le client de messagerie ne s\'est pas ouvert. Copiez les adresses e-mails ci-dessous :';

            // Explicitement spécifier UTF-8 pour le contenu du textarea
            const emailTextArea = document.createElement('textarea');
            emailTextArea.setAttribute('charset', 'utf-8');
            emailTextArea.value = emails;
            emailTextArea.readOnly = true;

            const copyButton = document.createElement('button');
            copyButton.innerText = 'Copier les e-mails';
            copyButton.onclick = () => {
                emailTextArea.select();
                document.execCommand('copy');
                alert('Adresses emails copiées dans le presse-papiers.');
                document.body.removeChild(overlay);
            };

            const closeButton = document.createElement('button');
            closeButton.innerText = 'Fermer';
            closeButton.className = 'btn--close';
            closeButton.onclick = () => {
                document.body.removeChild(overlay);
            };

            dialog.appendChild(message);
            dialog.appendChild(emailTextArea);
            dialog.appendChild(copyButton);
            dialog.appendChild(closeButton);

            overlay.appendChild(dialog);
            document.body.appendChild(overlay);
        },

        prevPage() {
            if (this.currentPage > 1) { this.currentPage--; }
        },
        nextPage() { if (this.currentPage < this.totalPages) { this.currentPage++; } }
    }
}
</script>

<style>
.userManagement {
    padding: 2em;
}

.description {
    text-transform: uppercase;
    font-size: 1.4em;
}

.userManagement .list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.userManagement .item {
    padding: 1em;
    border-bottom: 1px solid var(--grey-color);
    display: flex;
    flex-direction: column;
}

.userManagement .details {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.userManagement .info {
    font-weight: bold;
    color: var(--white-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.userManagement h2,
.info,
.links {
    text-transform: uppercase;
}

.email {
    text-transform: lowercase;
}

.userManagement .links {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    font-weight: bold;
    gap: 0.5em;
    margin-top: 0.5em;
}

.userManagement .link {
    display: flex;
    align-items: center;
    gap: 0.3em;
}

.userManagement .vehicle-photo-link {
    margin-left: 0.5em;
}

.userManagement .actions {
    display: flex;
    gap: 0.5em;
    margin-top: 0.5em;
}

.userManagement .btn {
    border: none;
    cursor: pointer;
    width: 100%;
    padding: 0.5rem 0 0.5rem 0.5rem;
    border: 2px solid var(--white-color);
}

.userManagement .btn--edit,
.userManagement .btn--save,
.userManagement .btn--admin {
    background-color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
    color: var(--white-color);
}

.userManagement .btn--delete,
.userManagement .btn--cancel {
    background-color: var(--black-color);
    border: 2px solid var(--white-color);
    color: var(--white-color);
}

.userManagement .btn--remove-admin {
    background-color: var(--yellow-color-darker);
    border: 2px solid var(--yellow-color-darker);
    color: var(--white-color);
}

.userManagement .btn--edit:hover,
.userManagement .btn--save:hover,
.userManagement .btn--admin:hover {
    background-color: var(--white-color);
    border: 2px solid var(--yellow-color);
    color: var(--yellow-color);
}

.userManagement .btn--delete:hover,
.userManagement .btn--cancel:hover {
    background-color: var(--white-color);
    border: 2px solid var(--black-color);
    color: var(--black-color);
}

.userManagement .btn--remove-admin:hover {
    background-color: var(--white-color);
    border: 2px solid var(--yellow-color-darker);
    color: var(--yellow-color-darker);
}

.userManagement .edit-form {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
}

.userManagement label {
    font-weight: bold;
    text-transform: uppercase;
}

.userManagement input[type="text"],
.userManagement input[type="email"],
.userManagement input[type="tel"],
.userManagement input[type="password"] {
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
    border-radius: 0px;
    transition: border-color 0.3s ease;
}

.userManagement input[type="text"]:focus,
.userManagement input[type="email"]:focus,
.userManagement input[type="tel"]:focus,
.userManagement input[type="password"]:focus {
    border-color: var(--yellow-color);
    outline: none;
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.new-pass {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    gap: .225rem;
}

.password-section {
    display: contents;
}

.userManagement .pagination {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 1em;
    gap: 1.5em;
    align-items: center;
    text-transform: uppercase;
}

.userManagement .pagination button {
    padding: 0.5em 1em;
    background-color: var(--white-color);
    color: var(--black-color);
    cursor: pointer;
}

.userManagement .pagination button:hover {
    background-color: var(--black-color);
    color: var(--white-color);
    border: 2px solid var(--white-color);
}

.userManagement .pagination button:disabled {
    background-color: var(--grey-color);
    color: var(--white-color);
    cursor: not-allowed;
}

.userManagement .desktop {
    display: block;
}

.userManagement .mobile {
    display: none;
}


.email-copy-dialog-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.email-copy-dialog {
    background-color: var(--white-color);
    padding: 4rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    max-width: 90%;
    width: 19rem;
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto', sans-serif;
}

.email-copy-dialog p {
    margin-bottom: 10px;
    font-size: 1em;
    color: var(--black-color);
    text-transform: uppercase;
}

.email-copy-dialog textarea {
    width: 100%;
    height: 100px;
    padding: 10px;
    border: 1px solid var(--grey-color);
    margin-bottom: 10px;
    overflow-y: auto;
    resize: none;
}

.email-copy-dialog button {
    padding: 0.5rem 0.75rem;
    margin-top: 10px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
    width: auto;
    background-color: var(--yellow-color);
    color: var(--white-color);
    border: 2px solid var(--yellow-color);
}

.email-copy-dialog button:hover {
    background-color: var(--white-color);
    border: 2px solid var(--yellow-color);
    color: var(--yellow-color);
}

.email-copy-dialog .btn--close {
    background-color: var(--black-color);
    border: 2px solid var(--black-color);
}

.email-copy-dialog .btn--close:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}

@media (max-width: 1024px) {
    .description {
        text-transform: uppercase;
        font-size: initial;
    }

    .userManagement {
        padding: 1.5em;
    }

    .userManagement h2 {
        font-size: 1.4em;
    }

    .userManagement .info,
    .userManagement .links,
    .userManagement .actions,
    .userManagement .pagination,
    .userManagement label {
        font-size: medium;
    }

}

@media (max-width: 768px) {
    .userManagement {
        padding: 1em;
    }

    .userManagement h2 {
        font-size: 1.2em;
    }

    .description {
        text-transform: uppercase;
        font-size: initial;
    }

    .userManagement .info,
    .userManagement .links,
    .userManagement .btn,
    .userManagement .pagination,
    .userManagement label {
        font-size: smaller;
    }

    .userManagement .info {
        align-items: flex-start;
    }

    .userManagement .link {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .userManagement .vehicle-photo-link {
        margin-left: 0;
    }

    .userManagement .actions {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin-top: 0.5em;
    }

    .userManagement .btn {
        padding: 0.5rem 0.75rem;
    }

    .userManagement .desktop {
        display: none;
    }

    .userManagement .mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .userManagement .pagination {
        justify-content: center;
        gap: 0.75rem;
        padding: .5em;
    }

    .userManagement .pagination button {
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .userManagement .pagination ion-icon {
        margin: 0;
    }
}

@media (max-width: 480px) {
    .description {
        font-size: smaller;
    }

    .userManagement {
        padding: 0.5em;
    }

    .userManagement h2 {
        font-size: 1em;
    }

    .tabs {
        gap: .5rem;
    }

    .userManagement .btn {
        padding: 0.5rem 0.5rem;
    }

    .userManagement .pagination {
        gap: 1rem;
    }

    .email-copy-dialog {
        width: 9rem;
        height: 19rem;
    }
}
</style>