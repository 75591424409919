<template>
    <div id="appointment">
        <section id="appointment-hero">
            <h1>Réservez nettoyage, polissage, ou remplacement de pare-brise.</h1>
            <button @click="scrollToForm">Prendre Rendez-vous</button>
        </section>

        <section id="appointment-services">
            <h2>Prendre Rendez-vous</h2>
        </section>
        <div class="effect-container"></div>

        <div class="appointment-section-container">
            <BookingProcess />
        </div>

        <div class="more-info-appointment">
            <p>
                <a href="/services-infos" target="_blank">
                    Récapitulatif des services
                </a>
            </p>
            <p><strong>Avant le nettoyage de votre véhicule</strong></p>

            <p>Merci de vider entièrement votre véhicule de tous objets personnels et documents importants. Si ce n'est
                pas le cas, un
                supplément de 10 € sera appliqué.</p>

            <p><strong>Avant le polissage de votre véhicule</strong></p>

            <p>La carrosserie doit être propre à l'arrivée. Sinon, un supplément de 35 €
                sera facturé pour un lavage à la vapeur, car la décontamination nécessite une surface propre.</p>

            <p><strong>Informations complémentaires</strong></p>

            <p>Nous déclinons toute responsabilité en cas de perte ou de dommage des
                effets personnels laissés dans le véhicule.</p>
        </div>
    </div>
</template>


<script>
import BookingProcess from './BookingProcess.vue';

export default {
    name: 'AppointmentPage',
    components: {
        BookingProcess
    },
    data() {
        return {
        };
    },
    methods: {
        scrollToForm() {
            document.querySelector('#appointment-services').scrollIntoView({ behavior: 'smooth' });
        },
    },
};
</script>

<style>
/* Hero Section */
#appointment-hero {
    position: relative;
    width: 100%;
    padding: 18rem 8rem;
    color: var(--white-color);
    font-size: larger;
    overflow: hidden;
    z-index: 1;
}

#appointment-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-mehmet-cinar-307954698-13600401.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

#appointment-hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, var(--black-color-50) 25%, var(--yellow-color) 100%);
    z-index: 2;
}

#appointment-hero h1 {
    max-width: 25rem;
    text-transform: uppercase;
    position: relative;
    z-index: 4;
    font-size: 2.5rem;
}

#appointment-hero button {
    position: relative;
    z-index: 4;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

/* Services Section */
#appointment-services {
    text-align: center;
    position: relative;
    top: -0.125rem;
}

#appointment-services h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--yellow-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 3;
}

/* Appointment Section Container */
.appointment-section-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white-color);
    color: var(--black-color);
    text-transform: uppercase;
    height: fit-content;
    margin-top: -1.25rem;
    padding: 3rem 2rem;
    z-index: 1;
}

.appointment-section-container::before {
    content: "";
    position: absolute;
    bottom: -1.125rem;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--white-color);
    z-index: 1;
}

.appointment-section-container::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--white-color);
    transform: rotate(45deg);
    z-index: 2;
}

/* Navigation Buttons */
.form-navigation {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    width: 100%;
}

.form-navigation button {
    width: 100%;
}

.appointment-section-container button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

.form-navigation .next-button {
    background-color: var(--yellow-color);
    color: var(--white-color);
}

.form-navigation button:hover {
    border-style: solid;
    border-color: var(--black-color);
}

.form-navigation .next-button:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
}

/* Info Section */
.more-info-appointment {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    height: fit-content;
    color: var(--white-color);
    font-size: larger;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 0;
    padding: 2rem;
}

.more-info-appointment::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-tdcat-70912.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: -1;
}

.more-info-appointment::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black-color);
    opacity: 0.75;
    z-index: -1;
}

.more-info-appointment p {
    font-size: 0.91rem;
    font-weight: bold;
    z-index: 3;
}

.appointment-section-container p {
    font-size: 0.91rem;
}

.more-info-appointment p strong,
.appointment-section-container h2 {
    font-size: 2.5rem;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    #appointment-hero {
        padding: 15rem 4rem;
    }

    #appointment-hero h1 {
        font-size: 2rem;
    }

    #appointment-hero button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    #appointment-services h2 {
        font-size: 2rem;
    }

    .progress-bar div {
        font-size: small;
    }

    .appointment-section-container .sub-title-appointment {
        font-size: 1rem;
    }

    .appointment-section-container button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .appointment-section-container p {
        font-size: 0.75rem;
    }

    .options {
        font-size: 0.75rem;
    }

    .more-info-appointment p {
        font-size: 0.75rem;
    }

    .more-info-appointment p strong,
    .appointment-section-container h2 {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    #appointment-hero {
        padding: 10rem 2rem;
    }

    #appointment-hero h1 {
        font-size: 2rem;
        max-width: 15rem;
    }

    #appointment-hero button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    #appointment-services h2 {
        font-size: 1.4rem;
    }

    .progress-bar div {
        font-size: large;
    }

    .progress-pc {
        display: none;
    }

    .progress-mobile {
        display: block;
    }

    .form-steps-container {
        margin: 0.5rem auto;
        padding: 0;
        box-shadow: none;
        width: 100%;
    }

    .appointment-section-container .sub-title-appointment {
        font-size: 0.875rem;
    }

    .appointment-section-container button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
        width: 100%;
    }

    .options {
        font-size: small;
    }

    .form-navigation {
        flex-direction: column;
    }

    .appointment-section-container p {
        font-size: 0.75rem;
    }

    .more-info-appointment p strong,
    .appointment-section-container h2 {
        font-size: 1.4rem;
    }
}

@media (max-width: 480px) {
    #appointment-hero {
        padding: 8rem 1rem;
    }

    #appointment-hero h1 {
        font-size: 1.5rem;
    }

    #appointment-hero button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    #appointment-services h2 {
        font-size: 1.2rem;
    }

    .appointment-section-container {
        padding: 3rem 1rem;
    }

    .progress-bar div {
        font-size: medium;
    }

    .appointment-section-container .sub-title-appointment {
        font-size: 0.75rem;
    }

    .appointment-section-container button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    .options {
        font-size: 0.525rem;
    }

    .appointment-section-container p {
        font-size: 0.75rem;
    }

    .more-info-appointment p strong,
    .appointment-section-container h2 {
        font-size: 1.2rem;
    }
}
</style>
