<template>
    <div class="booking">
        <div class="progress-bar">
            <div class="progress-bar-inner" :style="{ width: progressBarWidth }"></div>
            <div class="progress-step" :class="{ active: currentStep >= 1 }">
                <span class="progress-mobile">1</span>
                <span class="progress-pc">Service</span>
            </div>
            <div class="progress-step" :class="{ active: currentStep >= 2 }">
                <span class="progress-mobile">2</span>
                <span class="progress-pc">Forfait/Formule</span>
            </div>
            <div class="progress-step"
                :class="{ active: currentStep >= 3, 'skip-extras': bookingData.service === 'Extra' }">
                <span class="progress-mobile">3</span>
                <span class="progress-pc">Extras</span>
            </div>
            <div class="progress-step" :class="{ active: currentStep >= 4 }">
                <span class="progress-mobile">4</span>
                <span class="progress-pc">Informations</span>
            </div>
            <div class="progress-step" :class="{ active: currentStep >= 5 }">
                <span class="progress-mobile">5</span>
                <span class="progress-pc">Date et Heure</span>
            </div>
            <div class="progress-step" :class="{ active: currentStep >= 6 }">
                <span class="progress-mobile">6</span>
                <span class="progress-pc">Résumé</span>
            </div>
        </div>

        <component :is="currentStepComponent" :bookingData="bookingData" @update:bookingData="updateBookingData"
            @next="nextStep" @prev="prevStep" />
    </div>
</template>

<script>
import Step1ChooseService from './steps/Step1ChooseService.vue';
import Step2SelectPackage from './steps/Step2SelectPackage.vue';
import Step3ChooseExtras from './steps/Step3ChooseExtras.vue';
import Step4PersonalInfo from './steps/Step4PersonalInfo.vue';
import Step5SelectDateTime from './steps/Step5SelectDateTime.vue';
import Step6OrderSummary from './steps/Step6OrderSummary.vue';

export default {
    components: {
        Step1ChooseService,
        Step2SelectPackage,
        Step3ChooseExtras,
        Step4PersonalInfo,
        Step5SelectDateTime,
        Step6OrderSummary
    },
    data() {
        return {
            currentStep: 1,
            totalSteps: 6,
            bookingData: {
                service: '',
                package: '',
                extras: [],
                personalInfo: {},
                date: '',
                timeSlot: ''
            }
        };
    },
    computed: {
        currentStepComponent() {
            if (this.bookingData.service === 'Extra' && this.currentStep === 3) {
                return 'Step4PersonalInfo'; // Sauter l'étape 3 si le service "Extra" est sélectionné
            }
            switch (this.currentStep) {
                case 1: return 'Step1ChooseService';
                case 2: return 'Step2SelectPackage';
                case 3: return 'Step3ChooseExtras';
                case 4: return 'Step4PersonalInfo';
                case 5: return 'Step5SelectDateTime';
                case 6: return 'Step6OrderSummary';
                default: return 'Step1ChooseService';
            }
        },
        progressBarWidth() {
            // Calculer la largeur de la barre en fonction des étapes visibles
            const isExtraSelected = this.bookingData.service === 'Extra';
            const currentProgress = isExtraSelected && this.currentStep > 2 ? this.currentStep - 1 : this.currentStep;
            const totalVisibleSteps = isExtraSelected ? this.totalSteps - 1 : this.totalSteps;

            return `${(currentProgress / totalVisibleSteps) * 100}%`;
        }
    },
    watch: {
        // Réinitialiser la sélection de package et d'extras lorsqu'on change de service
        'bookingData.service': function () {
            this.bookingData.package = '';
            this.bookingData.extras = [];
        }
    },
    methods: {
        nextStep() {
            if (this.currentStep < this.totalSteps) {
                this.currentStep += 1;
                // Sauter l'étape 3 si le service "Extra" est sélectionné
                if (this.bookingData.service === 'Extra' && this.currentStep === 3) {
                    this.currentStep += 1;
                }
            }
        },
        prevStep() {
            if (this.currentStep > 1) {
                this.currentStep -= 1;
            }
        },
        updateBookingData(data) {
            this.bookingData = { ...this.bookingData, ...data };
        }
    }
};
</script>

<style scoped>
.booking {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100%;
    background-color: var(--white-color);
    color: var(--black-color);
    text-transform: uppercase;
}

.progress-bar {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 2rem;
    margin-bottom: 20px;
    background-color: transparent;
    color: var(--white-color);
}

.progress-bar-inner {
    content: "";
    position: absolute;
    bottom: -6px;
    height: 3px;
    background-color: var(--yellow-color);
    transition: width 0.3s ease;
}

.progress-step {
    position: relative;
    flex: 1;
    padding: 5px 8px;
    color: var(--black-color);
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    transition: color 0.3s ease, font-weight 0.3s ease;
}

.progress-step.active {
    font-weight: bold;
    color: var(--yellow-color);
}

.progress-step.skip-extras {
    display: none;
}

.progress-mobile {
    display: none;
}

.form-navigation {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: .5rem;
    width: 100%;
}

@media (max-width: 768px) {
    .progress-bar div {
        font-size: large;
    }

    .progress-pc {
        display: none;
    }

    .progress-mobile {
        display: block;
    }
}
</style>