<template>
    <div class="legalNotice">
        <h2>Mentions Légales</h2>
        <p>Conformément aux exigences de la loi pour la confiance dans l'économie numérique, nous vous informons des
            éléments suivants :</p>
        <div>
            <h3>1. Informations sur l'éditeur du site</h3>
            <p><strong>Nom de l'entreprise :</strong> Splash Auto BM</p>
            <p><strong>Adresse du siège social :</strong> <a
                    href="https://maps.app.goo.gl/zztuoHKDHd4phVQy5"
                    target="_blank">620 Rue de l'Europe, 60400 Noyon, France</a></p>
            <p><strong>Numéro de téléphone :</strong> <a href="tel:+33952719683">09 52 71 96 83</a></p>
            <p><strong>Adresse e-mail :</strong> <a href="mailto:splashautobm60@gmail.com">splashautobm60@gmail.com</a>
            </p>
            <p><strong>Directeur de la publication :</strong> Meihdi Ben Seddik</p>
        </div>
        <div>
            <h3>2. Hébergeur du site</h3>
            <p><strong>Nom de l'hébergeur :</strong> O2switch</p>
            <p><strong>Adresse de l'hébergeur :</strong> <a
                    href="https://www.google.com/maps/place/222-224+Boulevard+Gustave+Flaubert,+63000+Clermont-Ferrand,+France"
                    target="_blank">222-224 Boulevard Gustave Flaubert, 63000 Clermont-Ferrand, France</a></p>
            <p><strong>Numéro de téléphone :</strong> <a href="tel:+33444446040">+33 4 44 44 60 40</a></p>
            <p><strong>Adresse e-mail :</strong> <a href="mailto:contact@o2switch.fr">contact@o2switch.fr</a></p>
        </div>
        <div>
            <h3>3. Propriété intellectuelle</h3>
            <p>Le contenu de ce site, y compris les textes, images, graphismes, logos, et autres éléments, est la
                propriété de Splash Auto BM ou de tiers ayant accordé des droits d'utilisation. Toute reproduction,
                représentation, modification, ou diffusion de tout ou partie de ce contenu sans autorisation préalable
                est
                interdite.</p>
        </div>
        <div>
            <h3>4. Responsabilité</h3>
            <p>Splash Auto BM décline toute responsabilité en cas de dommages directs ou indirects résultant de
                l'utilisation de ce site ou de l'impossibilité d'y accéder. Nous ne garantissons pas que le site sera
                exempt d'erreurs ou de virus.</p>
        </div>
        <div>
            <h3>5. Données personnelles</h3>
            <p>Pour des informations sur la collecte, le traitement et la protection de vos données personnelles,
                veuillez consulter notre <router-link to="/privacy-policy">Politique de Confidentialité</router-link>.
            </p>
        </div>
        <div>
            <h3>6. Liens externes</h3>
            <p>Ce site peut contenir des liens vers d'autres sites web. Nous ne sommes pas responsables du contenu de
                ces sites externes et ne garantissons pas leur disponibilité.</p>
        </div>
        <div>
            <h3>7. Modifications des mentions légales</h3>
            <p>Splash Auto BM se réserve le droit de modifier ces mentions légales à tout moment. Les modifications
                seront publiées sur cette page avec une date de révision. Nous vous encourageons à consulter
                régulièrement cette page pour rester informé des mises à jour.</p>
        </div>
        <div>
            <h3>8. Contact</h3>
            <p>Pour toute question concernant ces mentions légales, veuillez nous contacter à <a
                    href="mailto:splashautobm60@gmail.com">splashautobm60@gmail.com</a> ou
                à <a href="https://maps.app.goo.gl/zztuoHKDHd4phVQy5"
                    target="_blank">620 Rue de l'Europe, 60400 Noyon, France</a>.</p>
        </div>
        <div>
            <h3>9. Crédits</h3>
            <p><strong>Graphisme :</strong> <a href="https://mateneo.carrd.co/" target="_blank">Nedjma Sella</a></p>
            <p><strong>Développement Web :</strong> <a href="https://www.linkedin.com/in/djason-chery-3b87702b4/"
                    target="_blank">Djason CHERY</a></p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LegalNotice',
}
</script>

<style>
.legalNotice {
    padding: 2em;
    text-transform: uppercase;
    font-size: .91rem;
}

.legalNotice ul li {
    list-style-type: square;
}

.legalNotice h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--yellow-color);
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

@media (max-width: 1024px) {
    .legalNotice h2 {
        font-size: 2rem;
    }

    .legalNotice li {
        font-size: 0.875rem;
    }

    .legalNotice p {
        font-size: 0.75rem;
    }
}

@media (max-width: 768px) {
    .legalNotice h2 {
        font-size: 1.4rem;
    }

    .legalNotice li {
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .legalNotice h2 {
        font-size: 1.2rem;
    }

    .legalNotice li {
        font-size: 0.875rem;
    }
}
</style>
