<template>
    <div class="map-page">
        <section id="map-hero">
            <h1>À Splash Auto BM, nous vous accueillons avec le sourire.</h1>
            <button @click="scrollToMap">Plan et Horaires</button>
        </section>

        <section id="map-services">
            <h2>Où et Quand Nous Trouver</h2>
        </section>
        <div class="effect-container"></div>

        <div class="map-section-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2587.3144354129918!2d3.025854076897839!3d49.572957450155016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e87336a6f7e5cd%3A0xd1641c2d2098b848!2sSPLASH%20AUTO%20BM!5e0!3m2!1sfr!2sfr!4v1728602565430!5m2!1sfr!2sfr!2sus&controls=0"
                width="100%" height="500" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade">
            </iframe>
            <div class="text-info">
                <div class="text-container">
                    <div class="map-title">
                        <h2 class="yellow">Ouvert,</h2>
                        <h2 class="yellow">du lundi au samedi,</h2>
                        <h2 class="sub-title-bar yellow">éclat garanti.</h2>
                    </div>
                    <a href="https://maps.app.goo.gl/zztuoHKDHd4phVQy5" target="_blank" class="contact-link-2">
                        <span class="text">
                            <ion-icon name="location"></ion-icon> 620 Rue de l'Europe 60400 Noyon
                        </span>
                    </a>
                    <a href="mailto:splashautobm60@gmail.com" class="contact-link-2">
                        <span class="text"><ion-icon name="mail"></ion-icon> splashautobm60@gmail.com</span>
                    </a>
                    <a href="tel:0952719683" class="contact-link">
                        <span class="text">
                            <span class="text-black">
                                <span class="yellow">Accueil : 09 52 71 96 83</span>
                            </span>
                        </span>
                    </a>
                    <a href="tel:0649579466" class="contact-link">
                        <span class="text">
                            <span class="text-black">
                                <span class="yellow">Commercial : 06 49 57 94 66</span>
                            </span>
                        </span>
                    </a>
                    <router-link class="button-container" to="/appointment">
                        <button class="button-appointment">Prendre RDV</button>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="hours-container">
            <h2>Horaires</h2>
            <div class="hours">
                <div class="day">
                    <span class="week">Lundi</span>
                    <span>09:00 - 12:30, 13:30 - 18:30</span>
                </div>
                <div class="day">
                    <span class="week">Mardi</span>
                    <span>09:00 - 12:30, 13:30 - 18:30</span>
                </div>
                <div class="day">
                    <span class="week">Mercredi</span>
                    <span>09:00 - 12:30, 13:30 - 18:30</span>
                </div>
                <div class="day">
                    <span class="week">Jeudi</span>
                    <span>09:00 - 12:30, 13:30 - 18:30</span>
                </div>
                <div class="day">
                    <span class="week">Vendredi</span>
                    <span>09:00 - 13:00, 15:00 - 19:30</span>
                </div>
                <div class="day">
                    <span class="week">Samedi</span>
                    <span>09:00 - 12:30, 13:30 - 18:30</span>
                </div>
                <div class="day">
                    <span class="yellow week">Dimanche</span>
                    <span class="yellow">Fermé</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MapHoursPage',
    methods: {
        scrollToMap() {
            const element = document.querySelector('#map-services');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
};
</script>

<style>
/* Hero Section */
#map-hero {
    position: relative;
    width: 100%;
    padding: 18rem 8rem;
    color: var(--white-color);
    font-size: larger;
    overflow: hidden;
    z-index: 1;
}

#map-hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-antonio-quagliata-63388-227433.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

#map-hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, var(--black-color-50) 25%, var(--yellow-color) 100%);
    z-index: 2;
}

.map-hero-content {
    position: relative;
    z-index: 3;
    text-align: center;
}

#map-hero h1 {
    max-width: 25rem;
    text-transform: uppercase;
    position: relative;
    z-index: 4;
    font-size: 2.5rem;
}

#map-hero button {
    position: relative;
    z-index: 4;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

#map-services {
    text-align: center;
    position: relative;
    top: -0.125rem;
}

#map-services h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--yellow-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 3;
}

/* Map Container */
.map-section-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1.25rem;
    height: 35rem;
    gap: 3rem;
    background-color: var(--white-color);
    z-index: 1;
}

.map-section-container::before {
    content: "";
    position: absolute;
    bottom: -1.125rem;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--white-color);
    z-index: 2;
}

.map-section-container::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--white-color);
    transform: rotate(45deg);
    z-index: -1;
}

.map-section-container iframe {
    height: 28rem;
    border: 0;
    box-sizing: border-box;
}

.text-info {
    height: 28rem;
}

.map-section-container iframe,
.text-info {
    width: 45%;
}

.text-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.map-title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    color: var(--black-color);
    font-size: x-large;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

.map-title h2 {
    margin-bottom: .5rem;
    margin-top: 0;
}

.sub-title-bar {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

.text-container .contact-link {
    color: var(--black-color);
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
}

.text-container .contact-link-2 {
    color: var(--black-color);
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    width: fit-content;
    text-transform: uppercase;
}

.text-container .contact-link:hover {
    text-decoration-color: var(--yellow-color);
}

.text-container .text-yellow {
    font-size: xx-large;
}

.text-yellow {
    color: var(--yellow-color);
}

.yellow {
    color: var(--yellow-color);
}

.text-container .text-black {
    font-size: xx-large;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    align-self: flex-end;
    width: fit-content;
}

.map-section-container .button-appointment {
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
}

.map-section-container .button-appointment:hover,
.map-section-container .button-container:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
    text-decoration: none;
}

/* Hours Section */
.hours-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 35rem;
    color: var(--white-color);
    font-size: larger;
    text-transform: uppercase;
    overflow: hidden;
    z-index: 0;
}

.hours-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-matej-117839-1034426.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

.hours-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black-color);
    opacity: 0.75;
    z-index: 2;
}

.hours-container h2 {
    position: relative;
    z-index: 3;
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.hours {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 3;
}

.day {
    display: flex;
    justify-content: space-between;
    width: 50%;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: var(--white-color);
}

.hours-container .week {
    font-weight: bold;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    #map-hero {
        padding: 15rem 4rem;
    }

    #map-hero h1 {
        font-size: 2rem;
    }

    #map-hero button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    #map-services h2 {
        font-size: 2rem;
    }

    .text-container {
        justify-content: space-evenly;
    }

    .map-title h2 {
        font-size: x-large;
    }

    .map-section-container {
        gap: 2rem;
    }

    .map-section-container iframe,
    .text-info {
        height: 25rem;
        width: 50%;
    }

    .text-info {
        width: 40%;
    }

    .text-container .text-yellow,
    .text-container .text-black,
    .text-info {
        font-size: small;
    }

    .button-container {
        align-self: flex-start;
    }

    .map-section-container .button-appointment {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .hours-container h2 {
        font-size: x-large;
    }

    .day {
        font-size: small;
    }
}

@media (max-width: 768px) {
    #map-hero {
        padding: 10rem 2rem;
    }


    #map-hero h1 {
        font-size: 2rem;
        max-width: 15rem;
    }

    #map-hero button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    #map-services h2 {
        font-size: 1.4rem;
    }

    .map-section-container {
        flex-direction: column;
        height: auto;
        padding-top: 4rem;
        gap: .5rem;
    }

    .map-section-container iframe,
    .text-info {
        height: 25rem;
        width: 90%;
    }

    .map-title h2 {
        font-size: larger;
    }

    .text-container .text-yellow,
    .text-container .text-black,
    .text-info {
        font-size: medium;
    }

    .map-section-container .button-appointment {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
        width: 100%;
    }

    .button-container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        width: 100%;
    }

    .hours-container h2 {
        font-size: x-large;
    }
}

@media (max-width: 480px) {
    #map-hero {
        padding: 8rem 1rem;
    }

    #map-hero h1 {
        font-size: 1.5rem;
    }

    #map-hero button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    #map-services h2 {
        font-size: 1.5rem;
    }

    .map-title h2 {
        font-size: smaller;
    }

    .text-container .text-yellow,
    .text-container .text-black,
    .text-info {
        font-size: small;
    }

    .hours-container h2 {
        font-size: smaller;
    }

    .day {
        font-size: .7rem;
        width: 60%;
    }
}
</style>
