<template>
    <div class="package-form">
        <h2 class="text-yellow">Choix du Forfait/Formule</h2>
        <div v-if="bookingData.service === 'Nettoyage'">
            <h3>Forfaits de Nettoyage</h3>
            <div class="package-options">
                <label v-for="(pkg, index) in packages.nettoyage" :key="index" :for="'nettoyage-' + index">
                    <input type="radio" :id="'nettoyage-' + index" :value="pkg.value" v-model="selectedPackage"
                        @change="updatePackage" autocomplete="off" />
                    {{ pkg.label }} <strong>({{ formatPrice(pkg.price) }})</strong>
                </label>
            </div>
        </div>
        <div v-if="bookingData.service === 'Polissage'">
            <h3>Formules de Polissage Céramique</h3>
            <div class="package-options">
                <label v-for="(pkg, index) in packages.polissage" :key="index" :for="'polissage-' + index">
                    <input type="radio" :id="'polissage-' + index" :value="pkg.value" v-model="selectedPackage"
                        @change="updatePackage" autocomplete="off" />
                    {{ pkg.label }} <strong>({{ formatPrice(pkg.price) }})</strong>
                </label>
            </div>
        </div>
        <div v-if="bookingData.service === 'Remplacement Pare-Brise'">
            <h3>Remplacement de Pare-Brise</h3>
            <p>
                Ce service est réservé exclusivement aux assurés pour le remplacement de pare-brise. Si vous n'êtes pas
                couvert par une assurance brise glace, nous ne pourrons pas réaliser ce service.
            </p>
            <p>Pour les assurés, choisissez l'une des options suivantes :</p>
            <div class="package-options">
                <label v-for="(pkg, index) in packages.pareBrise" :key="index" :for="'pareBrise-' + index">
                    <input type="radio" :id="'pareBrise-' + index" :value="pkg.value" v-model="selectedPackage"
                        @change="updatePackage" autocomplete="off" />
                    {{ pkg.label }} <strong>({{ formatPrice(pkg.price) }})</strong>
                </label>
            </div>
            <p v-if="!bookingData.insuredForGlassReplacement">
                Inclus : Franchise gratuite, Carte cadeau au choix, Articles multimédia
            </p>
            <p>Bonus de Parrainage : 50€</p>
        </div>
        <div v-if="bookingData.service === 'Extra'">
            <h3>Options Extra</h3>
            <div class="package-options">
                <label v-for="(pkg, index) in packages.extras" :key="index" :for="'extra-' + index">
                    <input type="checkbox" :id="'extra-' + index" :value="pkg.value" v-model="selectedExtras"
                        @change="updatePackage" autocomplete="off" />
                    {{ pkg.label }} <strong>({{ formatPrice(pkg.price) }})</strong>
                </label>
            </div>
        </div>
        <div v-if="selectedPackageDescription">
            <h3>Description du Forfait/Formule</h3>
            <p>{{ selectedPackageDescription }}</p>
        </div>
        <div class="form-navigation">
            <button @click="prevStep">Précédent</button>
            <button v-if="showNextButton" class="next-button" @click="nextStep">Suivant</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bookingData: Object
    },
    data() {
        return {
            selectedPackage: this.bookingData.package || '',
            selectedExtras: this.bookingData.extras || [],
            packages: {
                nettoyage: [
                    { value: 'Package Splash', label: 'Package Splash', price: '39.90', description: 'Nettoyage de base pour un entretien régulier : aspiration de l’habitacle, nettoyage des vitres intérieures, nettoyage et finition des plastiques. Temps d\'intervention : 45 minutes.' },
                    { value: 'Package Auto', label: 'Package Auto', price: '69.90', description: 'Nettoyage en profondeur : aspiration de l’habitacle, aspiration du coffre, désinfection du véhicule, nettoyage des vitres intérieures, nettoyage et finition des plastiques, shampouinage des tapis. Temps d\'intervention : 1 heure.' },
                    { value: 'Package BM', label: 'Package BM', price: '89.90', description: 'Nettoyage premium : aspiration de l’habitacle, aspiration du coffre, désinfection du véhicule, nettoyage des vitres intérieures, nettoyage et finition des plastiques, rénovation des plastiques, shampouinage des tapis, shampouinage des sièges. Temps d\'intervention : 1 heure 30 minutes.' }
                ],
                polissage: [
                    { value: 'Formule 1', label: 'Formule 1', price: '139.90', description: 'Polissage : élimine les micro-rayures et les oxydations. Céramique : crée une barrière protectrice durable, repousse l\'eau et les saletés. Temps d\'intervention : 1 heure.' },
                    { value: 'Formule 2', label: 'Formule 2', price: '189.90', description: 'Polissage + céramique, Nettoyage extérieur à la vapeur, Rénovation des phares avant. Temps d\'intervention : 1 heure 30 minutes.' },
                    { value: 'Formule 3', label: 'Formule 3', price: '249.90', description: 'Polissage + céramique, Package BM, Nettoyage extérieur à la vapeur, Rénovation des phares avant et arrière. Temps d\'intervention : 2 heures.' }
                ],
                pareBrise: [
                    { value: 'Carte Cadeau', label: 'Carte Cadeau', price: 'Gratuit', description: 'Vous recevez une carte cadeau de votre choix parmi Amazon, Airbnb, ou Total. Temps d\'intervention : 2 heures.' },
                    { value: 'Formule 1 + Package Auto', label: 'Formule 1 + Package Auto', price: 'Gratuit', description: 'Remplacement de pare-brise avec possibilité d’ajouter la Formule 1 à 139.90€ + un package auto gratuitement. Temps d\'intervention : 2 heures.' }
                ],
                extras: [
                    { value: 'Nettoyage des Tapis', label: 'Nettoyage des Tapis', price: '19.90' },
                    { value: 'Nettoyage extérieur à vapeur', label: 'Nettoyage extérieur à vapeur', price: '29.90' },
                    { value: 'Rénovation des Phares Avant', label: 'Rénovation des Phares Avant', price: '39.90' },
                    { value: 'Rénovation des Phares Arrière', label: 'Rénovation des Phares Arrière', price: '39.90' },
                    { value: 'Nettoyage Complet des Sièges', label: 'Nettoyage Complet des Sièges', price: '49.90' }
                ]
            }
        };
    },
    computed: {
        selectedPackageDescription() {
            const selected = this.getSelectedPackage();
            return selected ? selected.description : '';
        },
        showNextButton() {
            if (this.bookingData.service === 'Extra') {
                return this.selectedExtras.length > 0; // Affiche le bouton si un extra est sélectionné
            }
            return !!this.selectedPackage; // Affiche le bouton si un package est sélectionné
        }
    },
    methods: {
        formatPrice(price) {
            return price === 'Gratuit' ? price : parseFloat(price).toFixed(2) + '€';
        },
        updatePackage() {
            const selectedPackage = this.getSelectedPackage();
            const packageData = {
                package: this.selectedPackage,
                packagePrice: selectedPackage ? selectedPackage.price : null,
                extras: this.selectedExtras
            };

            // Si le service est "Remplacement Pare-Brise", stocker l'option dans `specialOffer`
            if (this.bookingData.service === 'Remplacement Pare-Brise') {
                packageData.specialOffer = this.selectedPackage;
            }

            this.$emit('update:bookingData', packageData);
        },
        getSelectedPackage() {
            let packageList = [];
            if (this.bookingData.service === 'Nettoyage') {
                packageList = this.packages.nettoyage;
            } else if (this.bookingData.service === 'Polissage') {
                packageList = this.packages.polissage;
            } else if (this.bookingData.service === 'Remplacement Pare-Brise') {
                packageList = this.packages.pareBrise;
            } else if (this.bookingData.service === 'Extra') {
                packageList = this.packages.extras;
            }
            return packageList.find(pkg => pkg.value === this.selectedPackage);
        },
        prevStep() {
            // Réinitialiser les sélections à chaque retour
            this.selectedPackage = '';
            this.selectedExtras = [];

            // Revenir à l'étape précédente
            this.$emit('prev');
        },
        nextStep() {
            this.$emit('next');
        }
    }
};
</script>

<style scoped>
.package-form {
    width: 100%;
}

.package-options,
.extra-options {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

p {
    margin: 1rem 0;
    padding: 0;
}

h2 {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

h3 {
    width: fit-content;
    margin-bottom: 1rem;
}

.package-options label,
.extra-options label {
    display: flex;
    align-items: center;
    gap: 0.2rem;
}

.package-options input[type="radio"],
.extra-options input[type="checkbox"] {
    margin-right: 10px;
}

button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}
</style>
