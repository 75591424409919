<template>
    <div class="calendarContainer">
        <h2>Calendrier de Disponibilité</h2>
        <p class="description">Gérez vos disponibilités en sélectionnant une date dans le calendrier. Une fois la date
            choisie, vous pourrez ajuster les créneaux horaires disponibles ou marquer la journée comme indisponible.
        </p>
        <div class="calendar-desktop">
            <div class="calendar-header">
                <button @click="prevMonth">Précédent</button>
                <span>{{ month }} {{ year }}</span>
                <button @click="nextMonth">Suivant</button>
            </div>
            <div class="calendar-body">
                <div class="calendar-days">
                    <div v-for="(day, index) in daysOfWeek" :key="index" class="day">
                        {{ day }}
                    </div>
                </div>
                <div class="calendar-dates">
                    <div v-for="(date, index) in dates" :key="index" :class="{
                        'date': true,
                        'fullyUnavailable': isFullyUnavailable(date),
                        'available': isAvailableFully(date),
                        'nearlyAvailable': isNearlyAvailable(date),
                        'booked': isBooked(date),
                        'selected': isSelected(date),
                    }" @click="selectDate(date)">
                        {{ date ? date.getDate() : '' }}
                    </div>
                </div>
            </div>

            <div v-if="selectedDate" class="modal-overlay">
                <div class="time-slots-modal">
                    <h3>Disponibilités pour le {{ selectedDate.toLocaleDateString() }}</h3>
                    <div class="legend">
                        <div><span class="color-box unavailable"></span> Indisponible</div>
                        <div><span class="color-box available"></span> Disponible</div>
                    </div>
                    <ul>
                        <li v-for="(slot, index) in generateHourlySlots(selectedDate)" :key="index" :class="{
                            unavailable: isSlotUnavailable(selectedDate, slot),
                            available: !isSlotUnavailable(selectedDate, slot)
                        }" @click="toggleSlotAvailability(selectedDate, slot)">
                            {{ slot }}
                        </li>
                    </ul>

                    <button @click="closeModal">Fermer</button>
                </div>
            </div>

            <div class="legend">
                <div><span class="color-box available-day"></span> Disponible</div>
                <div><span class="color-box nearlyAvailable"></span> Presque disponible</div>
                <div><span class="color-box unavailable-day"></span> Indisponible</div>
            </div>
        </div>

        <div class="calendar-mobile">
            <div class="calendar-header">
                <button @click="prevMonth">
                    <ion-icon name="chevron-back"></ion-icon>
                </button>
                <span>{{ shortMonth }} {{ year }}</span>
                <button @click="nextMonth">
                    <ion-icon name="chevron-forward"></ion-icon>
                </button>
            </div>
            <div class="calendar-body">
                <div class="calendar-days">
                    <div v-for="(day, index) in shortDaysOfWeek" :key="index" class="day">
                        {{ day }}
                    </div>
                </div>
                <div class="calendar-dates">
                    <div v-for="(date, index) in dates" :key="index" :class="{
                        'date': true,
                        'fullyUnavailable': isFullyUnavailable(date),
                        'available': isAvailableFully(date),
                        'nearlyAvailable': isNearlyAvailable(date),
                        'booked': isBooked(date),
                        'selected': isSelected(date),
                    }" @click="selectDate(date)">
                        {{ date ? date.getDate() : '' }}
                    </div>
                </div>

                <div v-if="selectedDate" class="modal-overlay">
                    <div class="time-slots-modal">
                        <h3>Disponibilités pour le {{ selectedDate.toLocaleDateString() }}</h3>
                        <div class="legend">
                            <div><span class="color-box unavailable"></span> Indisponible</div>
                            <div><span class="color-box available"></span> Disponible</div>
                        </div>
                        <ul>
                            <li v-for="(slot, index) in generateHourlySlots(selectedDate)" :key="index" :class="{
                                unavailable: isSlotUnavailable(selectedDate, slot),
                                available: !isSlotUnavailable(selectedDate, slot)
                            }" @click="toggleSlotAvailability(selectedDate, slot)">
                                {{ slot }}
                            </li>
                        </ul>
                        <button @click="closeModal">Fermer</button>
                    </div>
                </div>

                <div class="legend">
                    <div><span class="color-box available-day"></span> Disponible</div>
                    <div><span class="color-box nearlyAvailable"></span> Presque disponible</div>
                    <div><span class="color-box unavailable-day"></span> Indisponible</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CalendarComponent',
    data() {
        return {
            currentMonth: new Date().getMonth(),
            currentYear: new Date().getFullYear(),
            unavailableSlots: [],
            fullyUnavailableDates: [],
            nearlyAvailableDates: [],
            availableDates: [],
            bookedDates: [],
            dates: [],
            selectedDate: null,
        };
    },
    computed: {
        month() {
            return new Intl.DateTimeFormat('fr-FR', { month: 'long' }).format(
                new Date(this.currentYear, this.currentMonth)
            );
        },
        shortMonth() {
            return new Intl.DateTimeFormat('fr-FR', { month: 'short' }).format(
                new Date(this.currentYear, this.currentMonth)
            );
        },
        year() {
            return this.currentYear;
        },
        daysOfWeek() {
            return ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
        },
        shortDaysOfWeek() {
            return ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
        },
    },
    mounted() {
        this.updateDates();
    },
    methods: {
        async fetchDefaultAvailabilities() {
            try {
                const response = await fetch('https://api.splashauto-bm.fr/availabilities');
                if (!response.ok) throw new Error('Erreur lors de la récupération des disponibilités par défaut.');

                const data = await response.json();

                const availableDates = [];
                const today = new Date();
                const firstDayOfMonth = new Date(this.currentYear, this.currentMonth, 1);
                const lastDayOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);

                const dayMap = {
                    'Monday': 1,
                    'Tuesday': 2,
                    'Wednesday': 3,
                    'Thursday': 4,
                    'Friday': 5,
                    'Saturday': 6,
                    'Sunday': 0
                };

                data.forEach(item => {
                    if (item.day_of_week === 'Closed') return;

                    const targetDay = dayMap[item.day_of_week];
                    let current = new Date(firstDayOfMonth);
                    current.setDate(current.getDate() + ((targetDay - current.getDay() + 7) % 7));

                    while (current <= lastDayOfMonth) {
                        availableDates.push(new Date(current));
                        current.setDate(current.getDate() + 7);
                    }
                });

                this.availableDates = availableDates;

            } catch (error) {
                console.error('Erreur de récupération des disponibilités :', error);
            }
        },

        async fetchUnavailabilities() {
            try {
                const response = await fetch('https://api.splashauto-bm.fr/unavailabilities');
                if (!response.ok) throw new Error('Erreur lors de la récupération des indisponibilités.');

                const data = await response.json();
                this.unavailableSlots = data.map(item => ({
                    date: new Date(item.date),
                    slot: item.time_slot,
                }));
            } catch (error) {
                console.error('Erreur de récupération des indisponibilités :', error);
            }
        },

        async updateDates() {
            const startOfMonth = new Date(this.currentYear, this.currentMonth, 1);
            const endOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0);
            const dates = [];

            let startDayOfWeek = startOfMonth.getDay();
            if (startDayOfWeek === 0) {
                startDayOfWeek = 6;
            } else {
                startDayOfWeek -= 1;
            }

            for (let i = 0; i < startDayOfWeek; i++) {
                dates.push(null);
            }

            for (let i = 1; i <= endOfMonth.getDate(); i++) {
                dates.push(new Date(this.currentYear, this.currentMonth, i));
            }

            const endDayOfWeek = endOfMonth.getDay();
            let totalDaysToFill = 6 - endDayOfWeek;
            if (endDayOfWeek === 0) {
                totalDaysToFill = 0;
            }

            for (let i = 1; i <= totalDaysToFill; i++) {
                dates.push(null);
            }

            this.dates = dates;

            // Récupérer les données des APIs pour mettre à jour les dates disponibles
            await this.fetchDefaultAvailabilities();
            await this.fetchUnavailabilities();
            this.updateAvailableDates();
        },

        prevMonth() {
            this.currentMonth -= 1;
            if (this.currentMonth < 0) {
                this.currentMonth = 11;
                this.currentYear -= 1;
            }
            this.updateDates();
        },

        nextMonth() {
            this.currentMonth += 1;
            if (this.currentMonth > 11) {
                this.currentMonth = 0;
                this.currentYear += 1;
            }
            this.updateDates();
        },

        updateAvailableDates() {
            this.updateFullyUnavailableDates();
            this.updateNearlyAvailableDates();
            this.updateBookedDates();
        },

        updateFullyUnavailableDates() {
            this.fullyUnavailableDates = this.dates.filter(date => {
                return this.isFullyUnavailable(date);
            });
        },

        updateNearlyAvailableDates() {
            this.nearlyAvailableDates = this.dates.filter(date => {
                if (!date || !(date instanceof Date)) return false;
                // Écarte les dates entièrement indisponibles
                if (this.isFullyUnavailable(date)) return false;

                const slots = this.generateHourlySlots(date);
                const unavailableSlots = slots.filter(slot => this.isSlotUnavailable(date, slot));
                // Considère une date comme "presque disponible" si elle a quelques créneaux non disponibles
                const isNearlyAvailable = unavailableSlots.length > 0 && unavailableSlots.length < slots.length;
                return isNearlyAvailable;
            });
        },

        updateBookedDates() {
            if (!this.dates || !Array.isArray(this.dates)) return;

            this.bookedDates = this.dates.filter(date => {
                if (!date || !(date instanceof Date)) return false;
                // Écarte les dates entièrement indisponibles
                if (this.isFullyUnavailable(date)) return false;

                const slots = this.generateHourlySlots(date);
                const allSlotsBooked = slots.every(slot => this.isSlotUnavailable(date, slot));
                return allSlotsBooked;
            });
        },

        isFullyUnavailable(date) {
            if (!date) return false;

            const now = new Date();
            const endOfDayTimes = {
                0: '00:00', // Dimanche : indisponible toute la journée
                1: '18:30', // Lundi
                2: '18:30', // Mardi
                3: '18:30', // Mercredi
                4: '18:30', // Jeudi
                5: '19:00', // Vendredi
                6: '16:30'  // Samedi
            };

            const dayOfWeek = date.getDay();
            const [endHour, endMinute] = endOfDayTimes[dayOfWeek].split(':').map(Number);

            // Fin de la journée de travail
            const endOfWorkDay = new Date(date);
            endOfWorkDay.setHours(endHour, endMinute, 0, 0);

            // Conditions pour déterminer si la date est complètement indisponible
            const isWeekend = dayOfWeek === 0;
            const isPastWorkHours = date.toDateString() === now.toDateString() && now > endOfWorkDay;
            const isPastDate = date < new Date(now.setHours(0, 0, 0, 0)); // Dates antérieures à aujourd'hui

            return isWeekend || isPastWorkHours || isPastDate;
        },

        isAvailableFully(date) {
            return date &&
                this.availableDates.some(d => d.toDateString() === date.toDateString()) &&
                !this.isFullyUnavailable(date);
        },

        isBooked(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            if (!date || !this.bookedDates) return false;
            return date >= today && this.bookedDates.some(
                d => d instanceof Date && d.toDateString() === date.toDateString()
            );
        },

        isNearlyAvailable(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date && date >= today && this.nearlyAvailableDates.some(
                d => d.toDateString() === date.toDateString()
            );
        },

        isSelected(date) {
            // Returns true if the date is selected
            return this.selectedDate && date && this.selectedDate.toDateString() === date.toDateString();
        },

        selectDate(date) {
            if (this.isFullyUnavailable(date)) {
                alert('Cette date est complètement indisponible.');
                return;
            }

            if (this.isAvailableFully(date)) {
                this.selectedDate = date;
                this.updateAvailableDates();
            } else {
                alert('Cette date n\'est pas disponible.');
            }
        },

        generateHourlySlots(date) {
            const day = date.getDay();
            const now = new Date();
            const isToday = date.toDateString() === now.toDateString();
            const slots = {
                weekday: ['09:00 - 10:00', '10:00 - 11:00', '11:00 - 12:00', '13:30 - 14:30', '14:30 - 15:30', '15:30 - 16:30', '16:30 - 17:30', '17:30 - 18:30'],
                friday: ['09:00 - 10:00', '10:00 - 11:00', '11:00 - 12:00', '12:00 - 13:00', '15:00 - 16:00', '16:00 - 17:00', '17:00 - 18:00', '18:00 - 19:00'],
            };

            const currentSlots = day === 5 ? slots.friday : slots.weekday;

            // Si c'est aujourd'hui, filtre les créneaux passés
            if (isToday) {
                const currentTime = now.getHours() + now.getMinutes() / 60;
                return currentSlots.filter(slot => {
                    const [startHour, startMinute] = slot.split(' - ')[0].split(':').map(Number);
                    const slotTime = startHour + startMinute / 60;
                    // Ne retourne que les créneaux futurs ou en cours
                    return slotTime >= currentTime;
                });
            }

            return currentSlots;
        },

        isSlotUnavailable(date, slot) {
            // Marquer automatiquement comme indisponibles les créneaux horaires déjà passés pour aujourd'hui
            if (date && date.toDateString() === new Date().toDateString()) {
                const now = new Date();
                const [startHour, startMinute] = slot.split(' - ')[0].split(':').map(Number);
                const slotDate = new Date(date);
                slotDate.setHours(startHour, startMinute, 0, 0);
                if (slotDate <= now) {
                    return true; // Considéré comme indisponible car passé
                }
            }

            return date && this.unavailableSlots.some(
                unavailable => unavailable.date instanceof Date && unavailable.date.toDateString() === date.toDateString() && unavailable.slot === slot
            );
        },

        async toggleSlotAvailability(date, slot) {
            // Ne pas permettre le basculement si le créneau est déjà passé pour aujourd'hui
            if (date && date.toDateString() === new Date().toDateString()) {
                const now = new Date();
                const [startHour, startMinute] = slot.split(' - ')[0].split(':').map(Number);
                const slotDate = new Date(date);
                slotDate.setHours(startHour, startMinute, 0, 0);
                if (slotDate <= now) {
                    alert('Ce créneau est déjà passé et ne peut pas être modifié.');
                    return;
                }
            }

            const isCurrentlyUnavailable = this.isSlotUnavailable(date, slot);

            if (isCurrentlyUnavailable) {
                // Remove unavailability
                await this.removeUnavailability(date, slot);
                // Remove from array
                this.unavailableSlots = this.unavailableSlots.filter(
                    unavailable => !(unavailable.date.toDateString() === date.toDateString() && unavailable.slot === slot)
                );
            } else {
                // Add unavailability
                await this.addUnavailability(date, slot);
                // Add to array
                this.unavailableSlots.push({ date, slot });
            }

            // Update available dates
            this.updateAvailableDates();
        },

        async addUnavailability(date, slot) {
            try {
                // Convert to UTC for consistency
                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                const response = await fetch('https://api.splashauto-bm.fr/add_unavailabilities', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        date: utcDate.toISOString().split('T')[0], // Use YYYY-MM-DD format
                        slots: [slot],
                    }),
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de l\'ajout de l\'indisponibilité.');
                }

                const data = await response.json();
                console.log(data.message);
            } catch (error) {
                console.error('Erreur de ajout d\'indisponibilité :', error);
            }
        },

        async removeUnavailability(date, slot) {
            try {
                // Convert to UTC for consistency
                const utcDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
                const response = await fetch('https://api.splashauto-bm.fr/remove_unavailabilities', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        date: utcDate.toISOString().split('T')[0], // Use YYYY-MM-DD format
                        slots: [slot],
                    }),
                });

                if (!response.ok) {
                    throw new Error('Erreur lors de la suppression de l\'indisponibilité.');
                }

                const data = await response.json();
                console.log(data.message);
            } catch (error) {
                console.error('Erreur de suppression d\'indisponibilité :', error);
            }
        },

        closeModal() {
            this.selectedDate = null;
            this.removeModalClass();
        },

        addModalClass() {
            document.body.classList.add('modal-open');
        },

        removeModalClass() {
            document.body.classList.remove('modal-open');
        }
    },
    watch: {
        selectedDate(newValue) {
            if (newValue) {
                this.addModalClass();
            } else {
                this.removeModalClass();
            }
        }
    },
};
</script>

<style scoped>
/* Container */
.calendarContainer {
    padding: 2em;
}

button {
    padding: 0.5em 1em;
    background-color: var(--white-color);
    color: var(--black-color);
    cursor: pointer;
}

button:hover {
    background-color: var(--black-color);
    color: var(--white-color);
    border: 2px solid var(--white-color);
}

button:disabled {
    background-color: var(--grey-color);
    color: var(--black-color);
    cursor: not-allowed;
}

.description {
    text-transform: uppercase;
    font-size: 1.4em;
}

/* Calendar */
.calendar-desktop {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.calendarContainer h2 {
    text-transform: uppercase;
}

/* Calendar Header */
.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0 0 0;
    text-transform: uppercase;
}

/* Calendar Body */
.calendar-body {
    display: grid;
    gap: 1em;
    width: 100%;
}

.calendar-days,
.calendar-dates {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: .5rem;
}

/* Day and Date Styles */
.day,
.date {
    text-align: center;
    padding: 0.5em;
    font-weight: bold;
    text-transform: uppercase;
}

/* Date States */
.date.fullyUnavailable {
    background-color: var(--black-color);
    color: var(--white-color);
}

.date.available {
    background-color: var(--yellow-color);
    cursor: pointer;
}

.date.nearlyAvailable {
    background-color: var(--yellow-color-darker);
}

.date.booked {
    background-color: var(--red-color);
}

/* Color Box */
.color-box {
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
}

.color-box.available {
    background-color: var(--green-color);
}

.color-box.available-day,
.color-box.unavailable-day {
    background-color: var(--yellow-color);
}

.color-box.unavailable,
.color-box.unavailable-day {
    background-color: var(--red-color);
}

.color-box.nearlyAvailable {
    background-color: var(--yellow-color-darker);
}

/* Time Slots Modal */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.time-slots-modal {
    position: relative;
    width: 75%;
    background: var(--white-color);
    color: var(--black-color);
    padding: 1em;
    z-index: 1002;
}

.modal-overlay.modal-open {
    overflow: hidden;
}

.time-slots-modal button {
    padding: 0.5em 1em;
    background-color: var(--black-color);
    color: var(--white-color);
    cursor: pointer;
    border: 2px solid var(--black-color);
}

.time-slots-modal button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}

.time-slots-modal ul {
    list-style-type: none;
    padding: 0;
}

.time-slots-modal li {
    margin: 0.5em 0;
    padding: 0.5em;
    cursor: pointer;
}

.time-slots-modal li:active {
    border-color: var(--yellow-color);
    outline: none;
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.time-slots-modal li.unavailable {
    background-color: var(--red-color);
    color: var(--black-color);
}

.time-slots-modal li.available {
    background-color: var(--green-color);
    color: var(--white-color);
}

/* Legend */
.calendar-desktop .legend,
.calendar-mobile .legend {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
}

.calendar-desktop .legend div,
.calendar-mobile .legend div {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.calendar-desktop .time-slots-modal .legend,
.calendar-mobile .time-slots-modal .legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    gap: .5em;
}

.legend {
    text-transform: uppercase;
    font-size: 1.4em;
}

.time-slots-modal .legend {
    font-size: initial;
    text-transform: none;
    font-weight: normal;
}

/* Responsive Styles */
.calendar-mobile {
    display: none;
}

@media (max-width: 1024px) {
    .description {
        text-transform: uppercase;
        font-size: initial;
    }

    .calendarContainer {
        padding: 1.5em;
    }

    .calendarContainer h2 {
        font-size: 1.4em;
    }

    .calendar-mobile .legend {
        font-size: medium;
    }

    .day,
    .date {
        font-size: unset;
    }

    .legend {
        font-size: initial;
    }
}

@media (max-width: 768px) {
    .calendar-desktop {
        display: none;
    }

    .calendar-mobile {
        display: block;
    }

    .calendarContainer {
        padding: 1em;
    }

    .calendarContainer h2 {
        font-size: 1.2em;
    }

    .calendar-body {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .calendar-mobile .calendar-header button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 2rem;
    }

    .calendar-mobile .calendar-header ion-icon {
        margin: 0;
    }

    .calendar-mobile .legend {
        font-size: smaller;
    }

    .calendar-header {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 0.75rem;
        padding: .5em;
    }

    .calendar-days,
    .calendar-dates {
        justify-items: center;
        gap: .25rem;
    }

    .time-slots-modal {
        width: 75%;
        height: 25rem;
        overflow: scroll;
    }

    .day,
    .date {
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .calendar-mobile .legend {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 1em 0 0.5em 0.5em;
        gap: .25em;
    }

    .calendar-mobile .legend div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.2em;
    }

    .calendar-mobile .time-slots-modal .legend {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0;
        padding: 0;
        gap: .5em;
    }

    .calendar-mobile .time-slots-modal .legend div {
        font-size: initial;
    }
}

@media (max-width: 480px) {
    .calendarContainer {
        padding: 0.5em;
    }

    .calendarContainer h2 {
        font-size: 1em;
    }

    .calendar-mobile .legend div {
        font-size: 1em;
    }

    .description {
        font-size: smaller;
    }

    .day,
    .date {
        width: 1.1rem;
        height: 1.1rem;
        font-size: small;
    }
}

@media (max-width: 360px) {

    .day,
    .date {
        font-size: .7rem;
    }
}
</style>
