<template>
    <div class="login-register-form">
        <h2 class="text-yellow">{{ isExistingUser ? 'Connexion' : 'Fournir les Informations Personnelles' }}</h2>
        <div class="toggleButton">
            <button @click="toggleUserStatus" class="next-button">
                {{ isExistingUser ? "Je n'ai pas de compte" : "J'ai déjà un compte" }}
            </button>
        </div>

        <div v-if="!isExistingUser" class="register-form">
            <!-- Champs pour l'inscription -->
            <div class="form-group">
                <label for="firstName"><strong>Prénom</strong></label>
                <input v-model="personalInfo.firstName" id="firstName" type="text" autocomplete="given-name" required />
            </div>
            <div class="form-group">
                <label for="lastName"><strong>Nom de Famille</strong></label>
                <input v-model="personalInfo.lastName" id="lastName" type="text" autocomplete="family-name" required />
            </div>
            <div class="form-group">
                <label for="phoneNumber"><strong>Numéro de Téléphone</strong></label>
                <input v-model="personalInfo.phoneNumber" id="phoneNumber" type="tel"
                    pattern="^(\+33|0)[1-9](\d{2}){4}$"
                    title="Le numéro de téléphone doit commencer par 0 ou +33 suivi de 9 chiffres." autocomplete="tel"
                    required />
            </div>
            <div class="form-group">
                <label for="email"><strong>Adresse Email</strong></label>
                <input v-model="personalInfo.email" id="email" type="email" autocomplete="email" required />
            </div>
            <div class="form-group">
                <label for="address"><strong>Rue</strong></label>
                <input v-model="personalInfo.address" id="address" type="text" autocomplete="address-line1" required />
            </div>
            <div class="form-group">
                <label for="city"><strong>Ville</strong></label>
                <input v-model="personalInfo.city" id="city" type="text" autocomplete="off" required />
            </div>
            <div class="form-group">
                <label for="postalCode"><strong>Code Postal</strong></label>
                <input v-model="personalInfo.postalCode" id="postalCode" type="text" autocomplete="postal-code"
                    required />
            </div>
            <div class="form-group">
                <label for="country"><strong>Pays</strong></label>
                <input v-model="personalInfo.country" id="country" type="text" autocomplete="country" required />
            </div>
            <div class="form-group">
                <label for="vehicleType"><strong>Type de Véhicule</strong></label>
                <select v-model="personalInfo.vehicleType" id="vehicleType">
                    <option v-for="option in vehicleTypeOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>

            <!-- Sections conditionnelles pour les fichiers -->
            <div v-if="requiresVehiclePhotos" class="form-group">
                <label for="vehiclePhotos"><strong>Photos du Véhicule (JPG, PNG, PDF - max 2Mo par fichier, jusqu'à 5
                        fichiers)</strong></label>
                <input type="file" id="vehiclePhotos" @change="handleFilesChange($event)" multiple />
                <ul>
                    <li v-for="fileName in files.vehiclePhotosFileNames" :key="fileName">{{ fileName }}</li>
                </ul>
            </div>

            <div v-if="requiresCarRegistration" class="form-group">
                <label for="carRegistration"><strong>Carte Grise (JPG, PNG, PDF - max 2Mo)</strong></label>
                <input type="file" id="carRegistration" @change="handleFileChange('carRegistration', $event)" />
                <p v-if="files.carRegistrationFileName">{{ files.carRegistrationFileName }}</p>
            </div>

            <div v-if="requiresTechnicalInspection" class="form-group">
                <label for="technicalInspection"><strong>Contrôle Technique (JPG, PNG, PDF - max 2Mo)</strong></label>
                <input type="file" id="technicalInspection" @change="handleFileChange('technicalInspection', $event)" />
                <p v-if="files.technicalInspectionFileName">{{ files.technicalInspectionFileName }}</p>
            </div>

            <div class="form-group">
                <label for="password"><strong>Mot de Passe</strong></label>
                <input v-model="personalInfo.password" id="password" type="password" autocomplete="new-password"
                    required />
            </div>
            <div class="form-group">
                <label for="passwordConfirmation"><strong>Confirmation Mot de Passe</strong></label>
                <input v-model="personalInfo.passwordConfirmation" id="passwordConfirmation" type="password"
                    autocomplete="new-password" required />
            </div>
        </div>

        <div v-if="isExistingUser" class="login-form">
            <!-- Champs pour la connexion -->
            <div class="form-group">
                <label for="loginEmail"><strong>Adresse Email</strong></label>
                <input v-model="personalInfo.email" id="loginEmail" type="email" autocomplete="email" required />
            </div>
            <div class="form-group">
                <label for="loginPassword"><strong>Mot de Passe</strong></label>
                <input v-model="personalInfo.password" id="loginPassword" type="password"
                    autocomplete="current-password" required />
            </div>
            <div class="form-group">
                <label for="vehicleType"><strong>Type de Véhicule</strong></label>
                <select v-model="personalInfo.vehicleType" id="vehicleType">
                    <option v-for="option in vehicleTypeOptions" :key="option.value" :value="option.value">
                        {{ option.label }}
                    </option>
                </select>
            </div>
        </div>

        <!-- Navigation entre les étapes -->
        <div class="form-navigation">
            <button @click="prevStep">Précédent</button>
            <button v-if="isFormValid" class="next-button" @click="nextStep">
                {{ isExistingUser ? 'Connexion et Suivant' : 'Créer le Compte et Suivant' }}
            </button>
        </div>

        <!-- Message d'erreur ou de succès -->
        <div v-if="message" :class="['message', message.type]">
            {{ message.text }}
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    props: {
        bookingData: Object,
    },
    data() {
        return {
            isExistingUser: false,
            personalInfo: this.bookingData.personalInfo || {
                firstName: '',
                lastName: '',
                phoneNumber: '',
                email: '',
                address: '',
                city: '',
                postalCode: '',
                country: '',
                vehicleType: '',
                password: '',
                passwordConfirmation: '',
            },
            message: null,
            files: {
                carRegistration: null,
                technicalInspection: null,
                vehiclePhotos: [],
                carRegistrationFileName: '',
                technicalInspectionFileName: '',
                vehiclePhotosFileNames: [],
            },
        };
    },
    computed: {
        isFormValid() {
            if (this.isExistingUser) {
                return this.personalInfo.email && this.personalInfo.password && this.personalInfo.vehicleType;
            } else {
                const allFieldsFilled =
                    this.personalInfo.firstName &&
                    this.personalInfo.lastName &&
                    this.personalInfo.phoneNumber &&
                    this.personalInfo.email &&
                    this.personalInfo.address &&
                    this.personalInfo.city &&
                    this.personalInfo.postalCode &&
                    this.personalInfo.country &&
                    this.personalInfo.vehicleType &&
                    this.personalInfo.password &&
                    this.personalInfo.passwordConfirmation &&
                    this.personalInfo.password === this.personalInfo.passwordConfirmation;

                const filesValid = this.requiresVehiclePhotos ? this.files.vehiclePhotos.length > 0 : true;
                const registrationValid = this.requiresCarRegistration ? this.files.carRegistration : true;
                const inspectionValid = this.requiresTechnicalInspection ? this.files.technicalInspection : true;

                return allFieldsFilled && filesValid && registrationValid && inspectionValid;
            }
        },
        requiresCarRegistration() {
            return this.bookingData.service === 'Remplacement Pare-Brise';
        },
        requiresTechnicalInspection() {
            return this.bookingData.service === 'Remplacement Pare-Brise';
        },
        requiresVehiclePhotos() {
            return this.bookingData.service === 'Polissage' || this.bookingData.service === 'Remplacement Pare-Brise';
        },
        vehicleTypeOptions() {
            return [
                { label: 'Citadine (Gratuit)', value: 'Citadine' },
                { label: 'Berline/Compact (+9.90€)', value: 'Berline/Compact' },
                { label: '4x4/Familiale/Break (+15.90€)', value: '4x4/Familiale/Break' },
                { label: 'Van/Pick Up (sur devis)', value: 'Van/Pick Up' }
            ];
        },
    },
    methods: {
        ...mapActions(['login', 'register', 'fetchUser', 'updateUserFiles']),
        toggleUserStatus() {
            this.isExistingUser = !this.isExistingUser;
        },
        async nextStep() {
            try {
                const modifiedPersonalInfo = {
                    ...this.personalInfo,
                    vehicle_type: this.personalInfo.vehicleType
                };

                if (this.isExistingUser) {
                    // Vérifiez si l'utilisateur existe et que le mot de passe est correct
                    const existsResponse = await fetch('https://api.splashauto-bm.fr/user-exists', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            email: modifiedPersonalInfo.email,
                            password: modifiedPersonalInfo.password
                        })
                    });

                    const existsData = await existsResponse.json();
                    if (!existsData.exists) {
                        this.message = { type: 'error', text: "L'email ou le mot de passe est incorrect. Veuillez réessayer." };
                        return; // Arrêtez l'exécution ici pour empêcher le passage à l'étape suivante
                    }

                    // Procédez à la connexion si l'utilisateur existe et le mot de passe est correct
                    await this.handleLogin(modifiedPersonalInfo);
                } else {
                    // Procédez à l'inscription
                    await this.handleRegister(modifiedPersonalInfo);
                }

                // Mettre à jour les données et passer à l'étape suivante seulement si tout est correct
                this.$emit('update-booking-data', { personalInfo: modifiedPersonalInfo });
                this.$emit('next');
            } catch (error) {
                console.error(error);
                this.message = { type: 'error', text: 'Une erreur est survenue. Veuillez réessayer.' };
            }
        },
        async handleLogin(personalInfo) {
            const response = await this.login({
                email: personalInfo.email,
                password: personalInfo.password,
                vehicle_type: personalInfo.vehicle_type
            });
            if (response.success) {
                await this.fetchUser();
                this.message = { type: 'success', text: 'Connexion réussie !' };
                setTimeout(() => this.$emit('next'), 1000);
            } else {
                this.message = { type: 'error', text: response.message };
            }
        },
        async handleRegister(personalInfo) {
            const response = await this.register({
                firstname: personalInfo.firstName,
                lastname: personalInfo.lastName,
                phone: personalInfo.phoneNumber,
                email: personalInfo.email,
                password: personalInfo.password,
                address: {
                    street: personalInfo.address,
                    city: personalInfo.city,
                    postalCode: personalInfo.postalCode,
                    country: personalInfo.country
                },
                vehicle_type: personalInfo.vehicle_type
            });

            if (response.success) {
                this.message = { type: 'success', text: 'Inscription réussie !' };
                await this.handleFileUpload();
                setTimeout(() => this.$emit('next'), 1000);
            } else {
                this.message = { type: 'error', text: response.message };
            }
        },
        async handleFileUpload() {
            try {
                const formData = new FormData();
                if (this.files.carRegistration) formData.append('registration_certificate', this.files.carRegistration);
                if (this.files.technicalInspection) formData.append('technical_control_file', this.files.technicalInspection);
                this.files.vehiclePhotos.forEach(file => formData.append('vehicle_photo_file[]', file));

                const response = await fetch('https://api.splashauto-bm.fr/update/files', {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Erreur lors de la mise à jour des fichiers.');
                }

                const updatedFiles = await response.json();
                this.$store.commit('UPDATE_USER_FILES', updatedFiles);
                this.message = { type: 'success', text: 'Les fichiers ont été mis à jour avec succès.' };
            } catch (error) {
                console.error('Erreur lors de l\'enregistrement des fichiers:', error);
                this.message = { type: 'error', text: 'Erreur lors de la mise à jour des fichiers.' };
            }
        },
        handleFileChange(type, event) {
            const file = event.target.files[0];
            const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            const maxFileSize = 2 * 1024 * 1024;

            if (file && validFileTypes.includes(file.type) && file.size <= maxFileSize) {
                this.files[`${type}FileName`] = file.name;
                this.files[type] = file;
            } else {
                this.message = {
                    type: 'error',
                    text: 'Fichier invalide. Seuls les fichiers JPG, PNG, PDF sont acceptés et doivent être inférieurs à 2 Mo.',
                };
                event.target.value = '';
            }
        },
        handleFilesChange(event) {
            const selectedFiles = Array.from(event.target.files);
            const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
            const maxFileSize = 2 * 1024 * 1024;

            const invalidFiles = selectedFiles.filter(
                file => !validFileTypes.includes(file.type) || file.size > maxFileSize
            );

            if (invalidFiles.length > 0) {
                this.message = {
                    type: 'error',
                    text: 'Certains fichiers sont invalides. Seuls les fichiers JPG, PNG, PDF sont acceptés et doivent être inférieurs à 2 Mo.',
                };
                event.target.value = '';
                return;
            }

            this.files.vehiclePhotos = selectedFiles;
            this.files.vehiclePhotosFileNames = selectedFiles.map(file => file.name);
        },
        prevStep() {
            this.$emit('prev');
        },
    },
};
</script>

<style scoped>
.login-register-form {
    width: 100%;
}

h2 {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

h3 {
    width: fit-content;
    margin-bottom: 1rem;
}

label {
    display: block;
    margin-bottom: .75rem;
}

input,
textarea,
select {
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
    border-radius: 0px;
    transition: border-color 0.3s ease;
}

input[type="file"] {
    border: none;
}

input,
textarea {
    width: calc(100% - 1.1rem);
}

select {
    width: calc(100% + 0px);
}

input:focus,
textarea:focus,
select:focus {
    border-color: var(--yellow-color);
    outline: none;
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.toggleButton {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
}

button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}

.message {
    margin-top: 1rem;
    padding: 0;
    border-radius: 0;
}

.message.success {
    color: var(--green-color);
}

.message.error {
    color: var(--red-color);
}
</style>
