<template>
    <div class="appointmentsViewer">
        <h2>Rendez-vous des utilisateurs</h2>
        <p class="description">Vous êtes sur la page de gestion des rendez-vous des utilisateurs. Consultez et gérez les
            détails de chaque rendez-vous en fonction des services sélectionnés et des disponibilités des utilisateurs.
        </p>

        <div class="item">
            <input id="searchTerm" name="searchTerm" v-model="searchTerm" type="text"
                placeholder="Rechercher un rendez-vous" class="search-input" autocomplete="off" />
        </div>

        <div v-if="paginatedReservations.length === 0">
            <p>Aucun rendez-vous trouvé.</p>
        </div>

        <ul v-for="(reservations, month) in groupedReservations" :key="month">
            <li class="month-group">
                <h3>{{ month }}</h3>
                <ul>
                    <li v-for="reservation in reservations" :key="reservation.id" class="reservation-item">
                        <p><strong>Utilisateur :</strong> {{ reservation.first_name }} {{ reservation.last_name }} ({{
                            reservation.email }})</p>
                        <p><strong>Service :</strong> {{ reservation.service }}</p>
                        <p><strong>Package :</strong> {{ reservation.package || 'Aucun package sélectionné' }}</p>
                        <p><strong>Extras :</strong> {{ formatExtras(reservation.extras) }}</p>
                        <p><strong>Type de Véhicule :</strong> {{ getVehicleTypeName(reservation.vehicle_type_id) }}</p>
                        <p><strong>Date :</strong> {{ formatDate(reservation.date) }}</p>
                        <p><strong>Créneau Horaire :</strong> {{ combineTimeSlots(reservation) }}</p>
                        <p><strong>Montant :</strong> {{ formatAmount(reservation) }}</p>
                        <p v-if="shouldShowInfoMessage(reservation) && !isPastReservation(reservation)" class="info">
                            Le montant se met à jour automatiquement en fonction de l'heure d'arrivée de l'utilisateur.
                            Si le client arrive à l'heure ou avec moins de 15 minutes de retard, une réduction de 15€
                            est appliquée.
                            En revanche, si le client a plus de 15 minutes de retard, le montant initial ({{
                                calculateOriginalAmount(reservation) }}) est dû sans aucune réduction.
                        </p>

                        <p v-if="isPastReservation(reservation)">
                            <strong>Statut :</strong> <span class="status-passed">Déjà passé</span>
                        </p>

                        <!-- Affichage des fichiers associés -->
                        <div v-if="reservation.registration_certificate_url">
                            <p><strong>Carte Grise :</strong> <a
                                    :href="generateFileUrl(reservation.registration_certificate_url)"
                                    target="_blank">Voir la carte grise</a></p>
                        </div>
                        <div v-if="reservation.technical_control_file_url">
                            <p><strong>Contrôle Technique :</strong> <a
                                    :href="generateFileUrl(reservation.technical_control_file_url)" target="_blank">Voir
                                    le contrôle technique</a></p>
                        </div>
                        <div v-if="reservation.vehicle_photo_url">
                            <p><strong>Photos du Véhicule :</strong></p>
                            <ul>
                                <li v-for="(photoUrl, index) in splitUrls(reservation.vehicle_photo_url)" :key="index">
                                    <a :href="generateFileUrl(photoUrl.trim())" target="_blank">Voir la photo {{ index +
                                        1 }}</a>
                                </li>
                            </ul>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>

        <!-- Pagination -->
        <div class="pagination">
            <button @click="prevPage" :disabled="currentPage === 1">
                <span class="desktop">Précédent</span>
                <span class="mobile"><ion-icon name="chevron-back"></ion-icon></span>
            </button>
            <span>Page {{ currentPage }} sur {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">
                <span class="desktop">Suivant</span>
                <span class="mobile"><ion-icon name="chevron-forward"></ion-icon></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            reservations: [],
            searchTerm: '',
            currentPage: 1,
            itemsPerPage: 5
        };
    },
    computed: {
        filteredReservations() {
            const searchTerm = this.searchTerm.toLowerCase();

            let filtered = this.reservations.filter(reservation => {
                const reservationId = reservation.id ? reservation.id.toString() : '';
                const firstName = reservation.first_name ? reservation.first_name.toLowerCase() : '';
                const lastName = reservation.last_name ? reservation.last_name.toLowerCase() : '';
                const email = reservation.email ? reservation.email.toLowerCase() : '';
                const service = reservation.service ? reservation.service.toLowerCase() : '';
                const packageName = reservation.package ? reservation.package.toLowerCase() : '';
                const vehicleType = this.getVehicleTypeName(reservation.vehicle_type_id).toLowerCase();
                const formattedDate = this.formatDate(reservation.date).toLowerCase();
                const timeSlot = reservation.time_slot ? reservation.time_slot.toLowerCase() : '';
                const amount = this.formatAmount(reservation).toLowerCase();
                const extras = reservation.extras ? this.formatExtras(reservation.extras).toLowerCase() : '';

                const registrationCertificate = reservation.registration_certificate_url ? 'carte grise' : '';
                const technicalControl = reservation.technical_control_file_url ? 'contrôle technique' : '';
                const vehiclePhoto = reservation.vehicle_photo_url ? 'photos du véhicule' : '';

                return reservationId.includes(searchTerm) ||
                    firstName.includes(searchTerm) ||
                    lastName.includes(searchTerm) ||
                    email.includes(searchTerm) ||
                    service.includes(searchTerm) ||
                    packageName.includes(searchTerm) ||
                    vehicleType.includes(searchTerm) ||
                    formattedDate.includes(searchTerm) ||
                    timeSlot.includes(searchTerm) ||
                    amount.includes(searchTerm) ||
                    extras.includes(searchTerm) ||
                    registrationCertificate.includes(searchTerm) ||
                    technicalControl.includes(searchTerm) ||
                    vehiclePhoto.includes(searchTerm);
            });

            filtered.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateB - dateA; // Tri décroissant
            });

            return filtered;
        },
        groupedReservations() {
            const grouped = {};

            this.filteredReservations.forEach(reservation => {
                const month = new Date(reservation.date).toLocaleString('fr-FR', { month: 'long', year: 'numeric' });
                if (!grouped[month]) grouped[month] = [];
                grouped[month].push(reservation);
            });

            return grouped;
        },
        paginatedReservations() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            return this.filteredReservations.slice(start, start + this.itemsPerPage);
        },
        totalPages() {
            return Math.ceil(this.filteredReservations.length / this.itemsPerPage);
        }
    },
    methods: {
        async fetchAllReservations() {
            try {
                const response = await fetch('https://api.splashauto-bm.fr/reservations', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' }
                });
                const data = await response.json();
                this.reservations = data;
            } catch (error) {
                console.error('Erreur lors de la récupération des rendez-vous :', error);
            }
        },
        formatExtras(extras) {
            try {
                return extras && extras !== "[]" ? JSON.parse(extras).join(', ') : 'Aucun extra sélectionné';
            } catch (e) {
                console.error('Erreur lors de la conversion des extras :', e);
                return 'Aucun extra sélectionné';
            }
        },
        formatDate(dateString) {
            return new Date(dateString).toLocaleDateString('fr-FR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
        },
        combineTimeSlots(reservation) {
            let timeSlot = reservation.time_slot; // Le premier créneau
            let timeSlots = reservation.time_slots ? JSON.parse(reservation.time_slots) : []; // Les créneaux supplémentaires

            if (timeSlots.length === 0) {
                return timeSlot; // Si aucun créneau supplémentaire, retourner le créneau initial
            }

            // Récupérer le créneau de début et le créneau de fin
            let startTime = timeSlot.split(' - ')[0]; // Heure de début du créneau initial
            let endTime = timeSlots[timeSlots.length - 1].split(' - ')[1]; // Heure de fin du dernier créneau supplémentaire

            // Combiner le créneau initial et le dernier créneau supplémentaire en une plage horaire
            return `${startTime} - ${endTime}`;
        },
        formatAmount(reservation) {
            try {
                const parsedAmount = JSON.parse(reservation.amount);
                if (Array.isArray(parsedAmount) && parsedAmount.length > 0) {
                    let amountToDisplay = parsedAmount[0];
                    if (amountToDisplay === "Gratuit" || amountToDisplay === "Sur devis") return amountToDisplay;

                    const scheduledTime = new Date(reservation.date + ' ' + reservation.time_slot.split(' - ')[0]);
                    const adjustedTime = new Date(scheduledTime.getTime() + 15 * 60000);
                    if (new Date() <= adjustedTime) amountToDisplay -= 15;

                    return `${amountToDisplay.toFixed(2)}€`;
                }
            } catch (e) {
                console.error('Erreur lors de la conversion du montant :', e);
            }
            return 'À calculer';
        },
        calculateOriginalAmount(reservation) {
            try {
                const parsedAmount = JSON.parse(reservation.amount);
                return Array.isArray(parsedAmount) && parsedAmount.length > 0 ? `${parsedAmount[0].toFixed(2)}€` : 'N/A';
            } catch (e) {
                console.error('Erreur lors de la récupération du montant initial :', e);
                return 'N/A';
            }
        },
        getVehicleTypeName(vehicleTypeId) {
            const vehicleTypes = { 1: 'Citadine', 2: 'Berline/Compact', 3: '4x4/Familiale/Break', 4: 'Van/Pick Up' };
            return vehicleTypes[vehicleTypeId] || 'Type inconnu';
        },
        isPastReservation(reservation) {
            if (!reservation.date || !reservation.time_slot) return false;
            try {
                const reservationDateTime = new Date(reservation.date + ' ' + reservation.time_slot.split(' - ')[0]);
                return new Date() > reservationDateTime;
            } catch (e) {
                console.error('Error parsing date or time_slot:', e);
                return false;
            }
        },
        shouldShowInfoMessage(reservation) {
            return this.isPastReservation(reservation) === false && this.formatAmount(reservation) !== 'Gratuit' && this.formatAmount(reservation) !== 'Sur devis';
        },
        generateFileUrl(filePath) {
            const baseUrl = 'https://api.splashauto-bm.fr/';
            let cleanedFilePath = filePath.replace(/^.*api\.splashauto-bm\.fr\//, '');
            return baseUrl + cleanedFilePath.replace(/^\/+/, '');
        },
        splitUrls(urls) {
            return urls.split(/[,; ]+/).filter(url => url);
        },
        prevPage() {
            if (this.currentPage > 1) this.currentPage--;
        },
        nextPage() {
            if (this.currentPage < this.totalPages) this.currentPage++;
        }
    },
    created() {
        this.fetchAllReservations();
    }
};
</script>

<style scoped>
.appointmentsViewer {
    padding: 2em;
    font-size: 1em;
}

.appointmentsViewer .item {
    padding: 1em;
    border-bottom: 1px solid var(--grey-color);
    display: flex;
    flex-direction: column;
}

.appointmentsViewer input[type="text"],
.appointmentsViewer input[type="email"],
.appointmentsViewer input[type="tel"],
.appointmentsViewer input[type="password"] {
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
    border-radius: 0px;
    transition: border-color 0.3s ease;
}

.appointmentsViewer input[type="text"]:focus,
.appointmentsViewer input[type="email"]:focus,
.appointmentsViewer input[type="tel"]:focus,
.appointmentsViewer input[type="password"]:focus {
    border-color: var(--yellow-color);
    outline: none;
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.appointmentsViewer h2 {
    text-transform: uppercase;
}

.description {
    text-transform: uppercase;
    font-size: 1.4em;
}

.appointmentsViewer ul {
    text-transform: uppercase;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.reservation-item {
    border-bottom: 1px solid var(--grey-color);
    padding: 10px;
}

.reservation-item p {
    margin: 5px 0;
}

.appointmentsViewer .pagination {
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 1em;
    gap: 1.5em;
    align-items: center;
    text-transform: uppercase;
}

.appointmentsViewer .pagination button {
    padding: 0.5em 1em;
    background-color: var(--white-color);
    color: var(--black-color);
    cursor: pointer;
}

.appointmentsViewer .pagination button:hover {
    background-color: var(--black-color);
    color: var(--white-color);
}

.appointmentsViewer .pagination button:disabled {
    background-color: var(--grey-color);
    color: var(--white-color);
    cursor: not-allowed;
}

.appointmentsViewer .mobile {
    display: none;
}

.info {
    color: var(--grey-color);
    font-style: italic;
}

@media (max-width: 1024px) {

    .appointmentsViewer {
        padding: 1.5em;
    }

    .appointmentsViewer h2 {
        font-size: 1.4em;
    }

    .description {
        text-transform: uppercase;
        font-size: initial;
    }

    .appointmentsViewer ul {
        font-size: medium;
    }

    .appointmentsViewer .pagination {
        font-size: medium;
    }
}

@media (max-width: 768px) {
    .appointmentsViewer {
        padding: 1em;
    }

    .appointmentsViewer h2 {
        font-size: 1.2em;
    }

    .appointmentsViewer ul {
        font-size: smaller;
    }

    .appointmentsViewer .pagination {
        justify-content: center;
        gap: 0.75rem;
        padding: .5em;
        font-size: smaller;
    }

    .appointmentsViewer .pagination button {
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .appointmentsViewer .pagination ion-icon {
        margin: 0;
    }

    .appointmentsViewer .desktop {
        display: none;
    }

    .appointmentsViewer .mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .description {
        font-size: smaller;
    }

    .appointmentsViewer {
        padding: 0.5em;
    }

    .appointmentsViewer h2 {
        font-size: 1em;
    }

    .appointmentsViewer ul p {
        font-size: 0.85em;
    }

    .appointmentsViewer .pagination {
        gap: 1rem;
    }
}
</style>