<template>
    <div class="service-form">
        <h2 class="text-yellow">Choisir un Service</h2>
        <div class="service-buttons">
            <button :class="['service-button', { selected: selectedService === 'Nettoyage' }]"
                @click="selectService('Nettoyage')">
                Nettoyage
            </button>
            <button :class="['service-button', { selected: selectedService === 'Polissage' }]"
                @click="selectService('Polissage')">
                Polissage
            </button>
            <button :class="['service-button', { selected: selectedService === 'Remplacement Pare-Brise' }]"
                @click="selectService('Remplacement Pare-Brise')">
                Remplacement Pare-Brise
            </button>
            <button :class="['service-button', { selected: selectedService === 'Extra' }]"
                @click="selectService('Extra')">
                Extra
            </button>
        </div>
        <div class="form-navigation" v-if="selectedService">
            <button class="next-button" @click="nextStep">Suivant</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        bookingData: Object,
    },
    data() {
        return {
            selectedService: this.bookingData.service || '',
        };
    },
    methods: {
        selectService(service) {
            this.selectedService = service;
            this.$emit('update:bookingData', { service: this.selectedService });
        },
        nextStep() {
            this.$emit('next');
        },
    },
};
</script>

<style scoped>
.service-form {
    width: 100%;
}

.service-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: .5rem;
    margin-bottom: 1rem;
}

.service-button.selected {
    background-color: var(--yellow-color);
    color: var(--white-color);
    border: 2px solid var(--yellow-color);
}

h2 {
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

h3 {
    width: fit-content;
    margin-bottom: 1rem;
}

button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

button:hover {
    background-color: var(--white-color);
    color: var(--black-color);
    border: 2px solid var(--black-color);
}
</style>