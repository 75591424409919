<template>
    <div class="home">
        <section id="hero">
            <div class="video-container" ref="videoContainer">
                <video ref="videoElement" autoplay loop muted playsinline preload="auto">
                    <source :src="videoSrc" type="video/mp4">
                    Votre navigateur ne prend pas en charge la balise vidéo.
                </video>
            </div>
            <h1>Si la qualité n’a pas de prix, la satisfaction du client doit être à la hauteur.</h1>
            <button @click="scrollToServices">Découvrez nos services</button>
        </section>

        <section id="services">
            <h2>Nettoyage</h2>
        </section>
        <div class="effect-container"></div>

        <section class="washing-formulas">
            <div class="washing-formulas-container">
                <ServiceCard v-for="(service, index) in washingServices" :key="index" :title="service.title"
                    :price="service.price" :details="service.details" :background-class="service.backgroundClass"
                    :is-active="activeIndex === index" @toggleDetails="setActiveCard(index)" />
            </div>
        </section>

        <section id="services-replicated">
            <h2>Polissage Céramique</h2>
        </section>
        <div class="effect-container-replicated"></div>
        <div class="effect-container"></div>

        <section class="polishing-rates">
            <div class="polishing-rates-container">
                <ServiceCard v-for="(service, index) in services" :key="index + washingServices.length"
                    :title="service.title" :price="service.price" :details="service.details"
                    :background-class="service.backgroundClass"
                    :is-active="activeIndex === index + washingServices.length"
                    @toggleDetails="setActiveCard(index + washingServices.length)" />
            </div>
        </section>

        <section id="services-replicated">
            <h2>Pare-brise</h2>
        </section>
        <div class="effect-container-replicated"></div>
        <div class="effect-container"></div>

        <section class="windshield-rates">
            <div class="windshield-rates-container">
                <ServiceCard v-for="(service, index) in windshield"
                    :key="index + washingServices.length + services.length" :title="service.title"
                    :price="service.price" :details="service.details" :background-class="service.backgroundClass"
                    :is-active="activeIndex === index + washingServices.length + services.length"
                    @toggleDetails="setActiveCard(index + washingServices.length + services.length)" />
            </div>
        </section>

        <section id="services-replicated">
            <h2>professionnels</h2>
        </section>
        <div class="effect-container-replicated"></div>
        <div class="effect-container"></div>

        <section class="professional-message">
            <div class="professional-rates-container">
                <ServiceCard title="Services pour Professionnels" price="" :details="[
                    'Nous offrons des services sur mesure pour les professionnels.',
                    'Veuillez envoyer un e-mail à splashautobm60@gmail.com pour connaître la grille tarifaire.']"
                    background-class="background-professionnels" :is-active="true" :is-professional="true" />
            </div>
        </section>

    </div>
</template>

<script>
import ServiceCard from '@/components/ServiceCard.vue';

export default {
    components: { ServiceCard },
    data() {
        const context = require.context('@/assets/videos', false, /\.mp4$/);
        const videos = context.keys().map(key => context(key));
        return {
            videos,
            currentVideoIndex: 0,
            activeIndex: null,
            services: [
                {
                    title: "Formule 1",
                    price: "139.90€",
                    details: [
                        'Polissage',
                        'Céramique'
                    ],
                    fullWidth: false,
                    backgroundClass: 'background-1'
                },
                {
                    title: "Formule 2",
                    price: "189.90€",
                    details: [
                        'Formule 1',
                        '+',
                        'Nettoyage à vapeur extérieur',
                        'Package auto'
                    ],
                    fullWidth: false,
                    backgroundClass: 'background-2'
                },
                {
                    title: "Formule 3",
                    price: "249.90€",
                    details: [
                        'Formule 2',
                        '+',
                        'Rénovation des phares',
                        'Package BM'
                    ],
                    fullWidth: true,
                    backgroundClass: 'background-3'
                }
            ],
            washingServices: [
                {
                    title: "PACKAGE SPLASH",
                    price: "39.90€",
                    details: [
                        'Aspiration habitacle',
                        'Nettoyage des vitres intérieures',
                        'Nettoyage et finition des plastiques'
                    ],
                    fullWidth: false,
                    backgroundClass: 'background-4'
                },
                {
                    title: "PACKAGE AUTO",
                    price: "69.90€",
                    details: [
                        'PACKAGE SPLASH',
                        '+',
                        'Aspiration du coffre',
                        'Désinfection véhicule',
                        'Shampouinage des tapis'
                    ],
                    fullWidth: false,
                    backgroundClass: 'background-5'
                },
                {
                    title: "PACKAGE BM",
                    price: "89.90€",
                    details: [
                        'PACKAGE AUTO',
                        '+',
                        'Rénovation plastiques',
                        'Shampouinage des sièges'
                    ],
                    fullWidth: true,
                    backgroundClass: 'background-6'
                }
            ],
            windshield: [
                {
                    title: "Remplacement de Pare-Brise",
                    price: "Offre spéciale",
                    details: [
                        'Service réservé aux assurés brise glace. Sans cette couverture, service indisponible.',
                        'Franchise offerte',
                        'Carte cadeau au choix (Amazon, Airbnb, Total, etc.) ou possibilité d\'obtenir une Formule 1 à 139.90€ + un package auto !',
                        'Multimédia inclus',
                        'Système de parrainage : 50€ offerts pour chaque personne référée qui remplace son pare-brise chez nous'
                    ],
                    fullWidth: true,
                    backgroundClass: 'background-7'
                }
            ],
            professionals: {
                title: "Services pour Professionnels",
                price: "",
                details: [
                    'Nous offrons des services sur mesure pour les professionnels.',
                    'Veuillez envoyer un e-mail à splashautobm60@gmail.com pour connaître la grille tarifaire.'
                ],
                backgroundClass: 'background-professionnels'
            },
            scrollTimeout: null
        };
    },
    computed: {
        videoSrc() {
            return this.videos[this.currentVideoIndex];
        }
    },
    mounted() {
        this.setupVideo();
        window.addEventListener('scroll', this.debounceScroll);
    },
    beforeUnmount() {
        window.removeEventListener('scroll', this.debounceScroll);
        const videoElement = this.$refs.videoElement;
        if (videoElement) {
            videoElement.removeEventListener('ended', this.handleVideoEnd);
            videoElement.removeEventListener('error', this.handleVideoError);
        }
    },
    methods: {
        scrollToServices() {
            const element = document.getElementById('services');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            } else {
                console.log('Element with ID "services" not found');
            }
        },
        setupVideo() {
            const videoElement = this.$refs.videoElement;
            const container = this.$refs.videoContainer;
            if (videoElement && container) {
                container.style.opacity = 0;
                videoElement.src = this.videoSrc;
                videoElement.load();
                videoElement.play().catch(() => console.error('Play failed'));
                videoElement.oncanplay = () => container.style.opacity = 1;
            }
        },
        handleVideoEnd() {
            this.currentVideoIndex = (this.currentVideoIndex + 1) % this.videos.length;
            this.fadeOutAndIn();
        },
        fadeOutAndIn() {
            const container = this.$refs.videoContainer;
            if (container) {
                container.style.opacity = 0;
                setTimeout(() => this.setupVideo(), 1000);
            }
        },
        debounceScroll() {
            clearTimeout(this.scrollTimeout);
            this.scrollTimeout = setTimeout(() => {
            }, 200);
        },
        setActiveCard(index) {
            this.activeIndex = this.activeIndex === index ? null : index;
        }
    }
};
</script>

<style>
#hero {
    position: relative;
    width: 100%;
    padding: 18rem 8rem;
    color: var(--white-color);
    font-size: larger;
    overflow: hidden;
    z-index: 1;
}

#hero::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/imgs/pexels-albinberlin-919073.jpg');
    background-size: cover;
    background-position: center;
    filter: blur(0.5rem);
    z-index: 1;
}

#hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, var(--black-color-50) 25%, var(--yellow-color) 100%);
    z-index: 2;
}

.video-container {
    position: absolute;
    top: -2.19rem;
    left: 60%;
    transform: translateX(-50%);
    width: 55.5%;
    z-index: 2;
    clip-path: inset(0 20%);
    opacity: 0;
    transition: opacity 1s ease-in-out;
}

.video-container video {
    width: 100%;
    height: auto;
    display: block;
}

#hero h1 {
    max-width: 25rem;
    text-transform: uppercase;
    position: relative;
    z-index: 4;
    font-size: 2.5rem;
}

#hero button {
    position: relative;
    z-index: 4;
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
}

#services {
    text-align: center;
    position: relative;
    top: -0.125rem;
}

.effect-container {
    position: relative;
    height: 1rem;
    z-index: 2;
}

.effect-container::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 100%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.effect-container::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: calc(20% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--yellow-color);
    transform: rotate(45deg);
    z-index: 0;
}

#services h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--yellow-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 3;
}

.services p {
    padding: 2rem;
}

.polishing-rates {
    position: relative;
    z-index: 0;
}

.polishing-rates-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -1.25rem;
}

.windshield-rates {
    position: relative;
    z-index: 0;
}

.windshield-rates-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -1.25rem;
}

.professional-message {
    position: relative;
    z-index: 0;
}

.professional-rates-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -1.25rem;
}

.services-replicated {
    text-align: center;
    position: relative;
}

.effect-container-replicated {
    position: relative;
    top: -2.875rem;
    z-index: 2;
}

.effect-container-replicated::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.effect-container-replicated::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--yellow-color);
    transform: rotate(45deg);
    z-index: 0;
}

#services-replicated h2 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background-color: var(--yellow-color);
    text-transform: uppercase;
    font-size: 2.5rem;
    margin: 0;
    position: relative;
    z-index: 3;
}

/* Washing Formulas Section */
.washing-formulas {
    position: relative;
    z-index: 0;
}

.washing-formulas-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -1.25rem;
}

/* Splash Section */
.effect-container-splash {
    position: relative;
    height: 1rem;
    z-index: 1;
}

.effect-container-splash::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 20%;
    width: 100%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.effect-container-splash::after {
    content: "";
    position: absolute;
    bottom: 6px;
    left: calc(20% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--yellow-color);
    transform: rotate(45deg);
    z-index: 0;
}

.effect-container-top-splash {
    position: relative;
    top: -4rem;
    z-index: 2;
}

.effect-container-top-splash::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.effect-container-top-splash::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: calc(80% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--yellow-color);
    transform: rotate(45deg);
    z-index: 0;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    #hero {
        padding: 15rem 4rem;
    }

    #hero h1 {
        font-size: 2rem;
    }

    #hero button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .video-container {
        width: 50%;
        top: 0;
    }

    #services h2 {
        font-size: 2rem;
    }

    #services-replicated h2 {
        font-size: 2rem;
    }
}

@media (max-width: 768px) {
    #hero {
        padding: 10rem 2rem;
    }

    #hero::before {
        background-image: none;
    }

    #hero h1 {
        font-size: 2rem;
        max-width: 15rem;
    }

    #hero button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    #services h2 {
        font-size: 1.4rem;
    }

    #services-replicated h2 {
        font-size: 1.4rem;
    }

    .video-container {
        width: 100%;
        left: 50%;
        clip-path: none;
    }
}

@media (max-width: 480px) {
    #hero {
        padding: 8rem 1rem;
    }

    #hero::before {
        display: none;
    }

    #hero h1 {
        font-size: 1.5rem;
    }

    #hero button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    #services h2 {
        font-size: 1.5rem;
    }

    .video-container {
        width: 100%;
        top: 0;
    }
}
</style>
