<template>
    <div class="login-register">
        <div class="tabs">
            <button @click="toggleForm('login')" :class="{ active: activeForm === 'login' }">Connexion</button>
            <button @click="toggleForm('register')" :class="{ active: activeForm === 'register' }">Inscription</button>
        </div>

        <div v-if="activeForm === 'login'" class="form">
            <h2>Connexion</h2>
            <form @submit.prevent="handleLogin">
                <div class="form-group">
                    <label for="login-email">Adresse Email</label>
                    <input type="email" id="login-email" v-model="loginEmail" required autocomplete="email" />
                </div>
                <div class="form-group">
                    <label for="login-password">Mot de passe</label>
                    <input type="password" id="login-password" v-model="loginPassword" required
                        autocomplete="current-password" />
                </div>
                <button type="submit">Connexion</button>
                <button type="button" @click="showForgotPasswordForm">Mot de passe oublié ?</button>
            </form>
        </div>

        <div v-else-if="activeForm === 'register'" class="form">
            <h2>Inscription</h2>
            <form @submit.prevent="handleRegister">
                <div class="form-group">
                    <label for="register-first-name">Prénom</label>
                    <input type="text" id="register-first-name" v-model="registerFirstname" required
                        autocomplete="given-name" />
                </div>
                <div class="form-group">
                    <label for="register-last-name">Nom de Famille</label>
                    <input type="text" id="register-last-name" v-model="registerLastname" required
                        autocomplete="family-name" />
                </div>
                <div class="form-group">
                    <label for="register-phone">Numéro de Téléphone</label>
                    <input type="tel" id="register-phone" v-model="registerPhone" required
                        pattern="^(\+33|0)[1-9](\d{2}){4}$"
                        title="Le numéro de téléphone doit commencer par 0 ou +33 suivi de 9 chiffres."
                        autocomplete="tel" />
                </div>
                <div class="form-group">
                    <label for="register-email">Adresse Email</label>
                    <input type="email" id="register-email" v-model="registerEmail" required autocomplete="email" />
                </div>
                <div class="form-group">
                    <label for="register-street">Rue</label>
                    <input type="text" id="register-street" v-model="registerStreet" required
                        autocomplete="street-address" />
                </div>
                <div class="form-group">
                    <label for="register-city">Ville</label>
                    <input type="text" id="register-city" v-model="registerCity" required
                        autocomplete="address-level2" />
                </div>
                <div class="form-group">
                    <label for="register-postal-code">Code Postal</label>
                    <input type="text" id="register-postal-code" v-model="registerPostalCode" required
                        autocomplete="postal-code" />
                </div>
                <div class="form-group">
                    <label for="register-country">Pays</label>
                    <input type="text" id="register-country" v-model="registerCountry" required
                        autocomplete="country" />
                </div>
                <div class="form-group">
                    <label for="register-password">Mot de passe</label>
                    <input type="password" id="register-password" v-model="registerPassword" required
                        autocomplete="new-password" />
                </div>
                <div class="form-group">
                    <label for="confirm-password">Confirmation du Mot de Passe</label>
                    <input type="password" id="confirm-password" v-model="confirmPassword" required
                        autocomplete="new-password" />
                </div>
                <button type="submit">Inscription</button>
            </form>
        </div>

        <div v-if="showResetPasswordForm" class="form">
            <h2>Réinitialiser le Mot de Passe</h2>
            <form @submit.prevent="handleForgotPassword">
                <div class="form-group">
                    <label for="reset-email">Adresse Email</label>
                    <input type="email" id="reset-email" v-model="resetEmail" required autocomplete="email" />
                </div>
                <button type="submit">Envoyer le lien de réinitialisation</button>
                <button type="button" @click="cancelForgotPassword">Annuler</button>
            </form>
        </div>

        <div v-if="message" class="message" :class="message.type">{{ message.text }}</div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { useRouter } from 'vue-router';

export default {
    name: 'LoginRegister',
    data() {
        return {
            activeForm: 'login',
            loginEmail: '',
            loginPassword: '',
            registerFirstname: '',
            registerLastname: '',
            registerPhone: '',
            registerEmail: '',
            registerPassword: '',
            confirmPassword: '',
            registerStreet: '',
            registerCity: '',
            registerPostalCode: '',
            registerCountry: '',
            resetEmail: '',
            message: null,
            showResetPasswordForm: false
        };
    },
    methods: {
        ...mapActions(['login', 'register', 'fetchUser', 'forgotPassword']),
        toggleForm(formType) {
            this.activeForm = formType;
            this.message = null;
            this.showResetPasswordForm = false;
        },
        showForgotPasswordForm() {
            this.activeForm = 'login';
            this.showResetPasswordForm = true;
        },
        cancelForgotPassword() {
            this.showResetPasswordForm = false;
        },
        async handleLogin() {
            const result = await this.login({
                email: this.loginEmail,
                password: this.loginPassword
            });

            if (result.success) {
                await this.fetchUser();
                this.message = { type: 'success', text: 'Connexion réussie !' };
                setTimeout(() => this.$router.push('/'), 1000);
            } else {
                this.message = { type: 'error', text: result.message };
            }
        },
        async handleRegister() {
            if (this.registerPassword !== this.confirmPassword) {
                this.message = { type: 'error', text: 'Les mots de passe ne correspondent pas' };
                return;
            }
            const result = await this.register({
                firstname: this.registerFirstname,
                lastname: this.registerLastname,
                phone: this.registerPhone,
                email: this.registerEmail,
                password: this.registerPassword,
                address: {
                    street: this.registerStreet,
                    city: this.registerCity,
                    postalCode: this.registerPostalCode,
                    country: this.registerCountry
                }
            });

            if (result.success) {
                this.message = { type: 'success', text: 'Inscription réussie !' };
                setTimeout(() => this.$router.push('/'), 1000);
            } else {
                this.message = { type: 'error', text: result.message };
            }
        },
        async handleForgotPassword() {
            try {
                const response = await this.$store.dispatch('forgotPassword', this.resetEmail);

                if (response && response.success) {
                    this.message = { type: 'success', text: response.message || 'Un lien de réinitialisation a été envoyé à votre adresse e-mail.' };
                    this.showResetPasswordForm = false;
                } else {
                    this.message = { type: 'error', text: response?.message || 'Erreur lors de l\'envoi du lien de réinitialisation.' };
                }
            } catch (error) {
                console.error('Erreur lors de la réinitialisation du mot de passe:', error);
                this.message = { type: 'error', text: 'Erreur lors de la réinitialisation du mot de passe.' };
            }
        },
    },
    mounted() {
        const isAuthenticated = this.$store.state.isAuthenticated;
        if (isAuthenticated && this.$route.path === '/login-register') {
            this.$router.push('/profile');
        }
    }
};
</script>

<style>
.login-register {
    padding: 2em;
    text-transform: uppercase;
}

.login-register h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
    color: var(--yellow-color);
}

.login-register .tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    width: 100%;
    gap: 1rem;
}

.login-register .tabs button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

.login-register .tabs button.active {
    background-color: var(--yellow-color);
    color: var(--white-color);
    border: 2px solid var(--yellow-color);
}

.login-register .tabs button.active:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
}

.login-register form {
    display: flex;
    flex-direction: column;
}

.login-register label,
.login-register input {
    margin-bottom: .75rem;
    display: block;
}

.login-register form button {
    color: var(--white-color);
    background-color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
    margin-top: .75rem;
    margin-bottom: .75rem;
}

.login-register form button:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
}

.login-register input,
.login-register textarea {
    width: calc(100% - 9px);
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
    border-radius: 0px;
    transition: border-color 0.3s ease;
}

.login-register input:focus,
.login-register textarea:focus {
    border-color: var(--yellow-color);
    outline: none;
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.message {
    padding: 10px 0;
    font-weight: bold;
}

.message.success {
    color: var(--green-color);
}

.message.error {
    color: var(--red-color);
}

@media (max-width: 1024px) {
    .login-register h2 {
        font-size: 2rem;
    }

    .login-register li {
        font-size: 0.875rem;
    }

    .login-register p {
        font-size: 0.75rem;
    }

    .login-register label {
        font-size: 1rem;
    }

    .login-register .tabs button,
    .login-register form button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .message {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {
    .login-register h2 {
        font-size: 1.4rem;
    }

    .login-register li {
        font-size: 0.75rem;
    }

    .login-register p {
        font-size: 0.875rem;
    }

    .login-register label {
        font-size: 0.875rem;
    }

    .login-register .tabs button,
    .login-register form button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    .message {
        font-size: 0.875rem;
    }
}

@media (max-width: 480px) {
    .login-register h2 {
        font-size: 1.2rem;
    }

    .login-register li {
        font-size: 0.875rem;
    }

    .login-register label {
        font-size: 0.75rem;
    }

    .login-register .tabs button,
    .login-register form button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    .message {
        font-size: 0.75rem;
    }
}
</style>
