<template>
    <div class="profile">
        <div class="profile-header" v-if="user">
            <div>
                <h1 class="profile-name">{{ user.firstName || 'Prénom Manquant' }} {{ user.lastName || 'Nom Manquant' }}
                </h1>
                <p class="profile-email">{{ user.email || 'Email Manquant' }}</p>
            </div>
            <div v-if="isAdmin">
                <p class="profile-email">
                    <router-link to="/admin">Admin</router-link>
                </p>
            </div>
        </div>

        <div v-if="!isEditing" class="profile-info">
            <h2>À propos</h2>
            <p v-if="user.firstName"><strong>Prénom:</strong> {{ user.firstName || 'Prénom Manquant' }}</p>
            <p v-if="user.lastName"><strong>Nom de Famille:</strong> {{ user.lastName || 'Nom Manquant' }}</p>
            <p v-if="user.phone"><strong>Numéro de Téléphone:</strong> <a :href="`tel:${user.phone}`">{{ user.phone ||
                'Numéro Manquant' }}</a></p>
            <p v-if="user.email"><strong>Adresse Email:</strong> <a :href="`mailto:${user.email}`">{{ user.email ||
                'Email Manquant' }}</a></p>
            <p><strong>Adresse:</strong> <a :href="generateMapUrl(user.address)" target="_blank">
                    {{ user.address.street || 'Adresse inconnue' }},
                    {{ user.address.city || 'Ville inconnue' }},
                    {{ user.address.postalCode || 'Code postal inconnu' }},
                    {{ user.address.country || 'Pays inconnu' }}
                </a></p>

            <p><strong>Carte Grise:</strong></p>
            <div v-if="user.registrationCertificateUrl">
                <template v-if="isImage(generateFileUrl(user.registrationCertificateUrl))">
                    <img :src="generateFileUrl(user.registrationCertificateUrl)" alt="Carte Grise"
                        class="uploaded-file-preview" />
                </template>
                <template v-else>
                    <a :href="generateFileUrl(user.registrationCertificateUrl)" target="_blank">Voir le fichier</a>
                </template>
            </div>
            <div v-else>
                Aucun fichier disponible
            </div>

            <p><strong>Contrôle Technique:</strong></p>
            <div v-if="user.technicalControlUrl">
                <template v-if="isImage(generateFileUrl(user.technicalControlUrl))">
                    <img :src="generateFileUrl(user.technicalControlUrl)" alt="Contrôle Technique"
                        class="uploaded-file-preview" />
                </template>
                <template v-else>
                    <a :href="generateFileUrl(user.technicalControlUrl)" target="_blank">Voir le fichier</a>
                </template>
            </div>
            <div v-else>
                Aucun fichier disponible
            </div>

            <p><strong>Photos du Véhicule:</strong></p>
            <div v-if="user.vehiclePhotos && user.vehiclePhotos.length">
                <div v-if="user.vehiclePhotos.length === 1">
                    <template v-if="isImage(generateFileUrl(user.vehiclePhotos[0].url))">
                        <img :src="generateFileUrl(user.vehiclePhotos[0].url)" alt="Photo du véhicule"
                            class="uploaded-file-preview" />
                    </template>
                    <template v-else>
                        <a :href="generateFileUrl(user.vehiclePhotos[0].url)" target="_blank">Voir le fichier</a>
                    </template>
                </div>

                <div v-else>
                    <div class="tabs" v-if="hasBothImageAndPdf">
                        <button @click="activeTab = 'images'"
                            :class="{ active: activeTab === 'images' }">Images</button>
                        <button @click="activeTab = 'pdfs'" :class="{ active: activeTab === 'pdfs' }">PDFs</button>
                    </div>

                    <div v-if="activeTab === 'images' || !hasBothImageAndPdf" class="vehicle-photos-list">
                        <ul>
                            <li v-for="(photo, index) in filteredVehiclePhotos('image')" :key="index">
                                <img :src="generateFileUrl(photo.url)" :alt="'Photo du véhicule ' + (index + 1)"
                                    class="uploaded-file-preview" />
                            </li>
                        </ul>
                    </div>

                    <div v-if="activeTab === 'pdfs' || !hasBothImageAndPdf" class="vehicle-pdf-list">
                        <ul>
                            <li v-for="(photo, index) in filteredVehiclePhotos('pdf')" :key="index">
                                <a :href="generateFileUrl(photo.url)" target="_blank">Voir le fichier PDF {{ index + 1
                                    }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div v-else>
                Aucune photo disponible
            </div>

            <div class="profile-reservations">
                <h2>Mes Réservations</h2>
                <div v-if="!paginatedReservations || paginatedReservations.length === 0">
                    <p>Aucune réservation trouvée.</p>
                </div>
                <div v-else>
                    <ul>
                        <li v-for="reservation in paginatedReservations" :key="reservation.id" class="reservation-item">
                            <p><strong>Service:</strong> {{ reservation.service || 'Service non disponible' }}</p>
                            <p><strong>Package:</strong> {{ reservation.package || 'Aucun package' }}</p>
                            <p><strong>Extras:</strong>
                                {{ parseExtras(reservation.extras).join(', ') || 'Aucun extra sélectionné' }}</p>
                            <p><strong>Type de Véhicule:</strong> {{ getVehicleTypeName(reservation.vehicle_type_id) }}
                            </p>
                            <p><strong>Date:</strong>
                                {{ formatDateToLetters(reservation.date) || 'Date non disponible' }}</p>
                            <p><strong>Créneau horaire:</strong>
                                {{ combineTimeSlots(reservation) }}</p>
                            <p><strong>Montant:</strong>
                                {{ shouldShowOriginalAmount(reservation) ? calculateOriginalAmount(reservation) :
                                    calculateAmount(reservation) }}
                            </p>
                            <p v-if="shouldShowUserInfoMessage(reservation) && !isPastReservation(reservation) && reservation.status !== 'cancelled'"
                                class="cancellation-message">
                                Le montant se met à jour automatiquement en fonction de votre heure d'arrivée.
                                Si vous arrivez à l'heure ou avec moins de 15 minutes de retard, une réduction de 15€
                                est appliquée.
                                En revanche, si vous avez plus de 15 minutes de retard, le montant initial sera dû sans
                                aucune
                                réduction
                                ({{ calculateOriginalAmount(reservation) }}).
                            </p>
                            <p><strong>Statut: </strong>
                                <span v-if="isPastReservation(reservation)" class="status-passed">Déjà passé</span>
                                <span v-else :class="getStatusClass(reservation.status)">
                                    {{ getStatusLabel(reservation.status) }}
                                </span>
                            </p>
                            <!-- Show button or cancellation message based on status -->
                            <div v-if="reservation.status === 'cancelled'">
                                <p class="cancellation-message">La réservation a été annulée.</p>
                            </div>
                            <div v-else-if="!isPastReservation(reservation)">
                                <button @click="requestCancellation(reservation.id)">Annuler la réservation</button>
                            </div>
                        </li>
                    </ul>

                    <!-- Pagination Controls -->
                    <div class="pagination-controls">
                        <button @click="prevPage" :disabled="currentPage === 1">
                            <span class="desktop">Précédent</span>
                            <span class="mobile"><ion-icon name="chevron-back"></ion-icon></span>
                        </button>
                        <span>Page {{ currentPage }} sur {{ totalPages }}</span>
                        <button @click="nextPage" :disabled="currentPage === totalPages">
                            <span class="desktop">Suivant</span>
                            <span class="mobile"><ion-icon name="chevron-forward"></ion-icon></span>
                        </button>
                    </div>
                </div>

            </div>

        </div>

        <div v-if="isEditing && editMode === 'text'" class="profile-edit-form">
            <h2>Modifier les informations du profil</h2>
            <form @submit.prevent="saveTextInfo">
                <div class="form-group">
                    <label for="first-name">Prénom</label>
                    <input type="text" id="first-name" name="first-name" v-model="editUser.firstName"
                        autocomplete="given-name" required />
                </div>
                <div class="form-group">
                    <label for="last-name">Nom de Famille</label>
                    <input type="text" id="last-name" name="last-name" v-model="editUser.lastName"
                        autocomplete="family-name" required />
                </div>
                <div class="form-group">
                    <label for="phone">Numéro de Téléphone</label>
                    <input type="tel" id="phone" name="phone" v-model="editUser.phone"
                        pattern="^(\+33|0)[1-9](\d{2}){4}$"
                        title="Le numéro de téléphone doit commencer par 0 ou +33 suivi de 9 chiffres."
                        autocomplete="tel" required />
                </div>
                <div class="form-group">
                    <label for="email">Adresse Email</label>
                    <input type="email" id="email" name="email" v-model="editUser.email" autocomplete="email"
                        required />
                </div>

                <div class="form-group">
                    <label for="street">Rue</label>
                    <input type="text" id="street" name="street" v-model="editUser.address.street"
                        autocomplete="street-address" required />
                </div>

                <div class="form-group">
                    <label for="city">Ville</label>
                    <input type="text" id="city" name="city" v-model="editUser.address.city"
                        autocomplete="address-level2" required />
                </div>

                <div class="form-group">
                    <label for="postal-code">Code Postal</label>
                    <input type="text" id="postal-code" name="postal-code" v-model="editUser.address.postalCode"
                        autocomplete="postal-code" required />
                </div>

                <div class="form-group">
                    <label for="country">Pays</label>
                    <input type="text" id="country" name="country" v-model="editUser.address.country"
                        autocomplete="country" required />
                </div>

                <div class="form-group">
                    <label>
                        <input type="checkbox" id="regenerate-password" name="regenerate-password"
                            v-model="regeneratePassword" />
                        Modifier le mot de passe
                    </label>
                </div>
                <div v-if="regeneratePassword" class="form-group">
                    <label for="new-password">Nouveau mot de passe</label>
                    <input type="password" id="new-password" name="new-password" v-model="newPassword"
                        autocomplete="new-password" required />
                </div>
                <div v-if="regeneratePassword" class="form-group">
                    <label for="confirm-new-password">Confirmer le nouveau mot de passe</label>
                    <input type="password" id="confirm-new-password" name="confirm-new-password"
                        v-model="confirmNewPassword" autocomplete="new-password" required />
                </div>
                <div v-if="!regeneratePassword" class="form-group">
                    <label for="password">Mot de passe</label>
                    <input type="password" id="password" name="password" v-model="editUser.password"
                        autocomplete="current-password" required />
                </div>
                <div v-if="!regeneratePassword" class="form-group">
                    <label for="confirm-password">Confirmation du mot de passe</label>
                    <input type="password" id="confirm-password" name="confirm-password" v-model="confirmPassword"
                        autocomplete="current-password" required />
                </div>
                <div class="profile-actions">
                    <div class="action-row">
                        <button type="submit" class="button">Enregistrer les informations</button>
                        <button type="button" @click="cancelEdit">Annuler</button>
                    </div>
                    <div v-if="message" class="message" :class="message.type">{{ message.text }}</div>
                </div>
            </form>
        </div>

        <div v-if="isEditing && editMode === 'files'" class="profile-edit-form">
            <h2>Modifier les fichiers</h2>
            <form @submit.prevent="saveFiles">
                <div class="form-group">
                    <label for="carte-grise">Carte Grise (JPG, PNG, PDF - max 2Mo)</label>
                    <input type="file" @change="handleCarteGriseOrControleTechniqueChange('carteGrise', $event)"
                        id="carte-grise" />
                    <p v-if="editUser.carteGriseFileName">Fichier sélectionné: {{ editUser.carteGriseFileName }}</p>
                </div>
                <div class="form-group">
                    <label for="controle-technique">Contrôle Technique (JPG, PNG, PDF - max 2Mo)</label>
                    <input type="file" @change="handleCarteGriseOrControleTechniqueChange('controleTechnique', $event)"
                        id="controle-technique" />
                    <p v-if="editUser.controleTechniqueFileName">Fichier sélectionné: {{
                        editUser.controleTechniqueFileName }}
                    </p>
                </div>
                <div class="form-group">
                    <label for="vehicle-photos">Photos du Véhicule (JPG, PNG, PDF - max 2Mo par fichier, jusqu'à 5
                        fichiers)</label>
                    <input type="file" multiple @change="handleVehiclePhotosChange" id="vehicle-photos" />
                    <p v-if="editUser.vehiclePhotosFileNames.length">
                        Fichiers sélectionnés: {{ editUser.vehiclePhotosFileNames.join(', ') }}
                    </p>
                </div>
                <div class="profile-actions">
                    <div class="action-row">
                        <button type="submit" class="button">Enregistrer les fichiers</button>
                        <button type="button" @click="cancelEdit">Annuler</button>
                    </div>
                    <div v-if="message" class="message" :class="message.type">{{ message.text }}</div>
                </div>
            </form>
        </div>

        <div class="profile-actions">
            <div class="action-row">
                <button @click="startEdit('text')" v-if="!isEditing">Modifier le profil</button>
                <button @click="startEdit('files')" v-if="!isEditing">Modifier les fichiers</button>
            </div>

            <div class="action-column">
                <button @click="logout" class="button logout">Déconnexion</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    name: 'ProfilePage',
    data() {
        return {
            isEditing: false,
            editMode: 'text',
            activeTab: 'images',
            editUser: {
                firstName: '',
                lastName: '',
                phone: '',
                email: '',
                street: '',
                city: '',
                postalCode: '',
                country: '',
                carteGrise: null,
                controleTechnique: null,
                vehiclePhotos: [],
                carteGriseFileName: '',
                controleTechniqueFileName: '',
                vehiclePhotosFileNames: [],
                password: '',
                reservations: []
            },
            regeneratePassword: false,
            newPassword: '',
            confirmNewPassword: '',
            confirmPassword: '',
            message: null,
            pageReloaded: false,
            vehicleTypes: [
                { id: 1, name: 'Citadine' },
                { id: 2, name: 'Berline/Compact' },
                { id: 3, name: '4x4/Familiale/Break' },
                { id: 4, name: 'Van/Pick Up' }
            ],
            currentPage: 1,       // Current page of pagination
            itemsPerPage: 3,      // Number of reservations per page
        };
    },
    computed: {
        ...mapState(['user', 'reservations', 'isAdmin']),
        hasBothImageAndPdf() {
            const images = this.filteredVehiclePhotos('image');
            const pdfs = this.filteredVehiclePhotos('pdf');
            return images.length > 0 && pdfs.length > 0;
        },
        // Total number of pages based on the number of reservations
        totalPages() {
            return Math.ceil(this.reservations.length / this.itemsPerPage);
        },

        // Get the reservations for the current page
        paginatedReservations() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.reservations.slice(start, end);
        }
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchUserReservations', 'logout']),
        getStatusLabel(status) {
            const labels = {
                completed: 'Complétée',
                pending: 'En attente',
                failed: 'Échouée',
                cancelled: 'Annulée',
            };
            return labels[status] || 'Inconnu';
        },
        getStatusClass(status) {
            return `status-${status}`;
        },
        parseExtras(extras) {
            try {
                return JSON.parse(extras);
            } catch (e) {
                console.error('Erreur lors du parsing des extras:', e);
                return [];
            }
        },
        parseAmount(amount) {
            try {
                const parsedAmount = JSON.parse(amount);
                if (parsedAmount[0] === "Gratuit" || parsedAmount[0] === "Sur devis") {
                    return parsedAmount[0];
                }
                return `${parsedAmount[0]}€`;
            } catch (e) {
                console.error('Erreur lors du parsing du montant:', e);
                return 'N/A';
            }
        },
        shouldShowUserInfoMessage(reservation) {
            const parsedAmount = JSON.parse(reservation.amount);
            return Array.isArray(parsedAmount) && parsedAmount.length > 0 && parsedAmount[0] !== "Gratuit" && parsedAmount[0] !== "Sur devis";
        },
        shouldShowOriginalAmount(reservation) {
            return reservation.status === 'cancelled' || this.isPastReservation(reservation);
        },
        calculateAmount(reservation) {
            try {
                const parsedAmount = JSON.parse(reservation.amount);
                if (Array.isArray(parsedAmount) && parsedAmount.length > 0) {
                    let amountToDisplay = parsedAmount[0];

                    // Si le montant est "Gratuit" ou "Sur devis", on le retourne directement
                    if (amountToDisplay === "Gratuit" || amountToDisplay === "Sur devis") {
                        return amountToDisplay;
                    }

                    // Récupération du créneau horaire de début
                    const scheduledTime = new Date(reservation.date + ' ' + reservation.time_slot.split(' - ')[0]);
                    const adjustedTime = new Date(scheduledTime.getTime() + 15 * 60000); // Ajout de 15 minutes
                    const currentTime = new Date();

                    // Si l'heure actuelle est inférieure ou égale à adjustedTime, appliquer la réduction de 15€
                    if (currentTime <= adjustedTime) {
                        amountToDisplay -= 15;
                    }

                    return amountToDisplay.toFixed(2) + '€';
                }
            } catch (e) {
                console.error('Erreur lors de la conversion du montant :', e);
            }
            return 'À calculer'; // Valeur par défaut si le montant est indisponible
        },
        calculateOriginalAmount(reservation) {
            try {
                const parsedAmount = JSON.parse(reservation.amount);
                if (Array.isArray(parsedAmount) && parsedAmount.length > 0) {
                    return `${parsedAmount[0].toFixed(2)}€`;
                }
            } catch (e) {
                console.error('Erreur lors de la récupération du montant initial :', e);
            }
            return 'N/A'; // Retourne 'N/A' si le montant n'est pas disponible
        },
        getVehicleTypeName(vehicleTypeId) {
            const vehicleType = this.vehicleTypes.find(type => type.id === vehicleTypeId);
            return vehicleType ? vehicleType.name : 'Type de véhicule inconnu';
        },
        startEdit(mode) {
            this.editMode = mode;
            this.editUser = {
                firstName: this.user.firstName || '',
                lastName: this.user.lastName || '',
                phone: this.user.phone || '',
                email: this.user.email || '',
                address: {
                    street: this.user.address?.street || '',
                    city: this.user.address?.city || '',
                    postalCode: this.user.address?.postalCode || '',
                    country: this.user.address?.country || ''
                },
                carteGrise: null,
                controleTechnique: null,
                vehiclePhotos: [],
                carteGriseFileName: this.user.carteGriseUrl ? this.user.carteGriseUrl.split('/').pop() : '',
                controleTechniqueFileName: this.user.controleTechniqueUrl ? this.user.controleTechniqueUrl.split('/').pop() : '',
                vehiclePhotosFileNames: [],
                password: ''
            };
            this.isEditing = true;
        },
        handleCarteGriseOrControleTechniqueChange(field, event) {
            const file = event.target.files[0];
            const allowedTypes = ['jpg', 'jpeg', 'png', 'pdf'];
            const maxSize = 2 * 1024 * 1024;
            const fileExtension = file.name.split('.').pop().toLowerCase();

            if (!allowedTypes.includes(fileExtension) || file.size > maxSize) {
                this.setMessage('error', 'Le fichier doit être de type JPG, PNG, JPEG ou PDF et ne pas dépasser 2 Mo.');
                return;
            }

            this.editUser[field] = file;
            this.editUser[`${field}FileName`] = file.name;
        },
        handleVehiclePhotosChange(event) {
            if (!event.target || !event.target.files) {
                this.setMessage('error', 'Erreur lors de la sélection des fichiers. Veuillez réessayer.');
                return;
            }

            const files = Array.from(event.target.files);
            const allowedTypes = ['jpg', 'jpeg', 'png', 'pdf'];
            const maxSize = 2 * 1024 * 1024;

            const validFiles = files.filter(file => {
                const fileExtension = file.name.split('.').pop().toLowerCase();
                if (!allowedTypes.includes(fileExtension) || file.size > maxSize) {
                    this.setMessage('error', 'Chaque photo doit être de type JPG, PNG, JPEG ou PDF et ne pas dépasser 2 Mo.');
                    return false;
                }
                return true;
            });

            if (validFiles.length > 5) {
                this.setMessage('error', 'Vous ne pouvez sélectionner que jusqu\'à 5 photos du véhicule.');
                return;
            }

            this.editUser.vehiclePhotos = validFiles;
            this.editUser.vehiclePhotosFileNames = validFiles.map(file => file.name);
        },
        async saveTextInfo() {
            let passwordToSend = this.editUser.password;

            if (this.regeneratePassword) {
                if (this.newPassword !== this.confirmNewPassword) {
                    this.setMessage('error', 'Les nouveaux mots de passe ne correspondent pas. Veuillez vérifier et réessayer.');
                    return;
                }
                passwordToSend = this.newPassword;
            } else if (this.editUser.password !== this.confirmPassword) {
                this.setMessage('error', 'Le mot de passe est incorrect. Veuillez vérifier et réessayer.');
                return;
            }

            try {
                const textInfo = {
                    firstname: this.editUser.firstName,
                    lastname: this.editUser.lastName,
                    phone: this.editUser.phone,
                    email: this.editUser.email,
                    address: {
                        street: this.editUser.address?.street || '',
                        city: this.editUser.address?.city || '',
                        postalCode: this.editUser.address?.postalCode || '',
                        country: this.editUser.address?.country || ''
                    },
                    password: passwordToSend
                };

                const response = await fetch('https://api.splashauto-bm.fr/update/text', {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(textInfo),
                    credentials: 'include'
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Erreur lors de la mise à jour des informations textuelles. Veuillez réessayer plus tard.');
                }

                this.isEditing = false;
                this.setMessage('success', 'Les informations du profil ont été mises à jour avec succès.');
            } catch (error) {
                console.error('Erreur lors de l\'enregistrement des informations textuelles:', error);
                this.setMessage('error', 'Erreur lors de l\'enregistrement des informations du profil. Veuillez réessayer.');
            }
        },
        async saveFiles() {
            try {
                const formData = new FormData();

                if (this.editUser.carteGrise) formData.append('registration_certificate', this.editUser.carteGrise);
                if (this.editUser.controleTechnique) formData.append('technical_control_file', this.editUser.controleTechnique);

                this.editUser.vehiclePhotos.forEach(file => formData.append('vehicle_photo_file[]', file));

                const response = await fetch('https://api.splashauto-bm.fr/update/files', {
                    method: 'POST',
                    body: formData,
                    credentials: 'include'
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Erreur lors de la mise à jour des fichiers. Veuillez réessayer plus tard.');
                }

                const updatedFiles = await response.json();
                this.$store.commit('UPDATE_USER_FILES', updatedFiles);

                this.isEditing = false;
                this.setMessage('success', 'Les fichiers ont été mis à jour avec succès.');
            } catch (error) {
                console.error('Erreur lors de l\'enregistrement des fichiers:', error);
                this.setMessage('error', 'Erreur lors de l\'enregistrement des fichiers. Veuillez réessayer.');
            }
        },
        cancelEdit() {
            this.isEditing = false;
        },
        async logout() {
            try {
                await this.$store.dispatch('logout');
                this.handleUserLogout();
            } catch (error) {
                console.error('Erreur lors de la déconnexion:', error);
                this.setMessage('error', 'Erreur de déconnexion. Veuillez réessayer.');
            }
        },
        handleUserLogout() {
            this.$store.commit('CLEAR_USER');
            this.$router.push('/login-register');
        },
        generateFileUrl(filePath) {
            const baseUrl = 'https://api.splashauto-bm.fr/';

            let cleanedFilePath = filePath.replace(/^.*api\.splashauto-bm\.fr\//, '');

            return baseUrl + cleanedFilePath.replace(/^\/+/, '');
        },
        isImage(url) {
            return /\.(jpg|jpeg|png)$/i.test(url);
        },
        filteredVehiclePhotos(type) {
            if (!this.user.vehiclePhotos) return [];
            return this.user.vehiclePhotos.filter(photo =>
                (type === 'image' && this.isImage(this.generateFileUrl(photo.url))) ||
                (type === 'pdf' && !this.isImage(this.generateFileUrl(photo.url)))
            );
        },
        splitUrls(urls) {
            return urls.split(/[,; ]+/).filter(url => url);
        },
        generateMapUrl() {
            const {
                address = {
                    street: 'Adresse inconnue',
                    city: 'Ville inconnue',
                    postalCode: 'Code postal inconnu',
                    country: 'Pays inconnu'
                }
            } = this.user || {};

            const { street, city, postalCode, country } = address;

            return `https://maps.google.com/?q=${encodeURIComponent(`${street}, ${city}, ${postalCode}, ${country}`)}`;
        },
        setMessage(type, text) {
            this.message = { type, text };
            if (type === 'success') {
                setTimeout(() => {
                    this.message = null;
                    this.reloadPage();
                }, 1000);
            } else {
            }
        },
        reloadPage() {
            if (!this.pageReloaded) {
                this.pageReloaded = true;
                location.reload();
            }
        },
        async fetchUserAndReservations() {
            try {
                // Fetch user details
                await this.fetchUser();

                // Fetch reservations for the logged-in user
                await this.fetchUserReservations();
            } catch (error) {
                console.error('Erreur lors de la récupération des données utilisateur ou des réservations:', error);
                this.$store.commit('CLEAR_USER');
            }
        },
        formatDateToLetters(dateString) {
            const date = new Date(dateString);
            const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
            return date.toLocaleDateString('fr-FR', options);
        },
        combineTimeSlots(reservation) {
            let timeSlot = reservation.time_slot; // Le premier créneau
            let timeSlots = reservation.time_slots ? JSON.parse(reservation.time_slots) : []; // Les créneaux supplémentaires

            if (timeSlots.length === 0) {
                return timeSlot; // Si aucun créneau supplémentaire, retourner le créneau initial
            }

            // Récupérer le créneau de début et le créneau de fin
            let startTime = timeSlot.split(' - ')[0]; // Heure de début du créneau initial
            let endTime = timeSlots[timeSlots.length - 1].split(' - ')[1]; // Heure de fin du dernier créneau supplémentaire

            // Combiner le créneau initial et le dernier créneau supplémentaire en une plage horaire
            return `${startTime} - ${endTime}`;
        },
        async requestCancellation(reservationId) {
            const confirmCancellation = confirm("Êtes-vous sûr de vouloir annuler cette réservation ?");

            if (!confirmCancellation) {
                return; // If the user cancels the confirmation dialog, do nothing.
            }

            try {
                await this.$store.dispatch('cancelReservation', reservationId);
                alert('Demande d\'annulation envoyée.');
                this.$store.dispatch('fetchUserReservations'); // Refresh reservations after cancellation
            } catch (error) {
                console.error(error);
                alert('Une erreur s\'est produite lors de l\'envoi de la demande.');
            }
        },
        isPastReservation(reservation) {
            const currentDate = new Date();
            const reservationDate = new Date(reservation.date + ' ' + reservation.time_slot.split(' - ')[0]);
            return currentDate > reservationDate;
        },
        // Go to the next page
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },

        // Go to the previous page
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
    },
    mounted() {
        this.fetchUser().then(() => {
            if (this.user.vehiclePhotoUrl) {
                this.user.vehiclePhotos = this.user.vehiclePhotoUrl.split(',').map(url => ({ url }));
            } else {
                this.user.vehiclePhotos = [];
            }

            this.fetchUserReservations();
        }).catch((error) => {
            console.error('Erreur lors de la récupération des données utilisateur au montage:', error);
            this.$store.commit('CLEAR_USER');
        });
    }
};
</script>

<style>
.profile {
    padding: 2em;
    margin: 0 auto;
    text-transform: uppercase;
}

.profile-header {
    border-bottom: 1px solid var(--yellow-color);
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.profile-header div {
    align-self: flex-end;
}

.profile-name {
    margin: 0;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 1rem 0 1rem 0;
    color: var(--yellow-color);
}

.profile h2 {
    align-self: center;
    color: var(--yellow-color);
}

.profile-email {
    color: var(--white-color);
    text-transform: lowercase;
}

.profile-info {
    margin-bottom: 2em;
}

.profile-info p {
    margin: 0.5rem 0;
}

.profile-edit-form label {
    display: block;
    margin-bottom: .75rem;
    font-weight: bold;
    width: fit-content;
}

.profile-edit-form input[type="text"],
.profile-edit-form input[type="tel"],
.profile-edit-form input[type="email"],
.profile-edit-form input[type="password"] {
    width: calc(100% - 9px);
    padding: 0.5rem 0 0.5rem 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid var(--black-color);
    border-radius: 0px;
    transition: border-color 0.3s ease;
}

.profile-edit-form input[type="file"] {
    padding: 0;
    border: none;
    margin-bottom: .75rem;
}

.profile-edit-form input[type="text"]:focus,
.profile-edit-form input[type="tel"]:focus,
.profile-edit-form input[type="email"]:focus,
.profile-edit-form input[type="password"]:focus {
    border-color: var(--yellow-color);
    outline: none;
    box-shadow: 0 0 0 3px var(--yellow-color-shadow);
}

.tabs {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}

.vehicle-photos-list ul,
.vehicle-pdf-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.profile .tabs button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    background-color: var(--yellow-color);
    color: var(--white-color);
    border: none;
    outline: none;
}

.profile .tabs button.active {
    background-color: var(--white-color);
    color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
}

.uploaded-file-preview {
    max-width: 100px;
    max-height: 100px;
}

.profile-actions {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.action-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: center;
}

.action-column {
    display: flex;
    justify-content: center;
}

.logout {
    margin-bottom: .75rem;
}

.message {
    align-self: flex-start;
    padding: 10px 0;
    font-weight: bold;
}

.message.success {
    color: var(--green-color);
}

.message.error {
    color: var(--red-color);
}

.profile-actions button {
    font-size: 1.25rem;
    padding: 0.75rem 1.5rem;
    width: 100%;
}

.profile .button {
    background-color: var(--yellow-color);
    color: var(--white-color);
    border: 2px solid var(--yellow-color);
}

.profile .button:hover {
    background-color: var(--white-color);
    color: var(--yellow-color);
    border: 2px solid var(--yellow-color);
}

.profile-reservations div ul {
    gap: .5rem;
    display: flex;
    flex-direction: column;
    margin: 0;
    list-style: square;
}

.status-completed {
    color: var(--green-color);
}

.status-pending {
    color: var(--yellow-color-darker);
}

.status-failed {
    color: var(--red-color);
}

.status-cancelled {
    color: var(--grey-color);
}

.status-passed {
    color: var(--grey-color);
    font-style: italic;
}

.cancellation-message {
    font-style: italic;
    color: var(--grey-color);
}

.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination-controls button:disabled {
    background-color: var(--grey-color);
    color: var(--white-color);
    border: 2px solid var(--white-color);
    cursor: not-allowed;
}

.profile .desktop {
    display: block;
}

.profile .mobile {
    display: none;
}

@media (max-width: 1024px) {
    .profile-name {
        font-size: 2rem;
    }

    .profile label {
        font-size: 1rem;
    }

    .profile-actions {
        flex-direction: column;
    }

    .action-row {
        flex-direction: column;
    }

    .profile p,
    .profile-info div {
        font-size: 0.75rem;
    }

    .profile button {
        font-size: 1rem;
        padding: 0.5rem 1rem;
    }

    .message {
        font-size: 1rem;
    }
}

@media (max-width: 768px) {

    .profile-name,
    .profile h2 {
        font-size: 1.4rem;
    }

    .profile label {
        font-size: 0.875rem;
    }

    .profile p,
    .profile-info div {
        font-size: 0.875rem;
    }

    .profile button {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }

    .message {
        font-size: 0.875rem;
    }

    .pagination-controls ion-icon {
        margin: 0;
    }

    .pagination-controls button {
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .profile .desktop {
        display: none;
    }

    .profile .mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 480px) {

    .profile-name,
    .profile h2 {
        font-size: 1.2rem;
    }

    .profile label {
        font-size: 0.75rem;
    }

    .profile button {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }

    .message {
        font-size: 0.75rem;
    }
}
</style>
