<template>
  <Loader :isLoading="isLoading" />
  <HeaderComponent />
  <router-view />
  <FooterComponent />
  <button class="back-to-top" @click="scrollToTop" v-if="isVisible">↑</button>
</template>

<script>
import HeaderComponent from "./components/Header.vue";
import FooterComponent from "./components/Footer.vue";
import Loader from "./components/Loader.vue";

export default {
  name: "App",
  components: {
    Loader,
    HeaderComponent,
    FooterComponent,
  },
  data() {
    return {
      isVisible: false,
      isLoading: true
    };
  },
  methods: {
    startLoading() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
      }, 200);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    handleScroll() {
      this.isVisible = window.scrollY > 300;
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.startLoading();
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    $route(to, from) {
      this.startLoading();
    }
  }
};
</script>

<style>
@import './assets/css/style.css';

/* Style du bouton Retour en haut */
.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: var(--grey-color);
  color: var(--white-color);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: content-box;
  font-size: 18px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  opacity: .9;
  z-index: 1000;
}

.back-to-top.show {
  opacity: 1;
}

.back-to-top:hover {
  background-color: var(--grey-color-light);
}

@media (max-width: 1024px) {
  .back-to-top {
    width: 2rem;
    height: 2rem;
  }
}

@media (max-width: 768px) {
  .back-to-top {
    display: none;
  }
}
</style>
