<template>
    <footer class="footer">

        <section id="services">
            <h2>#SPLASHAUTOBM</h2>
        </section>
        <div class="effect-container-splash"></div>
        <div class="effect-container-top-splash"></div>

        <div class="pre-footer"></div>
        <div class="container">
            <div class="footer-top">
                <div class="footer-section">
                    <h3>Suivez nous</h3>
                    <a href="https://www.instagram.com/splashauto_bm/" target="_blank" class="social-link"><ion-icon
                            name="logo-instagram"></ion-icon>
                        Instagram</a>
                    <a href="https://www.snapchat.com/add/splashauto60" target="_blank" class="social-link"><ion-icon
                            name="logo-snapchat"></ion-icon>
                        Snapchat</a>
                </div>
                <div class="footer-section">
                    <h3><router-link to="/map-hours">Horaires</router-link></h3>
                    <p>Lundi à Jeudi et Samedi : 9h00 - 12h30, 13h30 - 18h30</p>
                    <p>Vendredi : 9h00 - 13h00, 15h00 - 19h30</p>
                    <p>Dimanche : Fermé</p>
                </div>
                <div class="footer-section">
                    <h3><router-link to="/map-hours">Nous Trouver</router-link></h3>
                    <p>
                        <a href="https://maps.app.goo.gl/zztuoHKDHd4phVQy5" target="_blank" class="social-link">
                            Adresse : 620 Rue de l'Europe, 60400 Noyon
                        </a>
                    </p>
                    <p><a href="tel:0952719683" class="social-link">Téléphone : 09 52 71 96 83</a></p>
                </div>
            </div>
            <div id="top" @click="scrollToTop">en haut</div>
            <div class="footer-bottom">
                <span>
                    © {{ currentYear }} SPLASH AUTO BM
                </span>
                <span>
                    Tous droits réservés | <router-link to="/legal-notice">Mentions légales</router-link> | <router-link
                        to="/privacy-policy">Politique de Confidentialité</router-link>
                </span>
                <span>
                    Réalisation : <a href="https://www.linkedin.com/in/djason-chery-3b87702b4/" target="_blank">Djason
                        CHERY</a>
                </span>
            </div>
            <div class="footer-bottom-mobile">
                <div class="info-mobile">
                    <div class="rules">
                        <router-link to="/legal-notice">Mentions légales</router-link> <span
                            class="separator-mobile">|</span>
                        <router-link to="/privacy-policy">Politique de Confidentialité</router-link>
                    </div>
                    <div class="copyright">
                        <span class="yellow">© {{ currentYear }}</span>
                        <span>SPLASH AUTO BM</span>
                        <span>Tous droits réservés</span>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "FooterComponent",
    computed: {
        currentYear() {
            return new Date().getFullYear();
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
</script>

<style>
.footer {
    position: relative;
}

.pre-footer {
    height: 9rem;
    background-color: var(--black-color);
}

.footer-top {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: space-evenly;
    gap: 1rem;
    padding: 1rem;
    height: 15rem;
    background: linear-gradient(to top, var(--black-color) 0%, var(--yellow-color) 100%);
    position: relative;
}

.footer-top::before {
    content: "";
    position: absolute;
    top: -1.15rem;
    left: -20%;
    width: 100%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.footer-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to top, var(--black-color) -49%, var(--yellow-color) 90%);
    position: relative;
    z-index: 1;
    top: -5rem;
    width: 25rem;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;
}

.footer-section::before {
    content: "";
    position: absolute;
    top: -1.15rem;
    left: 0;
    width: 50%;
    height: 1.25rem;
    background: var(--yellow-color);
    z-index: 1;
}

.footer-section::after {
    content: "";
    position: absolute;
    top: -12px;
    left: calc(50% - 15px);
    width: 1.875rem;
    height: 1.875rem;
    background: var(--yellow-color);
    transform: rotate(45deg);
    z-index: 0;
}

.footer-section a:hover {
    color: var(--white-color);
}

.footer-section h3 a {
    color: var(--white-color);
}

.social-link {
    text-decoration: none;
    color: var(--white-color);
}

.footer-top h3 {
    font-size: large;
    font-weight: bold;
    text-transform: uppercase;
}

.footer-top p,
.footer-bottom span,
.social-link {
    font-size: x-small;
    text-transform: uppercase;
    margin: 0;
}

.footer-top p,
.social-link {
    margin: .5rem 0;
}

.footer-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 1rem;
    text-align: center;
    padding: 1rem;
    background-color: var(--black-color);
}

.footer-bottom span {
    position: relative;
    display: inline-block;
    padding: 0.5rem;
}

.footer-bottom span::before,
.footer-bottom span::after {
    content: "";
    position: absolute;
    z-index: 0;
}

.footer-bottom span::before {
    content: "";
    position: absolute;
    top: -24px;
    left: calc(60% - 10px);
    width: 1.875rem;
    height: 100%;
    background: var(--black-color);
    transform: rotate(45deg);
    z-index: 0;
}

.footer-bottom span::after {
    top: -1.9rem;
    left: -0.25rem;
    width: calc(60% + 0.5rem);
    height: 100%;
    background: var(--black-color);
    transform: scale(1);
    z-index: 0;
}

.footer-bottom span a {
    position: relative;
    color: var(--white-color);
    z-index: 1;
}

.footer-bottom-mobile {
    display: none;
}

#top {
    display: none;
}

@media (max-width: 1024px) {
    .footer-section {
        margin: 1rem 0;
        height: 13.5rem;
        top: -5rem;
        background: linear-gradient(to top, var(--black-color) -56%, var(--yellow-color) 91%);
    }

    .footer-top p,
    .footer-bottom span,
    .social-link {
        font-size: 0.5rem;
    }
}

@media (max-width: 768px) {
    .pre-footer {
        height: .75em;
    }

    .footer-top {
        display: none;
    }

    .footer-bottom {
        display: none;
    }

    .footer-bottom-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: fit-content;
        width: 100%;
        gap: 1rem;
        background: linear-gradient(to top, var(--black-color) 0%, var(--yellow-color) 400%);
        position: relative;
    }

    .info-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 6rem;
        text-transform: uppercase;
        color: var(--white-color);
        border-top: 2px solid var(--yellow-color);
    }

    .info-mobile a {
        color: var(--white-color);
    }

    .rules,
    .copyright {
        font-size: medium;
        font-weight: bold;
        color: var(--white-color);
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.25rem;
        padding-bottom: .5rem;
    }

    .separator-mobile {
        display: block;
    }

    .rules a:hover,
    .copyright a:hover {
        color: var(--white-color);
    }

    .yellow {
        color: var(--yellow-color);
    }

    #top {
        display: flex;
        align-self: center;
        justify-content: flex-end;
        margin-left: .35rem;
        margin-bottom: .5rem;
        cursor: pointer;
        text-transform: uppercase;
        font-size: medium;
        font-weight: bold;
        width: fit-content;
    }

    #top:hover {
        color: var(--white-color);
        text-decoration: underline;
    }
}

@media (max-width: 480px) {
    .footer-section {
        width: 20rem;
    }

    .info-mobile {
        height: 10rem;
        justify-content: space-evenly;
    }

    .rules,
    .copyright {
        flex-direction: column;
    }

    .separator-mobile {
        display: none;
    }
}
</style>
