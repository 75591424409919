<template>
    <div :class="['service-card', { 'show-details': isActive }]"
        :style="{ backgroundImage: `url(${backgroundImageUrl})` }" @click="toggleDetailsOnMobile"
        @mouseenter="showDetailsOnDesktop" @mouseleave="hideDetailsOnDesktop">
        <div class="service-info">
            <h3 class="service-title" v-if="!isActive || isDesktop">{{ title }}</h3>
            <div class="service-details" v-if="isActive || isDesktop">
                <p class="price">{{ price }}</p>
                <ul>
                    <li v-for="(detail, index) in details" :key="index">
                        <span :class="{ 'plus-sign': detail === '+' }">{{ detail }}</span>
                    </li>
                </ul>
            </div>
            <!-- Si c'est la section professionnels, afficher "Envoyer un e-mail" -->
            <button v-if="isProfessional" class="info-btn" @click="sendEmail">Envoyer un e-mail</button>
            <!-- Sinon, afficher "En savoir plus" comme d'habitude -->
            <router-link v-else :to="{ path: '/services-infos', hash: getAnchor }">
                <button class="info-btn" v-if="isActive || isDesktop">En savoir plus</button>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        price: String,
        details: Array,
        backgroundClass: String,
        isActive: Boolean,
        isProfessional: Boolean // Nouvelle prop pour la section professionnels
    },
    data() {
        return {
            windowWidth: window.innerWidth
        };
    },
    computed: {
        isDesktop() {
            return this.windowWidth > 768;
        },
        backgroundImageUrl() {
            switch (this.backgroundClass) {
                case 'background-1':
                    return require('@/assets/imgs/pexels-esmihel-14615262.jpg');
                case 'background-2':
                    return require('@/assets/imgs/pexels-esmihel-14615257.jpg');
                case 'background-3':
                    return require('@/assets/imgs/pexels-esmihel-14542677.jpg');
                case 'background-4':
                    return require('@/assets/imgs/pexels-tima-miroshnichenko-6872168.jpg');
                case 'background-5':
                    return require('@/assets/imgs/pexels-tima-miroshnichenko-6873006.jpg');
                case 'background-6':
                    return require('@/assets/imgs/pexels-koprivakart-3354648.jpg');
                case 'background-7':
                    return require('@/assets/imgs/pexels-artyom-kulakov-1190754-2265634.jpg');
                case 'background-professionnels':
                    return require('@/assets/imgs/pexels-daniel-andraski-197681005-13065689.jpg');
                default:
                    return '';
            }
        },
        getAnchor() {
            switch (this.title) {
                case 'PACKAGE SPLASH':
                    return '#washing-section';
                case 'PACKAGE AUTO':
                    return '#washing-section';
                case 'PACKAGE BM':
                    return '#washing-section';
                case 'Formule 1':
                    return '#polishing-section';
                case 'Formule 2':
                    return '#polishing-section';
                case 'Formule 3':
                    return '#polishing-section';
                case 'Remplacement de Pare-Brise':
                    return '#windshield-section';
                default:
                    return '#';
            }
        }
    },
    methods: {
        toggleDetailsOnMobile() {
            if (!this.isDesktop) {
                this.$emit('toggleDetails');
            }
        },
        showDetailsOnDesktop() {
            if (this.isDesktop) {
                this.$emit('toggleDetails');
            }
        },
        hideDetailsOnDesktop() {
            if (this.isDesktop) {
                this.$emit('toggleDetails');
            }
        },
        handleResize() {
            this.windowWidth = window.innerWidth;
        },
        sendEmail() {
            window.location.href = "mailto:splashautobm60@gmail.com?subject=Demande de grille tarifaire";
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }
};
</script>

<style>
.service-card {
    padding: 1.25rem;
    flex: 1 1 44.5%;
    position: relative;
    overflow: hidden;
    text-align: center;
    background-size: cover;
    background-position: center;
    color: #fff;
    transition: transform 0.3s ease, opacity 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20rem;
    max-height: 20rem;
}

.service-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.service-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 1.25rem;
}

.service-title {
    font-size: 1.5rem;
    margin: 0;
    text-transform: uppercase;
    transition: transform 0.3s ease, font-size 0.3s ease, opacity 0.3s ease;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    color: #fff;
}

.service-details {
    font-size: small;
    opacity: 0;
    transform: translateY(1.25rem);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.info-btn {
    font-size: 1rem;
    padding: 0.5rem 1rem;
    opacity: 0;
    transform: translateY(1.25rem);
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.service-card.show-details .service-details,
.service-card.show-details .info-btn {
    opacity: 1;
    transform: translateY(0);
}

.service-card.show-details .service-title {
    transform: translateY(-2rem);
    opacity: 0;
}

.service-info .price {
    margin-bottom: 0.9375rem;
    color: var(--yellow-color);
    font-weight: bold;
    font-size: x-large;
}

.service-info ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0.9375rem 0;
    text-transform: uppercase;
}

.service-info ul li {
    margin-bottom: 0.625rem;
}

.plus-sign {
    color: var(--yellow-color);
    font-size: xx-large;
}

@media (max-width: 768px) {
    .service-card {
        flex: 1 1 100%;
        max-height: none;
    }

    .service-info {
        padding: 1rem;
    }

    .service-title {
        font-size: 1.25rem;
    }

    .service-info .price {
        font-size: medium;
    }

    .service-info ul li {
        font-size: smaller;
    }

    .plus-sign {
        font-size: large;
    }

    .info-btn {
        font-size: 0.875rem;
        padding: 0.5rem 0.75rem;
    }
}

@media (max-width: 480px) {
    .info-btn {
        font-size: 0.75rem;
        padding: 0.5rem 0.5rem;
    }
}
</style>