<template>
    <div class="policy">
        <h2>Politique de Confidentialité</h2>
        <p>Chez Splash Auto BM, nous nous engageons à protéger la confidentialité de vos informations personnelles.
            Cette politique de confidentialité décrit comment nous recueillons, utilisons, partageons et protégeons vos
            informations lorsque vous utilisez nos services automobile.</p>
        <div>
            <h3>1. Informations que nous collectons</h3>
            <p>Nous pouvons collecter les informations suivantes lorsque vous utilisez nos services :</p>
            <ul>
                <li><strong>Informations personnelles :</strong> nom, prénom, numéro de téléphone, e-mail, adresse.</li>
                <li><strong>Informations de paiement :</strong> détails de carte de crédit ou autres méthodes de
                    paiement.</li>
                <li><strong>Informations sur les services :</strong> détails des services commandés, tels que le package
                    de nettoyage, la formule de polissage céramique, ou le remplacement de pare-brise, ainsi que toute
                    demande ou instruction particulière.
                    Nos services incluent des packages de nettoyage utilisant des produits bio et respectueux de
                    l'environnement, des formules de polissage céramique pour protéger et embellir votre véhicule, ainsi
                    que le remplacement de pare-brise avec des avantages comme la franchise offerte et des cartes
                    cadeaux. Vous pouvez consulter les détails complets de nos services <router-link
                        to="/services-infos">ici</router-link>.</li>
                <li><strong>Documents et informations supplémentaires :</strong>
                    <ul>
                        <li><strong>Carte grise :</strong> pour vérifier la propriété et l'identification du véhicule.
                        </li>
                        <li><strong>Contrôle technique :</strong> pour confirmer que le véhicule est en conformité avec
                            les réglementations en vigueur.</li>
                        <li><strong>Photos du véhicule :</strong> des images de l'intérieur et de l'extérieur du
                            véhicule pour évaluer son état avant et après les services.</li>
                    </ul>
                </li>
            </ul>
        </div>
        <div>
            <h3>2. Utilisation des informations</h3>
            <p>Nous utilisons les informations collectées pour :</p>
            <ul>
                <li>Traiter vos commandes et vous fournir les services demandés.</li>
                <li>Gérer les paiements et prévenir les fraudes.</li>
                <li>Améliorer nos services et personnaliser votre expérience utilisateur.</li>
                <li>Vous informer des offres spéciales, promotions et actualités concernant nos services.</li>
            </ul>
        </div>
        <div>
            <h3>3. Partage des informations</h3>
            <p>Nous ne partageons vos informations personnelles qu'avec :</p>
            <ul>
                <li><strong>Nos partenaires de service :</strong> tels que les prestataires de paiement et de support
                    technique, uniquement dans la mesure nécessaire pour fournir nos services.</li>
                <li><strong>Les autorités légales :</strong> si la loi l'exige ou pour protéger nos droits et la
                    sécurité de nos clients.</li>
            </ul>
        </div>
        <div>
            <h3>4. Sécurité des informations</h3>
            <p>Nous mettons en œuvre des mesures de sécurité appropriées pour protéger vos informations personnelles
                contre la perte, le vol et l'accès non autorisé. Cependant, aucune méthode de transmission sur Internet
                ou de stockage électronique n'est totalement sécurisée, et nous ne pouvons garantir une sécurité
                absolue.</p>
        </div>
        <div>
            <h3>5. Vos droits</h3>
            <p>Vous avez le droit d'accéder à vos informations personnelles, de les corriger ou de les supprimer. Pour
                exercer ces droits, veuillez nous contacter à <a
                    href="mailto:splashautobm60@gmail.com">splashautobm60@gmail.com</a>.</p>
        </div>
        <div>
            <h3>6. Modifications de cette politique</h3>
            <p>Nous pouvons mettre à jour cette politique de confidentialité de temps en temps. Toute modification sera
                publiée sur cette page avec une date de révision. Nous vous encourageons à consulter régulièrement cette
                politique pour rester informé de la manière dont nous protégeons vos informations.</p>
        </div>
        <div>
            <h3>7. Contact</h3>
            <p>Pour toute question concernant cette politique de confidentialité ou pour toute demande relative à vos
                informations personnelles, veuillez nous contacter à <a
                    href="mailto:splashautobm60@gmail.com">splashautobm60@gmail.com</a> ou à <a
                    href="https://maps.app.goo.gl/zztuoHKDHd4phVQy5" target="_blank">620 Rue de l'Europe, 60400 Noyon,
                    France</a>.</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicy',
}
</script>

<style>
.policy {
    padding: 2em;
    text-transform: uppercase;
    font-size: .91rem;
}

.policy ul li {
    list-style-type: square;
}

.policy h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: var(--yellow-color);
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--yellow-color);
}

@media (max-width: 1024px) {
    .policy h2 {
        font-size: 2rem;
    }

    .policy li {
        font-size: 0.875rem;
    }

    .policy p {
        font-size: 0.75rem;
    }
}

@media (max-width: 768px) {
    .policy h2 {
        font-size: 1.4rem;
    }

    .policy li {
        font-size: 0.75rem;
    }
}

@media (max-width: 480px) {
    .policy h2 {
        font-size: 1.2rem;
    }

    .policy li {
        font-size: 0.875rem;
    }
}
</style>
